/**
 * A component that allows the an admin user to switch an account
 * from regular plans (Starter/Standard/Premium) to Ultimate (custom plan).
 */

import { isNil } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogContent, DialogActions, Typography, DialogTitle, InputAdornment,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import isNumeric from '@beacon/utils/isNumeric';
import NumberInput from 'components/NumberInput';
import { gql, useMutation } from '@apollo/client';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';

const CHANGE_PLAN_TO_ULTIMATE = gql`
  mutation ChangePlanToUltimate($input: ChangePlanToUltimateInput!) {
    changePlanToUltimate(input: $input)
  }
`;

const ChangePlan = ({
  billing,
  account,
  classes,
}) => {

  const [changePlanToUltimate] = useMutation(CHANGE_PLAN_TO_ULTIMATE);

  const [ultimateAmount, setUltimateAmount] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [saveError, setSaveError] = React.useState(null);
  const [hasSaved, setHasSaved] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setTimeout(() => {
      // reset after close
      setUltimateAmount(null);
    }, 500);
  };

  const handleDialogSubmit = async () => {
    try {
      setIsSaving(true);

      await changePlanToUltimate({
        variables: {
          input: {
            account_id: account.id,
            ultimate_price: ultimateAmount,
          },
        },
      });

      setHasSaved(true);
    } catch (e) {
      setSaveError(getHelpfulErrorMessage(e));
      setIsSaving(false);
    }
  };

  return (

    <div>

      <Button className={classes.changePlanButton} onClick={handleDialogOpen}>Change to Ultimate</Button>

      <Dialog open={isDialogOpen} fullWidth onClose={handleDialogClose}>
        <DialogTitle>
          Change Plan to Ultimate
        </DialogTitle>
        <DialogContent>

          {saveError && (
            <div>
              <Alert severity="error">{saveError}</Alert>
              <br />
            </div>
          )}

          {hasSaved ? (
            <div>
              <Alert severity="success">Success! This customer has been switched to Ultimate. Refresh your page to see the latest changes.</Alert>
              <br />
              <br />
            </div>
          ) : (
            <div>

              <Typography variant="body1">
                What should this customer be paying (per year) on the Ultimate plan?
              </Typography>

              <TextField
                value={ultimateAmount || ''}
                error={isNil(ultimateAmount)}
                variant="outlined"
                disabled={isSaving}
                autoFocus
                margin="normal"
                fullWidth
                InputProps={{
                  inputComponent: NumberInput,
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  endAdornment: billing.stripe_customer_tax_exempt === 'none'
                    ? <InputAdornment position="end">per year (plus VAT)</InputAdornment>
                    : 'per year',
                }}
                onChange={(e) => {
                  setUltimateAmount(isNumeric(e.target.value) ? Number(e.target.value) : null);
                }}
              />

              {billing.technology_plan_frequency !== 12 && (
              <div>
                <br />
                <Alert severity="warning">
                  Heads up! This customer is not paying annually. When switching them to Ultimate, they will automatically be switched to an annual plan, with the annual renewal on today's date.
                </Alert>
              </div>
              )}

            </div>
          )}

        </DialogContent>

        {!hasSaved && (
          <DialogActions>
            <Button disabled={isSaving} onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleDialogSubmit} disabled={isSaving}>
              {isSaving ? 'Changing...' : 'Change Plan'}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );

};

const styles = (theme) => ({
  changePlanButton: {
    marginTop: theme.spacing(0.75),
  },
});

ChangePlan.propTypes = {
  billing: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangePlan);
