import {
  map,
  isArray,
  split,
  last,
  sortBy,
  reverse,
} from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import copy from 'clipboard-copy';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DescriptionIcon from '@material-ui/icons/Description';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {
  Alert,
  AlertTitle,
} from '@material-ui/lab';
import Button from '@material-ui/core/Button';

import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import { host as dashboardHost } from 'config/dashboard';
import {
  gql, useQuery,
} from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import Icon from '../../../../../../icon';


const GET_IMPLEMENTATION_UPLOAD_FILES = gql`
  query GetImplementationFileUploads($accountId: Int!) {
    implementationFileUploads (accountId: $accountId) {
      file_url
      key
      size
      last_modified
    }
  }
`;

const Files = ({
  account,
  classes,
}) => {

  const [isCopied, setIsCopied] = useState(false);

  const {
    loading,
    data = {},
    error,
  } = useQuery(GET_IMPLEMENTATION_UPLOAD_FILES,
    {
      variables: {
        accountId: Number(account.id),
      },
    });

  const { implementationFileUploads: files } = data;
  const sortedFiles = reverse(sortBy(files, ['last_modified']));

  const handleCopyClick = () => {
    setIsCopied(true);
    copy(`${dashboardHost}/account/${account.id}/file_upload`);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Card>
      <CardContent>

        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-PyUmEEQ24Qr9" />
          </CardTitleIcon>
          {'Uploaded files'}
          {isArray(files) && ` (${files.length})`}
        </CardTitle>

        <Button
          onClick={handleCopyClick}
          className={classes.buttonFileUpload}
        >
          {isCopied ? 'Copied' : 'Copy file upload link'}
        </Button>

        <br />

        {error && (
          <Alert severity="error">
            <AlertTitle>
              {'Well this is embarassing...'}
            </AlertTitle>
            {`${error.message}`}
          </Alert>
        )}

        {!loading && !error && (
          <List>
            {map(sortedFiles, file => (
              <ListItem
                key={file.key}
                href={file.file_url}
                target="blank"
                button
                component="a"
              >
                <ListItemAvatar>
                  <DescriptionIcon />
                </ListItemAvatar>
                <ListItemText
                  primary={`${last(split(file.key, '/'))}`}
                  secondary={`${moment(file.last_modified).format('LLLL')} · ${file.size}`}
                />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

const styles = {
  buttonFileUpload: {
    margin: 10,
  },
};

Files.propTypes = {
  account: PropTypes.object.isRequired,
};

export default withStyles(styles)(Files);
