import { isObject, map, sortby } from 'lodash';
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { Alert, AlertTitle } from '@material-ui/lab';

import select from 'utils/select';
import Loading from 'components/Loading';

const GET_ACCOUNT_CONFIG = gql`
  query getAccountConfig(
    $accountId: Int!,
  ) {
    accountConfig(
      accountId: $accountId,
    )
  }
`;

function AccountConfigDialog({
  account,
  onClose,
  classes,
}) {
  const [value, setValue] = useState('all_record_types');

  const {
    loading,
    data = { accountConfig: [] },
    error,
  } = useQuery(GET_ACCOUNT_CONFIG, {
    variables: {
      accountId: account.id,
    },
  });

  const handleChange = (event, newValue) => setValue(newValue);

  const handleCodeClick = (e) => select(e.currentTarget);

  const sortedAccountConfig = sortby(data.accountConfig, 'label');

  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle className={classes.title}>
        Account config
      </DialogTitle>

      <DialogContent>

        {loading ? (
          <>
            <Loading />

            <Typography style={{ textAlign: 'center' }} variant="subtitle1">
              {'Fetching account config for '}
              <strong>{account.name}</strong>
              ...
            </Typography>

          </>
        ) : (
          <div>

            {error ? (
              <Alert
                severity="error"
                className={classes.alert}
              >
                <AlertTitle>Error: Oh no! Something went wrong!</AlertTitle>
                {isObject(error) ? getHelpfulErrorMessage(error) : error}
              </Alert>
            ) : (
              <div>
                <Paper>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab value="all_record_types" label="All Record Types" />

                    {map(sortedAccountConfig, (config) => (
                      <Tab key={`${config.key} tab`} value={config.key} label={config.label} />
                    ))}
                  </Tabs>
                </Paper>

                {value === 'all_record_types' && (
                <pre
                  role="button" // eslint-disable-line
                  className={classes.embedCode}
                  onClick={handleCodeClick}
                  onKeyDown={handleCodeClick}
                >
                  {JSON.stringify(sortedAccountConfig, null, 2)}
                </pre>
                )}

                {map(sortedAccountConfig, (config) => (
                  <div key={config.key}>
                    {value === config.key && (
                      <pre
                        role="button" // eslint-disable-line
                        className={classes.embedCode}
                        onClick={handleCodeClick}
                        onKeyDown={handleCodeClick}
                      >
                        {JSON.stringify(config, null, 2)}
                      </pre>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          disabled={loading}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AccountConfigDialog.propTypes = {
  account: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  embedCode: {
    border: 'none',
    fontFamily: 'Menlo, Consolas, Monaco, monospace',
    padding: 12,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: 13,
    margin: '10px 0',
    background: '#eee',
    borderRadius: 3,
    cursor: 'pointer',
    '&:hover': {
      background: '#ddd',
    },
  },
  title: {
    '& .MuiTypography-root': {
      padding: 0,
    },
  },
});

export default withStyles(styles)(AccountConfigDialog);
