/*
 * React hook for binding to events on a Pusher channel. Use
 * `usePusherChannel` to get the channel first.
 *
 * Example:
 * const { channel } = usePusherChannel('mychannel');
 * usePusherEvent(channel, 'myevent', (data) => {
 *   console.log('do something', data)
 * })
 *
 * Tip: use `useCallback` to memoize the callback function passed
 * to the third argument to prevent unbinding/binding on every
 * single render.
 */
import { useEffect } from 'react';

const usePusherEvent = (channel, eventName, callback) => {
  useEffect(() => {
    if (!channel || !eventName) {
      return;
    }

    // Bind to the event
    channel.bind(eventName, callback);

    // Unbind the event
    return () => {
      channel.unbind(eventName, callback);
    };
  }, [channel, eventName, callback]);
};

export default usePusherEvent;
