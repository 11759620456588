import { isInteger } from 'lodash';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField, IconButton,
  InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NumberInput from 'components/NumberInput';

const Discount = forwardRef(({
  discount,
  onChange,
  isSaving,
}, textInputRef) => {
  const handleDiscountRemove = () => {
    onChange(null);
  };

  const handleNameChange = (e) => {
    const newDiscount = {
      ...discount,
      name: e.target.value,
    };
    onChange(newDiscount);
  };

  const handleAmountChange = (e) => {
    const newDiscount = {
      ...discount,
      monthly_amount_off: Number(e.target.value) || null,
    };
    onChange(newDiscount);
  };

  const handlePercentChange = (e) => {
    const newDiscount = {
      ...discount,
      percent_off: Number(e.target.value) || null,
    };
    onChange(newDiscount);
  };

  const handleDurationChange = (e) => {
    const newDuration = Number(e.target.value);

    const newDiscount = {
      ...discount,
    };

    if (isInteger(newDuration) && newDuration > 0) {
      newDiscount.duration_in_months = newDuration;
      newDiscount.duration = 'repeating';
    } else {
      newDiscount.duration_in_months = null;
      newDiscount.duration = 'forever';
    }

    onChange(newDiscount);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <TextField
          inputRef={textInputRef}
          variant="outlined"
          label="Name"
          value={discount.name}
          onChange={handleNameChange}
          disabled={isSaving}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          label="Monthly amount off"
          value={discount.monthly_amount_off || ''}
          disabled={!!discount.percent_off || isSaving}
          onChange={handleAmountChange}
          fullWidth
          InputProps={{
            inputComponent: NumberInput,
            startAdornment: (
              <InputAdornment position="start">
                £
              </InputAdornment>
            ),
          }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            decimalScale: 2,
            fixedDecimalScale: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          label="Percent off"
          value={discount.percent_off || ''}
          onChange={handlePercentChange}
          disabled={!!discount.monthly_amount_off || isSaving}
          fullWidth
          InputProps={{
            inputComponent: NumberInput,
            endAdornment: (
              <InputAdornment position="end">
                %
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          label="Duration"
          value={discount.duration_in_months || ''}
          onChange={handleDurationChange}
          disabled={isSaving}
          fullWidth
          InputProps={{
            inputComponent: NumberInput,
            endAdornment: (
              <InputAdornment position="end">
                months
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={handleDiscountRemove}
          disabled={isSaving}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
});

Discount.displayName = 'Discount';

Discount.propTypes = {
  discount: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Discount;
