import React from 'react';

import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  CardContent,
  withStyles,
} from '@material-ui/core';
import Loading from 'components/Loading';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { gql, useQuery } from '@apollo/client';
import Icon from '../../../../icon';
import PortalFormJSONEditor from './PortalFormJSONEditor';

const GET_PORTAL_FORM_SUBMISSION_JSON = gql`
  query GetPortalFormSubmissionJSON($id: String) {
    portalFormSubmissionJSON(id: $id)
  }
`;

const EditPortalFormSubmission = ({
  classes, id,
}) => {

  const {
    loading: loadingJSON,
    error: errorJSON,
    data: submissionFormJSON,
  } = useQuery(GET_PORTAL_FORM_SUBMISSION_JSON, {
    variables: {
      id,
    },
  });

  return (
    loadingJSON ? (
      <Loading />
    ) : (
      <div>
        <Card>
          <CardContent>
            <CardHeader
              title="Edit form submission"
              avatar={<Icon value="plumpy plumpy-aPtgRkkLiNl2" />}
              titleTypographyProps={{ variant: 'h6' }}
              classes={{ root: classes.root }}
            />

            { errorJSON && (
            <Alert severity="error">
              {getHelpfulErrorMessage(errorJSON)}
            </Alert>
            )}

            <br />
            <PortalFormJSONEditor
              portalFormJSON={submissionFormJSON
                ?.portalFormSubmissionJSON}
            />

          </CardContent>
        </Card>

      </div>

    )
  );

};

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    padding: theme.spacing(0.5),
  },

});

EditPortalFormSubmission.propTypes = {
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(EditPortalFormSubmission);
