import {
  map,
  isEqual,
  pick,
  find,
} from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import WarningIcon from '@material-ui/icons/Warning';
import NumberInput from 'components/NumberInput';

const ChangeAllPrices = ({
  draftBilling,
  billing,
  billingPlans,
  dispatch,
  classes,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [changePercent, setChangePercent] = useState(10);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handlePriceChange = (e) => {
    if (e.target.value) {
      setChangePercent(Number(e.target.value));
    } else {
      setChangePercent(null);
    }
  };

  const handleSaveClick = () => {
    const multiplier = (100 + changePercent) / 100;

    const newBasePrices = {
      flat_fee: draftBilling.base_prices.flat_fee * multiplier,
      rate_fee: draftBilling.base_prices.rate_fee * multiplier,
    };

    const newElementPrices = map(draftBilling.element_prices, price => ({
      ...price,
      flat_fee: price.flat_fee * multiplier,
      rate_fee: price.rate_fee * multiplier,
    }));

    const newLimitIncreasePrices = map(draftBilling.limit_increase_prices, price => ({
      ...price,
      unit_cost: price.unit_cost * multiplier,
    }));

    dispatch({
      type: 'set',
      data: {
        base_prices: newBasePrices,
        element_prices: newElementPrices,
        limit_increase_prices: newLimitIncreasePrices,
      },
    });

    // and close the dialog
    setIsDialogOpen(false);
  };


  // Undo any changes made
  const handleUndoClick = () => {
    dispatch({
      type: 'set',
      data: pick(billing, [
        'base_prices',
        'element_prices',
        'limit_increase_prices',
      ]),
    });
  };

  const billingPlan = find(billingPlans, ['key', draftBilling.plan]);

  // Reset the prices for this plan to what's on the pricing page
  const handleResetClick = () => {
    dispatch({
      type: 'set',
      data: pick(billingPlan, [
        'base_prices',
        'element_prices',
        'limit_increase_prices',
      ]),
    });
  };


  const havePricesChanged = !isEqual(
    pick(billing, ['base_prices', 'element_prices', 'limit_increase_prices']),
    pick(draftBilling, ['base_prices', 'element_prices', 'limit_increase_prices']),
  );

  const havePricesChangedFromPricingPage = !isEqual(
    pick(billingPlan, ['base_prices', 'element_prices', 'limit_increase_prices']),
    pick(draftBilling, ['base_prices', 'element_prices', 'limit_increase_prices']),
  );


  return (
    <div>

      <Button
        onClick={handleDialogOpen}
        variant="outlined"
        className={classes.button}
      >
        <ImportExportIcon className={classes.buttonIcon} />
        Change all prices
      </Button>

      {havePricesChanged && (
        <Tooltip
          title="Undo the changes you've just made"
          placement="top"
        >
          <Button
            onClick={handleUndoClick}
          >
            <RotateLeftIcon className={classes.buttonIcon} />
            Undo
          </Button>
        </Tooltip>
      )}

      {havePricesChangedFromPricingPage && (
        <Tooltip
          title="Reset (to what's on the pricing page)"
          placement="top"
        >
          <Button
            onClick={handleResetClick}
            className={classes.redButton}
          >
            <WarningIcon className={classes.buttonIcon} />
            Reset
          </Button>
        </Tooltip>
      )}

      <Dialog
        open={isDialogOpen}
        fullWidth
        maxWidth="sm"
        onClose={handleDialogClose}
      >
        <DialogTitle>
          Change all prices
        </DialogTitle>

        <DialogContent>

          <DialogContentText>
            Enter the percent that you'd like to increase/decrease the price by:
          </DialogContentText>

          <TextField
            label="Price change"
            value={changePercent}
            variant="outlined"
            required
            margin="normal"
            fullWidth
            onChange={handlePriceChange}
            autoFocus
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputComponent: NumberInput,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              decimalScale: 2,
            }}
          />

        </DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveClick}
            disabled={!changePercent}
          >
            Save changes
          </Button>
        </DialogActions>

      </Dialog>

    </div>
  );

};

const styles = theme => ({
  button: {
    marginRight: theme.spacing(1.5),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  redButton: {
    color: theme.palette.error.dark,
  },
});

ChangeAllPrices.propTypes = {
  billing: PropTypes.object.isRequired,
  billingPlans: PropTypes.arrayOf(PropTypes.object).isRequired,
  draftBilling: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChangeAllPrices);
