import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Header from './Header';
import Sidebar from './Sidebar';

function Page({
  sidebarTabs,
  user,
  location,
  children,
  classes,
}) {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const toggleMobileDrawer = () => setIsMobileDrawerOpen(!isMobileDrawerOpen);

  return (
    <div>

      <Header
        toggleMobileDrawer={toggleMobileDrawer}
      />

      <Sidebar
        tabs={sidebarTabs}
        user={user}
        location={location}
        isMobileDrawerOpen={isMobileDrawerOpen}
        toggleMobileDrawer={toggleMobileDrawer}
      />

      <main
        className={classNames({
          [classes.content]: true,
          [classes.contentShift]: true,
        })}
      >
        {children}
      </main>

    </div>
  );
}

const styles = (theme) => ({
  content: {
    marginTop: 65,
    padding: 15,
    [theme.breakpoints.up('sm')]: {
      padding: 30,
    },
  },
  contentShift: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 270,
    },
  },
});

Page.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  sidebarTabs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Page);
