import {
  isNil, isInteger, includes, map, filter, union, find, intersection,
} from 'lodash';
import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Icon,
  TextField,
  Grid,
  Typography,
  Tooltip,
  InputAdornment,
  MenuItem,
  ListItemText,
  Checkbox,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/styles';
import getElementName from '@beacon/utils/getElementName';
import getBillingFeatures from '@beacon/utils/getBillingFeatures';
import isBillingActive from '@beacon/utils/isBillingActive';

import NumberInput from 'components/NumberInput';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import arePropsEqual from 'utils/arePropsEqual';

import currencyFormat from 'mout/number/currencyFormat';
import ExpandIcon from '../ExpandIcon';

const PricesUltimate = ({
  draftBilling,
  dispatch,
  isSaving,
  classes,
}) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [isChangeFrequencyDialogOpen, setIsChangeFrequencyDialogOpen] = useState(false);
  const [hasChangeFrequencyDialogBeenOpen, setHasChangeFrequencyDialogBeenOpen] = useState(false);

  const billingFeatures = getBillingFeatures(draftBilling.version);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  // When elements are changed:
  // 1) Update the `elements` key
  // 2) Remove any `enabled_features` that are no longer available based on
  // elements being unselected
  // 3) If an element has just been enabled, then add all of the features available
  // on that element to `enabled_features`
  const handleElementsChange = (e) => {
    const newEnabledElements = e.target.value;

    const [justEnabledElement] = filter(newEnabledElements, (elementKey) => (
      !includes(draftBilling.elements, elementKey)
    ));

    dispatch({
      type: 'set',
      key: 'elements',
      value: newEnabledElements,
    });

    // Update feature flags:
    // - Rip out the ones where an element is not enabled for it
    // - Add the ones where the element has just been enabled
    let newEnabledFeatures = filter(draftBilling.enabled_features, (featureKey) => {
      const feature = find(billingFeatures, ['key', featureKey]);

      // Always include core features
      if (includes(feature.elements, 'core')) {
        return true;
      }

      // Still include feature flags for elements still enabled
      if (intersection(feature.elements, newEnabledElements).length > 0) {
        return true;
      }

      // Don't include anythign else
      return false;
    });

    // Work out the NEW enabled feature flags to append
    const featuresFromElementJustEnabled = map(filter(billingFeatures, (billingFeature) => (
      includes(billingFeature.elements, justEnabledElement)
    )), 'key');

    // Merge em together
    newEnabledFeatures = union(newEnabledFeatures, featuresFromElementJustEnabled);

    // And update the billing state
    dispatch({
      type: 'set',
      key: 'enabled_features',
      value: newEnabledFeatures,
    });

  };

  const handleFrequencyDialogClose = () => {
    setIsChangeFrequencyDialogOpen(false);
  };

  const elementKeys = [
    'fundraising',
    'memberships',
    'ticketing',
    'email_integrations',
    'finance',
    'case_management',
    'grant_making',
    'volunteering',
    'roles_and_permissions',
    'workflows',
    'portal',
  ];

  return (
    <Card>
      <CardContent>

        <ExpandIcon
          onChange={handleExpandClick}
          isExpanded={isExpanded}
          label="prices"
        />

        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-3zh7UOATW4ER" />
          </CardTitleIcon>
          Ultimate pricing
        </CardTitle>

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Price</strong>
              <Tooltip
                title="How much would you like to charge them?"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.ultimate_price}
              error={isNil(draftBilling.ultimate_price)}
              variant="outlined"
              disabled={isSaving}
              fullWidth
              InputProps={{
                inputComponent: NumberInput,
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                endAdornment: draftBilling.stripe_customer_tax_exempt === 'none'
                  ? <InputAdornment position="end">+ VAT</InputAdornment>
                  : undefined,
              }}
              onChange={(e) => dispatch({
                type: 'set_number',
                key: 'ultimate_price',
                value: e.target.value,
              })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Billing frequency</strong>
              <Tooltip
                title="How often?"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.technology_plan_frequency}
              onFocus={() => {
                // don't show if they haven't bought yet
                if (!isBillingActive(draftBilling)) {
                  return;
                }
                // don't show more than once
                if (hasChangeFrequencyDialogBeenOpen) {
                  return;
                }
                // open the dialog
                setIsChangeFrequencyDialogOpen(true);
                // flag as having opened once
                setHasChangeFrequencyDialogBeenOpen(true);
              }}
              onChange={(e) => dispatch({
                type: 'set_number',
                key: 'technology_plan_frequency',
                value: e.target.value,
              })}
              variant="outlined"
              error={(
                !isInteger(draftBilling.technology_plan_frequency)
                || draftBilling.technology_plan_frequency < 1
                || draftBilling.technology_plan_frequency > 12
              )}
              disabled={isSaving}
              fullWidth
              InputProps={{
                inputComponent: NumberInput,
                startAdornment: <InputAdornment position="start">Every</InputAdornment>,
                endAdornment: <InputAdornment position="end">months</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          onClose={handleFrequencyDialogClose}
          open={isChangeFrequencyDialogOpen}
        >
          <DialogTitle>Changing frequqency</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {'Warning: changing the billing frequency will automatically reset the customer\'s billing anchor date to '}
              <strong>today</strong>
              {', causing them to be automatically billed '}
              <strong>
                £
                {currencyFormat(draftBilling.ultimate_price, 2)}
              </strong>
              {' immediately after saving.'}
            </Typography>
            <br />
            <Typography variant="body1">
              {'They\'ll be billed automatically every '}
              {draftBilling.technology_plan_frequency}
              {' months going forward.'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFrequencyDialogClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Elements</strong>
              <Tooltip
                title="What elements do they get for this?"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.elements || []}
              onChange={handleElementsChange}
              variant="outlined"
              disabled={isSaving}
              fullWidth
              select
              SelectProps={{
                multiple: true,
                MenuProps: {
                  variant: 'menu',
                },
                renderValue: (selectedValue) => map(selectedValue, (elementKey) => getElementName(elementKey)).join(', '),
              }}
            >
              {map(elementKeys, (elementKey) => (
                <MenuItem key={elementKey} value={elementKey}>
                  <Checkbox
                    checked={includes(draftBilling.elements, elementKey)}
                    color="primary"
                  />
                  <ListItemText
                    primary={(
                      <span>
                        {getElementName(elementKey)}
                      </span>
                    )}
                  />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <br />

      </CardContent>
    </Card>
  );
};

PricesUltimate.propTypes = {
  draftBilling: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

const styles = (theme) => ({
  fieldLabel: {
    marginTop: theme.spacing(2),
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
});

const watchedProps = [
  'isSaving',
  'draftBilling.technology_plan_frequency',
  'draftBilling.ultimate_price',
  'draftBilling.elements',
  'draftBilling.enabled_features',
  'draftBilling.stripe_customer_tax_exempt',
];

export default withStyles(styles)(memo(PricesUltimate, arePropsEqual(watchedProps)));
