import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Loading from 'components/Loading';
import Page from 'components/Page';

import getSidebarTabs from './getSidebarTabs';

import Home from './Home';
import UserList from './UserList';
import User from './User';
import SecurityList from './SecurityList/component';

import AccountList from './AccountList/component';
import Account from './Account';
import AppList from './AppList';
import App from './App';
import ApiKeysList from './ApiKeyList/component';
import AccessRequestList from './AccessRequestList/component';
import PortalFormSubmissionsList from './PortalFormSubmissionsList/component';
import PortalFormSubmission from './PortalFormSubmission';

class AdminWrapper extends Component {

  componentDidMount() {
    const {
      loadCurrentSession,
      loadCurrentUser,
    } = this.props;

    loadCurrentSession();
    loadCurrentUser();
  }

  render() {
    const {
      isLoading,
      location,
      classes,
    } = this.props;

    return (
      <div>
        {isLoading ? (
          <div className={classes.loading}>
            <Loading />
          </div>
        ) : (
          <Page
            sidebarTabs={getSidebarTabs({ location })}
            location={location}
          >

            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/security" component={SecurityList} />
              <Route exact path="/apikeys" component={ApiKeysList} />
              <Route exact path="/accessrequests" component={AccessRequestList} />
              <Route exact path="/formsubmissions" component={PortalFormSubmissionsList} />
              <Route exact path="/formsubmission/:id" component={PortalFormSubmission} />

              <Route exact path="/apps" component={AppList} />
              <Route path="/app/:app_id" component={App} />

              <Route exact path="/users" component={UserList} />
              <Route exact path="/user/:user_id" component={User} />

              <Route exact path="/accounts" component={AccountList} />
              <Route path="/account/:account_id" component={Account} />

            </Switch>

          </Page>
        )}
      </div>
    );
  }

}

const styles = {
  loading: {
    marginTop: 100,
  },
};

AdminWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  loadCurrentSession: PropTypes.func.isRequired,
  loadCurrentUser: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdminWrapper);
