// This function is used to determine if an app is disabled at the globally or
// just for a single account. It also provide a helpful message to the user.
import { get } from 'lodash';

export default ({ app, configuration }) => {
  const disableTypes = [];

  if (get(app, 'is_enabled') === false) {
    disableTypes.push('global');
  }

  if (get(configuration, 'is_enabled') === false) {
    disableTypes.push('account');
  }

  return disableTypes.length > 0 ? ` (Disabled at the ${disableTypes.join(' and ')} level)` : '';
};
