/*
 * Select a field. Equivalent to jQuery $.select()
 * Kudos to http://stackoverflow.com/questions/4067469/selecting-all-text-in-html-text-input-when-clicked
 */
import lowerCase from 'mout/string/lowerCase';

export default (el) => {
  const type = lowerCase(el.tagName);

  // Form fields
  if (type === 'input' || type === 'textarea') {
    // Note that we use this instead of the native select() to ensure
    // compatibility with mobile safari
    return el.setSelectionRange(0, el.value.length);
  }

  if (document.body.createTextRange) {
    // ms
    const range = document.body.createTextRange();
    range.moveToElementText(el);
    range.select();
  } else if (window.getSelection) {
    // moz, opera, webkit
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(el);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};
