import {
  get,
  map,
} from 'lodash';
import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { gql, useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import {
  TextField, TableRow,
  TableHead, TableCell, TableBody, Table, CardContent,
  Card, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import Loading from 'components/Loading';
import { API_KEYS_FRAGMENT } from 'app/fragments';
import ApiKeyRevoker from './ApiKeyRevoker';
import ApiKeyCreatorSearch from './ApiKeyCreatorSearch';
import ApplicationContext from '../../../ApplicationContext';

const GET_API_KEYS = gql`
  query GetApiKeys($query: String, $offset: Int, $limit: Int, $sort_by: String, $sort_direction: SortDirection) {
    apiKeys(query: $query, offset: $offset, limit: $limit, sort_by: $sort_by, sort_direction: $sort_direction) {
      ...ApiKey
    }
  }
  ${API_KEYS_FRAGMENT}
`;

const ApiKeysList = ({ classes }) => {

  const { searchQuery, updateSearchQuery } = useContext(ApplicationContext);

  // only show a query result when they have typed in the entire account id number
  const hasQueryResults = searchQuery.length >= 3;

  const {
    loading,
    error,
    data: apiKeysList,
    refetch,
    fetchMore,
  } = useQuery(GET_API_KEYS, {
    variables: {
      query: hasQueryResults ? searchQuery : '',
      offset: 0,
      limit: 100,
      sort_by: 'created_at',
      sort_direction: 'DESC',
    },
  });

  const handleSearchQueryChange = async (e) => {
    const { value } = e.target;

    updateSearchQuery(value);

    if (value.length >= 3) {
      await refetch({
        offset: 0,
        query: value,
      });
    }
  };

  return (
    <div>
      <Typography variant="h6">
        API keys
      </Typography>

      <Card>
        <CardContent className={classes.apiKeyWrapper}>

          <div className={classes.searchBarAndCreateButton}>
            <TextField
              variant="outlined"
              className={classes.searchBar}
              fullWidth
              placeholder="Search API keys"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              autoFocus
            />

            <ApiKeyCreatorSearch
              apiKeyListRefetch={refetch}
            />

          </div>

          <br />
          <br />

          { loading ? (
            <Loading />
          ) : (
            apiKeysList && (
            <InfiniteScroll
              loadMore={() => {
                fetchMore({
                  variables: {
                    offset: apiKeysList.apiKeys.length,
                    limit: 100,
                    searchQuery,
                  },
                });
              }}
              hasMore={!loading && apiKeysList.apiKeys.length < apiKeysList.total}
            >
              <div>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Last 4 characters of API Key</TableCell>
                      <TableCell>Account name</TableCell>
                      <TableCell>Account ID</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>API key created</TableCell>
                      <TableCell>Is revoked?</TableCell>
                      <TableCell>Revoke?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(apiKeysList.apiKeys, (apiKey) => (

                      <TableRow
                        key={apiKey.id}
                        className={classes.tableRow}
                      >
                        <TableCell>
                          {apiKey.last4}
                        </TableCell>
                        <TableCell>
                          {apiKey.account.name}
                        </TableCell>
                        <TableCell>{apiKey.account_id}</TableCell>
                        <TableCell>{`Created by ${get(apiKey, 'created_by_user.email')}`}</TableCell>
                        <TableCell>{moment(apiKey.created_at).format('ll')}</TableCell>
                        <TableCell>{`${apiKey.is_revoked}`}</TableCell>
                        <TableCell>
                          <ApiKeyRevoker
                            apiKey={apiKey}
                            refetch={refetch}
                          />
                        </TableCell>
                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
              </div>

            </InfiniteScroll>
            )
          )}
        </CardContent>

        {error && (
        <div>
          <Alert severity="error">
            {getHelpfulErrorMessage(error)}
          </Alert>
          <br />
        </div>
        )}
      </Card>

    </div>
  );

};

const styles = (theme) => ({
  apiKeyWrapper: {
    overflow: 'auto',
  },
  searchBar: {
    paddingBottom: theme.spacing(0.5),
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    height: 60,
  },
  tableCellHeader: {
    borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[1],
  },
  searchBarAndCreateButton: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
  },
});

export default withStyles(styles)(ApiKeysList);
