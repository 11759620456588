import { map } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import CardTitle from 'components/CardTitle';
import Loading from 'components/Loading';

import browserHistory from 'app/history';
import Icon from '../../../../../icon';

import ExpiryDate from './ExpiryDate';


const handleTrialClick = accountId => () => {
  browserHistory.push(`/account/${accountId}`);
};

const Trials = ({
  isLoading,
  trials,
  loadTrials,
}) => {

  // Load trials just on mount
  useEffect(() => {
    loadTrials();
  }, [loadTrials]);


  return (
    <Card>
      <CardContent>
        <CardTitle>
          {'Current Trials'}
          {!isLoading && ` (${trials.length})`}
        </CardTitle>

        {isLoading ? (
          <Loading />
        ) : (
          <div>

            <List>
              {map(trials, trial => (
                <ListItem
                  key={trial.id}
                  button
                  onClick={handleTrialClick(trial.id)}
                >
                  <ListItemIcon>
                    {trial.billing_id ? (
                      <Icon value="plumpy plumpy-k8xXTceQVvPp" />
                    ) : (
                      <Icon value="plumpy plumpy-Hd7decIy4OLo" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={trial.name}
                    secondary={(
                      <span>
                        <ExpiryDate trial={trial} />
                      </span>
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        )}

      </CardContent>
    </Card>
  );
};


Trials.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadTrials: PropTypes.func.isRequired,
  trials: PropTypes.arrayOf(PropTypes.object),
};

export default Trials;
