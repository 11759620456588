import {
  reject,
  isObject,
  map,
  trim,
  uniq,
  uniqBy,
  includes,
} from 'lodash';
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { gql, useQuery, useMutation } from '@apollo/client';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { Alert, AlertTitle } from '@material-ui/lab';

import { host as dashboardHost } from 'config/dashboard';
import Loading from 'components/Loading';

import usePusherChannel from 'utils/usePusherChannel';
import usePusherEvent from 'utils/usePusherEvent';
import Icon from '../../../../../../icon';
import AdditionalFeatureToggleButtons from './AdditionalFeatureToggleButtons';
import ToggleCopyForm from './ToggleCopyForm';
import ToggleCopyView from './ToggleCopyView';

const copyAllFormsArray = [
  'forms_donation',
  'forms_event_registration',
  'forms_membership',
  'forms_signup',
];

const GET_PORTAL_FORMS_TOTAL = gql`
  query GetPortalFormsTotal($account_id: Int) {
    portalFormsTotal(account_id: $account_id)

    entityListViewsTotal(account_id: $account_id)

    allEntityListViews(account_id: $account_id) {
      id
      entity_type {
        id
        key
        label
        label_plural
        icon
        icon_color
      }
    }
  }
`;

const COPY_ACCOUNT = gql`
  mutation copyAccount(
    $accountId: Int!,
    $name: String!
    $additionalFeatures: [String]
  ) {
    copyAccount(
      accountId: $accountId,
      name: $name
      additionalFeatures: $additionalFeatures
    )
  }
`;

function CopyAccountButton({
  account,
  classes,
}) {
  const { data } = useQuery(GET_PORTAL_FORMS_TOTAL, {
    variables: {
      account_id: account.id,
    },
  });
  const [copyAccount] = useMutation(COPY_ACCOUNT);

  const [name, setName] = useState(`${account.name} copy`);
  const [copiedAccount, setCopiedAccount] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isCopying, setIsCopying] = useState(false);

  const [additionalFeatures, setAdditionalFeatures] = useState([]);
  const [copyAllForms, setCopyAllForms] = useState(false);
  const [copyAllEntityListViews, setCopyAllEntityListViews] = useState(false);

  const {
    allEntityListViews = [],
    portalFormsTotal = 0,
    entityListViewsTotal = 0,
  } = data || {};

  const allEntityListViewsIds = uniqBy(allEntityListViews, 'entity_type.id');

  const handleNameChange = ({ target }) => setName(target.value);

  const onOpen = () => setOpen(true);

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick' && isCopying) {
      return;
    }
    setCopiedAccount(null);
    setName(`${account.name} copy`);
    setOpen(false);
  };

  const handleDuplicateClick = async () => {
    setIsCopying(true);
    setError(null);

    let newAdditionalFeatures = [...additionalFeatures];

    if (copyAllForms) {
      newAdditionalFeatures = uniq([...newAdditionalFeatures, ...copyAllFormsArray]);
    }

    if (copyAllEntityListViews) {
      newAdditionalFeatures = uniq([
        ...newAdditionalFeatures,
        ...uniq(map(allEntityListViews, ({ entity_type }) => `view_${entity_type.key}`)),
      ]);
    }

    await copyAccount({
      variables: {
        accountId: account.id,
        name,
        additionalFeatures: newAdditionalFeatures,
      },
    });
  };

  const addToAdditionalFeatures = (feature) => () => setAdditionalFeatures(
    uniq([...additionalFeatures, feature]),
  );

  const removeFromAdditionalFeatures = (feature) => () => setAdditionalFeatures(
    reject(additionalFeatures, (additionalFeature) => feature === additionalFeature),
  );

  const redirectToCopiedDashboard = () => window.open(`${dashboardHost}/account/${copiedAccount.id}/home`);

  const channelName = `private-account-${account.id}-admin-dashboard`;
  const { channel } = usePusherChannel(channelName);
  usePusherEvent(channel, 'account-copy', ({ message: response }) => {
    const { account_id, status, message } = response;

    setIsCopying(false);

    if (status === 'error') {
      setError(message);
    }
    if (status === 'success') {
      setCopiedAccount({
        id: account_id,
        name,
      });
      setName(`${account.name} copy`);
    }
  });

  return (
    <div>

      <Button
        onClick={onOpen}
        variant="contained"
        className={classes.copyButton}
      >
        <Icon
          value="Njyu9AxVqPk7"
          alt="Copy account icon"
          className={classes.buttonIcon}
        />
        {' '}
        Copy
      </Button>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle className={classes.title}>
          {copiedAccount?.id ? (
            <>
              {'Copy successful! Dolly would be proud 🐑 '}
              <em>baaahh!</em>
            </>
          ) : ('Copy account')}
        </DialogTitle>

        <DialogContent>

          {isCopying ? (
            <>
              <Loading />

              <Typography style={{ textAlign: 'center' }} variant="subtitle1">
                {'Creating a copy of '}
                <strong>{account.name}</strong>
                ...
              </Typography>

            </>
          ) : (
            <div>

              {error && (
                <Alert
                  severity="error"
                  className={classes.alert}
                >
                  <AlertTitle>Error: Oh no! Something went wrong!</AlertTitle>
                  {isObject(error) ? getHelpfulErrorMessage(error) : error}
                </Alert>
              )}

              {copiedAccount?.id ? (
                <Typography variant="subtitle1">
                  {`Your account has been copied successfully! You can click on the button below to go to your new account (${copiedAccount?.name}).`}
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle1">
                    This will create a duplicate of this account that you can edit separately.
                  </Typography>
                  <TextField
                    margin="normal"
                    label="Name"
                    variant="outlined"
                    value={name || ''}
                    onChange={handleNameChange}
                    disabled={isCopying}
                    fullWidth
                  />

                  <br />
                  <br />
                  <Divider />
                  <br />

                  <AdditionalFeatureToggleButtons
                    setCopyAll={setCopyAllForms}
                    copyAll={copyAllForms}
                    title={`Copy all forms (${portalFormsTotal})`}
                  >
                    {map(copyAllFormsArray, (formKey) => (
                      <ToggleCopyForm
                        key={formKey}
                        account={account}
                        additionalFeatures={additionalFeatures}
                        formKey={formKey}
                        onChange={includes(additionalFeatures, formKey)
                          ? removeFromAdditionalFeatures(formKey)
                          : addToAdditionalFeatures(formKey)}
                      />
                    ))}
                  </AdditionalFeatureToggleButtons>

                  <AdditionalFeatureToggleButtons
                    setCopyAll={setCopyAllEntityListViews}
                    copyAll={copyAllEntityListViews}
                    title={`Copy all views (${entityListViewsTotal})`}
                  >
                    {map(allEntityListViewsIds, ({ entity_type }) => {
                      const viewKey = `view_${entity_type.key}`;

                      return (
                        <ToggleCopyView
                          account={account}
                          additionalFeatures={additionalFeatures}
                          entityType={entity_type}
                          viewKey={viewKey}
                          onChange={includes(additionalFeatures, viewKey)
                            ? removeFromAdditionalFeatures(viewKey)
                            : addToAdditionalFeatures(viewKey)}
                        />
                      );
                    })}
                  </AdditionalFeatureToggleButtons>
                </>
              )}
            </div>
          )}

        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            disabled={isCopying}
          >
            Cancel
          </Button>

          {copiedAccount && !isCopying ? (
            <Button
              variant="contained"
              color="primary"
              onClick={redirectToCopiedDashboard}
              disabled={isCopying}
            >
              {`Go to ${copiedAccount.name}`}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleDuplicateClick}
              disabled={isCopying || !trim(name)}
            >
              {isCopying ? 'Copying...' : 'Copy'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

CopyAccountButton.propTypes = {
  account: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  copyButton: {
    marginRight: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  formToggles: {
    display: 'grid',
    justifyItems: 'start',
    gap: theme.spacing(1),
  },
  title: {
    '& .MuiTypography-root': {
      padding: 0,
    },
  },
});

export default withStyles(styles)(CopyAccountButton);
