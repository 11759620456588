import {
  filter,
  get,
  isInteger,
  map,
  sortBy,
} from 'lodash';
import React from 'react';

import {
  // Divider,
  Typography,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';

import Loading from 'components/Loading';
import { APP_FRAGMENT } from 'app/fragments';

import AppListItem from './AppListItem';

const GET_APPS = gql` 
  query GetApps {
    apps {
      ...App
    }
  }
  ${APP_FRAGMENT}
`;

const AppList = () => {

  const {
    loading: loadingApps,
    data: results,
  } = useQuery(GET_APPS);

  const apps = get(results, 'apps', []);
  // -new Date(app.created_at).getTime() will return a timestamp for each date from
  // oldest to newest. The - is used to sort the apps by the oldest created app first.
  const sortedApps = sortBy(apps, (app) => -new Date(app.created_at).getTime());
  // dividing the apps between those that are available, and those that aren't
  const sortedAvailableApps = filter(
    sortBy(sortedApps, (app) => -new Date(app.created_at).getTime()),
    (app) => {
      // if the app is not coming soon (so it's ready to use), and is visible, then add to sorted apps
      if (!app.is_coming_soon && app.is_visible) {
        return true;
      }
    },
  );

  // Leaving this here because it might be useful later, but we don't do much with not available
  // app right now
  // // if coming soon is true and is visible is true then add to the apps not yet avaliable list
  // const appsNotYetAvailable = filter(
  //   apps,
  //   (app) => app.is_coming_soon && app.is_visible,
  // );

  return (
    <div>
      <Typography variant="h6">
        App Directory - Available
        {!loadingApps && isInteger(sortedAvailableApps.total) && ` (${sortedAvailableApps.total})`}
      </Typography>
      <br />

      {loadingApps ? (
        <Loading />
      ) : (
        <>
          {map(sortedAvailableApps || [], (app) => (
            <AppListItem key={app.key} app={app} />
          ))}
        </>
      )}

      {/*
        * Leaving this here because it might be useful later, but we don't do much with not available
        * app right now
      */}
      {/*
      <br />
      <Divider />
      <br />

      <Typography variant="h6">
        App Directory - Not yet available
        {!loadingApps && isInteger(appsNotYetAvailable.total) && ` (${appsNotYetAvailable.total})`}
      </Typography>
      <br />

      {loadingApps ? (
        <Loading />
      ) : (
        <>
          {map(appsNotYetAvailable || [], (app) => (
            <AppListItem key={app.key} app={app} />
          ))}
        </>
      )} */}
    </div>
  );
};

export default AppList;
