import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// import { getStateByKey } from 'reducers';
import Home from './component';

const selector = createSelector([], () => ({}));

const mapDispatchToProps = {};

export default connect(
  selector,
  mapDispatchToProps,
)(Home);
