import encode from 'mout/queryString/encode';
import { trim } from 'lodash';
import {
  ACCOUNTS_LOADING,
  ACCOUNTS_LOADED,
  ACCOUNT_LOADING,
  ACCOUNT_LOADED,
  ACCOUNT_UPDATED,
  ACCOUNT_USERS_LOADING,
  ACCOUNT_USERS_LOADED,
  ACCOUNT_USER_UPDATED,
  ACCOUNT_BILLING_LOADING,
  ACCOUNT_BILLING_LOADED,
  ACCOUNT_BILLING_USAGE_LOADED,
} from 'constants/account';
import {
  NOTIFY_SHOW_MESSAGE,
} from 'constants/notify';
import api from 'api';

export const loadAccountsList = ({
  page,
  query,
}) => (dispatch) => new Promise((resolve, reject) => {

  dispatch({
    type: ACCOUNTS_LOADING,
    page,
  });

  const queryString = encode({
    per_page: 20,
    page,
  });

  const payload = {};

  const isSearch = trim(query);
  if (isSearch) {
    payload.conditions = [{
      field: 'name',
      operator: 'contains',
      value: trim(query),
    }];
  }

  api.post(`/accounts/filter${queryString}`, payload)

    .then(({ results, total }) => {
      dispatch({
        type: ACCOUNTS_LOADED,
        accounts: results,
        page: isSearch ? undefined : page,
        total,
      });
    })

    .then(resolve, reject);

});

export const loadAccount = (accountId) => (dispatch) => new Promise((resolve, reject) => {

  dispatch({
    type: ACCOUNT_LOADING,
  });

  api.get(`/account/${accountId}`)

    .then((account) => {
      dispatch({
        type: ACCOUNT_LOADED,
        account,
      });
    })

    .then(resolve, reject);

});

export const updateAccount = (accountId, data, notifyMessage) => (dispatch) => new Promise((resolve, reject) => {

  api.patch(`/account/${accountId}`, data)

    .then((account) => {
      dispatch({
        type: ACCOUNT_UPDATED,
        account,
      });

      if (notifyMessage) {
        dispatch({
          type: NOTIFY_SHOW_MESSAGE,
          message: notifyMessage,
        });
      }
    })

    .then(resolve, reject);

});

export const loadAccountBilling = (accountId) => (dispatch) => new Promise((resolve, reject) => {

  dispatch({
    type: ACCOUNT_BILLING_LOADING,
  });

  api.get(`/account/${accountId}/billing`)

    .then((billing) => {
      dispatch({
        type: ACCOUNT_BILLING_LOADED,
        billing,
      });
    })

    .then(resolve, reject);

});

export const loadAccountBillingUsage = (accountId) => (dispatch) => new Promise((resolve, reject) => {

  api.get(`/account/${accountId}/billing/usage`)

    .then((billingUsage) => {
      dispatch({
        type: ACCOUNT_BILLING_USAGE_LOADED,
        billingUsage,
      });
    })

    .then(resolve, reject);

});

export const loadAccountUsers = (accountId) => (dispatch) => new Promise((resolve, reject) => {

  dispatch({
    type: ACCOUNT_USERS_LOADING,
  });

  api.get(`/account/${accountId}/users`)

    .then(({ results }) => {
      dispatch({
        type: ACCOUNT_USERS_LOADED,
        accountId,
        accountUsers: results,
      });
    })

    .then(resolve, reject);

});

export const updateAccountUser = ({
  accountId,
  accountUserId,
  data,
  notifyMessage,
}) => (dispatch) => new Promise((resolve, reject) => {

  api.patch(`/account/${accountId}/account_user/${accountUserId}`, data)

    .then((result) => {
      dispatch({
        type: ACCOUNT_USER_UPDATED,
        accountId,
        accountUser: result,
      });

      if (notifyMessage) {
        dispatch({
          type: NOTIFY_SHOW_MESSAGE,
          message: notifyMessage,
        });
      }
    })

    .then(resolve, reject);

});
