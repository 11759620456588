import React, { useState } from 'react';
import PropTypes from 'prop-types';
import typecast from 'mout/string/typecast';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, MenuItem, Typography, Button, Dialog, DialogTitle, DialogActions, DialogContent,
} from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { Alert } from '@material-ui/lab';
import { ACCOUNT_USERS_FRAGMENT } from 'app/fragments';

const LOCK_ACCOUNT_USER = gql`
  mutation LockAccountUser(
    $id: Int!, 
    $locked_reason: String
  ) {
    lockAccountUser(
      id: $id, 
      locked_reason: $locked_reason
    ) {
      ...AccountUser
    }
  }
  ${ACCOUNT_USERS_FRAGMENT}
`;


const SecurityLock = ({
  accountUser,
  classes,
  refetch,
}) => {


  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lockReason, setLockReason] = useState(null);

  const [lockAccountUser, {
    error,
  }] = useMutation(LOCK_ACCOUNT_USER);

  const onClose = async () => {
    refetch();
    setIsDialogOpen(false);
    setIsSaving(false);
  };


  const handleLock = (id) => {
    lockAccountUser({
      variables: {
        id,
        locked_reason: lockReason,
      },
      onCompleted: onClose,
    });
  };

  return (

    <div className={classes.wrapper}>
      <Button
        onClick={() => setIsDialogOpen(true)}
        disabled={isSaving}
      >
        {isSaving && 'Locking'}
        {!isSaving && 'Lock'}
      </Button>

      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          Lock account and user?
        </DialogTitle>
        <DialogContent>

          {error && (
          <Alert severity="error">
            {getHelpfulErrorMessage(error)}
          </Alert>
          )}

          <Typography variant="subtitle1">
              Why would you like to lock this account user?
          </Typography>

          <TextField
            onChange={(e) => {
              setLockReason(typecast(e.target.value));
            }}
            select
            disabled={isSaving}
            variant="outlined"
            fullWidth
            margin="normal"
            value={String(lockReason)}
          >
            <MenuItem value="competitor">
                Competitor
            </MenuItem>
            <MenuItem value="security">
                Security
            </MenuItem>
            <MenuItem value="null">
                Other
            </MenuItem>
          </TextField>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleLock(accountUser.id)}
            disabled={isSaving}
          >
            {isSaving ? 'Locking...' : 'Lock'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
};

const styles = theme => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
});


SecurityLock.propTypes = {
  accountUser: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default withStyles(styles)(SecurityLock);
