import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function Expandomatic({
  onClick,
  children,
}) {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    let timeout;
    if (clicked) {
      timeout = setTimeout(() => {
        onClick();
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [clicked, onClick]);

  return (
    <Typography
      style={{
        fontSize:
        clicked ? 64 : 16,
        letterSpacing: clicked ? '2rem' : 'normal',
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'all 0.5s ease-in',
        opacity: clicked ? 0 : 'initial',
      }}
      onClick={() => setClicked(true)}
    >
      {children}
    </Typography>
  );
}

Expandomatic.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};
