import PropTypes from 'prop-types';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'ace-builds';
import AceEditor from 'react-ace';
import { gql, useMutation } from '@apollo/client';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/webpack-resolver';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { Alert } from '@material-ui/lab';
import notify from 'actions/notify';

const UPDATE_PORTAL_FORM_SUBMISSION_JSON = gql`
  mutation UpdatePortalFormSubmissionJSON($id: String, $input: JSONObject) {
    updatePortalFormSubmissionJSON(id: $id, input: $input)
  }
`;

const PortalFormJSONEditor = ({ portalFormJSON, classes }) => {

  const [editorValue, setEditorValue] = useState(JSON.stringify(portalFormJSON, null, 2));
  const [formatError, setFormatError] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [updatePortalFormJSON, { error }] = useMutation(UPDATE_PORTAL_FORM_SUBMISSION_JSON);
  const dispatch = useDispatch();

  useEffect(() => {
    setEditorValue(JSON.stringify(portalFormJSON, null, 2));
  }, [portalFormJSON]);

  const editorRef = useRef();

  const onChange = (newValue) => {
    setHasChanged(true);
    setEditorValue(newValue);
  };

  const submitJSON = () => {
    setFormatError(null);
    try {
      updatePortalFormJSON({
        variables: {
          id: portalFormJSON?.id,
          input: JSON.parse(editorValue),
        },
        onCompleted: () => {
          dispatch(notify({ message: 'JSON updated successfully' }));
          setHasChanged(false);
        },
      });
    } catch (err) {
      // make sure we show any formatting errors to the user
      setFormatError(err);
    }
  };

  return (
    <div>
      <div>

        {(error || formatError) && (
        <Alert
          className={classes.alert}
          severity="error"
        >
          {getHelpfulErrorMessage(error || formatError)}
        </Alert>
        )}

        <div>
          <AceEditor
            mode="json"
            theme="github"
            onChange={onChange}
            name={portalFormJSON?.id}
            editorProps={{ $blockScrolling: true }}
            value={editorValue}
            ref={editorRef}
            width="100%"
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <div className={classes.submitButton}>
          <Button
            onClick={() => submitJSON()}
            variant="contained"
            color="primary"
            disabled={!hasChanged}
          >
            Submit JSON
          </Button>
        </div>
      </div>

    </div>
  );
};

const styles = (theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },

  submitButton: {
    marginLeft: theme.spacing(28),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
});

PortalFormJSONEditor.propTypes = {
  portalFormJSON: PropTypes.object.isRequired,
};

export default withStyles(styles)(PortalFormJSONEditor);
