import { isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateByKey } from 'reducers';
import { loadTrials } from 'actions/finance';
import Trials from './component';

const selector = createSelector([
  getStateByKey('finance.trials'),
], (trials) => ({
  trials,
  isLoading: isUndefined(trials),
}));

const mapDispatchToProps = {
  loadTrials,
};

export default connect(
  selector,
  mapDispatchToProps,
)(Trials);
