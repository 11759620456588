import {
  omit,
  isEqual,
  pick,
  cloneDeep,
} from 'lodash';
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Typography,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  Alert,
} from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { gql, useQuery, useMutation } from '@apollo/client';
import isBillingConfigured from '@beacon/utils/isBillingConfigured';
import isBillingActive from '@beacon/utils/isBillingActive';
import getBillingEstimate from '@beacon/utils/getBillingEstimate';

import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Loading from 'components/Loading';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import browserHistory from 'app/history';

import omitDeep from 'omit-deep';
import AddressLookupLicence from './AddressLookup';
import SubscriptionStatus from './SubscriptionStatus';
import CreateBilling from './CreateBilling';
import Overview from './Overview';
import Finance from './Finance';
import Tax from './Tax';
import Limits from './Limits';
import Features from './Features';
import Prices from './Prices';
import PricesUltimate from './PricesUltimate';
import Discounts from './Discounts';
import ProrationDialog from './ProrationDialog';
import DestroyDialog from './DestroyDialog';
import draftBillingReducer from './draftBillingReducer';
import Icon from '../../../../../icon';

const GET_ACCOUNT_BILLING = gql`
  query GetAccountBilling($accountId: Int!) {
    billing (account_id: $accountId) {
      id
      plan
      version
      mrr
      technology_plan_frequency
      technology_annual_discount_percent
      contacts_tier
      contacts
      contacts_override
      notes
      stripe_customer_id
      stripe_customer_name
      stripe_customer_email
      stripe_customer_address
      stripe_payment_method_id
      stripe_tax_rate_id
      stripe_customer_tax_exempt
      tax_id
      subscription_start_at
      collection_method
      is_send_invoice_always_allowed
      send_invoice_days_until_due
      stripe_technology_subscription_id
      services_day_rate_amount
      services_hour_rate_amount
      min_contacts
      max_contacts
      max_users
      max_read_only_users
      max_roles
      max_custom_entity_fields
      max_workflows
      elements
      enabled_features
      ultimate_price
      base_prices {
        flat_fee
        rate_fee
      }
      element_prices {
        key
        flat_fee
        rate_fee
        hidden
      }
      element_trials {
        element
        expires
      }
      limit_increase_prices {
        key
        maximum
        step
        number_free
        unit_cost
        hidden
      }
      discount {
        name
        monthly_amount_off
        percent_off
        duration
        duration_in_months
      }
      discounts {
        name
        monthly_amount_off
        percent_off
        duration
        duration_in_months
      }
      limit_increases {
        key
        quantity
      }
      address_lookup_licence {
        id
        free_allocation
        credit_balance
        can_lookup
        is_valid_trial
      }
    }
    billingPlans {
      key
      name
      base_prices {
        flat_fee
        rate_fee
      }
      element_prices {
        key
        flat_fee
        rate_fee
        hidden
      }
      limit_increase_prices {
        key
        maximum
        step
        number_free
        unit_cost
        hidden
      }
    }
  }
`;

const CREATE_UPDATE_BILLING = gql`
  mutation CreateBilling(
    $accountId: Int!,
    $data: CreateOrUpdateBillingInput!,
    $prorationBehaviour: BillingProrationBehaviour,
    $itemDescription: String
  ) {
    createOrUpdateBilling(
      account_id: $accountId,
      input: $data,
      proration_behaviour: $prorationBehaviour
      item_description: $itemDescription
    ) {
      plan
    }
  }
`;

const DESTROY_BILLING = gql`
  mutation DestroyBilling($accountId: Int!) {
    destroyBilling(account_id: $accountId)
  }
`;

const UPDATE_ADDRESS_LOOKUP_FREE_ALLOCATION = gql`
  mutation ResetFreeLookupAllocation($accountId: Int!, $value: Int!) {
    resetAllocation(account_id: $accountId, value: $value) {
      free_allocation
    }
  }
`;

function BillingV2({
  account,
  notify,
  loadAccountBilling,
  classes,
}) {

  const {
    loading,
    data = {},
    refetch: refetchBilling,
  } = useQuery(GET_ACCOUNT_BILLING, {
    variables: {
      accountId: account.id,
    },
  });

  const [createUpdateBilling] = useMutation(CREATE_UPDATE_BILLING);
  const [destroyBilling] = useMutation(DESTROY_BILLING);
  const [resetAllocation] = useMutation(UPDATE_ADDRESS_LOOKUP_FREE_ALLOCATION);

  const [draftBilling, dispatch] = useReducer(draftBillingReducer, null);
  const [isProrationDialogOpen, setIsProrationDialogOpen] = useState(false);
  const [isDestroyDialogOpen, setIsDestroyDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [isDestroying, setIsDestroying] = useState(false);

  useEffect(() => {
    if (data.billing && data.billing.plan) {
      dispatch({
        type: 'onload',
        billing: data.billing,
      });
    }
  }, [data.billing]);

  const hasChanged = !isEqual(draftBilling, data.billing);

  const handleBillingCreate = () => {
    notify({
      message: 'Billing created!',
    });
    refetchBilling();
  };

  const saveBilling = async (prorationBehaviour, itemDescription) => {
    try {

      setSaveError(null);
      setIsSaving(true);

      let saveData;

      if (draftBilling.plan === 'ultimate') {
        saveData = omit(draftBilling, [
          'id',
          'mrr',
          'contacts_tier',
          'stripe_payment_method_id',
          'limit_increases',
          'base_prices',
          'element_prices',
          'limit_increase_prices',
          'contact_tiers',
          'address_lookup_licence',
        ]);
      } else {
        saveData = omit(draftBilling, [
          'id',
          'mrr',
          'contacts',
          'contacts_tier',
          'stripe_payment_method_id',
          'elements',
          'limit_increases',
          'address_lookup_licence',
        ]);
      }

      // get rid of unneeded keys
      const cleanSaveData = omitDeep(
        cloneDeep(saveData),
        ['__typename', 'version'],
      );

      await createUpdateBilling({
        variables: {
          accountId: account.id,
          data: cleanSaveData,
          prorationBehaviour,
          itemDescription,
        },
      });

      // Address lookup credits are separate from billing but related so we are
      // handling that difference here on the client with a separate mutation...
      if (!isEqual(
        draftBilling.address_lookup_licence,
        data.billing.address_lookup_licence,
      )) {
        await resetAllocation({
          variables: {
            accountId: account.id,
            value: draftBilling.address_lookup_licence.free_allocation,
          },
        });
      }

      await refetchBilling();

      setIsSaving(false);

      notify({
        message: 'Billing saved',
      });

    } catch (e) {
      setSaveError(getHelpfulErrorMessage(e));
      setIsSaving(false);
    }
  };

  const { billing, billingPlans } = data;
  // See if the prices have changed using a separate utility method
  const havePricesChanged = !isEqual(
    pick(billing, [
      'technology_annual_discount_percent',
      'base_prices',
      'element_prices',
      'limit_increase_prices',
    ]),
    pick(draftBilling, [
      'technology_annual_discount_percent',
      'base_prices',
      'element_prices',
      'limit_increase_prices',
    ]),
  );

  let oldBillingEstimate;
  let newBillingEstimate;

  if (havePricesChanged && isBillingActive(billing) && billing.plan !== 'ultimate') {
    oldBillingEstimate = getBillingEstimate({
      contacts: billing.contacts,
      plan: billing.plan,
      elements: billing.elements,
      limitIncreases: billing.limit_increases,
      frequency: billing.technology_plan_frequency === 12 ? 'annual' : 'monthly',
      billing,
      version: billing?.version,
    });

    newBillingEstimate = getBillingEstimate({
      contacts: billing.contacts,
      plan: billing.plan,
      elements: billing.elements,
      limitIncreases: billing.limit_increases,
      frequency: billing.technology_plan_frequency === 12 ? 'annual' : 'monthly',
      billing: draftBilling, // w/ new billing prices
      version: billing.version,
    });
  }

  const handleSaveClick = () => {
    // Prompt the user about how to handle prorations after
    // prices have been changed on an active subscription. Only do
    // this if the subscription total has changed.
    if (isBillingActive(billing)
      && havePricesChanged
      && billing.plan !== 'ultimate'
      && oldBillingEstimate.total !== newBillingEstimate.total) {
      setIsProrationDialogOpen(true);
    } else {
      saveBilling();
    }
  };

  const handleProrationDialogClose = () => {
    setIsProrationDialogOpen(false);
  };

  const handleProrationDialogSave = async (prorationBehaviour, itemDescription) => {
    await saveBilling(prorationBehaviour, itemDescription);
    setIsProrationDialogOpen(false);
  };

  const handleDestroyClick = () => {
    setIsDestroyDialogOpen(true);
  };

  const handleDestroyDialogClose = () => {
    setIsDestroyDialogOpen(false);
  };

  const handleDestroyConfirm = async () => {
    setIsDestroying(true);

    await destroyBilling({
      variables: {
        accountId: account.id,
      },
    });

    // Redirect to account page
    browserHistory.push(`/account/${account.id}`);

    // Reload billing in redux state
    loadAccountBilling(account.id);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>

      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/accounts">
          Accounts
        </Link>
        <Link component={RouterLink} to={`/account/${account.id}`}>
          {account.name}
        </Link>
        <Typography color="textPrimary">Billing (new)</Typography>
      </Breadcrumbs>

      <br />

      {!isBillingConfigured(billing) && (
        <Card>
          <CardContent>

            <CardTitle>
              <CardTitleIcon>
                <Icon value="plumpy plumpy-vkDKKBC6Xb7o" />
              </CardTitleIcon>
              Plan
            </CardTitle>

            <br />

            <Alert severity="info">
              Billing hasn't been configured for this account yet
            </Alert>

            <br />

            <CreateBilling
              account={account}
              onCreate={handleBillingCreate}
            />

          </CardContent>
        </Card>
      )}

      {isBillingConfigured(billing) && draftBilling && (
        <div>

          {saveError && (
            <div>
              <Alert severity="error">
                {saveError}
              </Alert>
              <br />
            </div>
          )}

          <SubscriptionStatus
            billing={draftBilling}
          />

          <Overview
            billing={billing}
            account={account}
            billingPlans={billingPlans}
            draftBilling={draftBilling}
            isBillingActive={isBillingActive(billing)}
            dispatch={dispatch}
            isSaving={isSaving}
            hasChanged={hasChanged}
          />

          {/* Discounts are currently only supported for active billing so we can support
          * a legacy discount. They're not available for new customers yet because we need
          * to do a bunch of work on the billing setup to make it work there too. */}
          {isBillingActive(billing) && (
            <Discounts
              draftBilling={draftBilling}
              dispatch={dispatch}
              isSaving={isSaving}
            />
          )}

          {draftBilling.plan === 'ultimate' ? (
            <PricesUltimate
              draftBilling={draftBilling}
              dispatch={dispatch}
              isSaving={isSaving}
            />
          ) : (
            <Prices
              draftBilling={draftBilling}
              oldBillingEstimate={oldBillingEstimate}
              newBillingEstimate={newBillingEstimate}
              billing={billing}
              billingPlans={billingPlans}
              isBillingActive={isBillingActive(billing)}
              dispatch={dispatch}
              isSaving={isSaving}
            />
          )}

          <Limits
            draftBilling={draftBilling}
            isBillingActive={isBillingActive(billing)}
            dispatch={dispatch}
            isSaving={isSaving}
          />

          <Features
            accountId={account.id}
            draftBilling={draftBilling}
            elementTrials={billing.element_trials}
            isBillingActive={isBillingActive(billing)}
            dispatch={dispatch}
            notify={notify}
            isSaving={isSaving}
          />

          <AddressLookupLicence
            account={account}
            draftBilling={draftBilling}
            isBillingActive={isBillingActive(billing)}
            dispatch={dispatch}
            isSaving={isSaving}
          />

          <Finance
            draftBilling={draftBilling}
            isBillingActive={isBillingActive(billing)}
            dispatch={dispatch}
            isSaving={isSaving}
          />

          {isBillingActive(billing) && (
            <Tax
              draftBilling={draftBilling}
              isBillingActive={isBillingActive(billing)}
              dispatch={dispatch}
              isSaving={isSaving}
            />
          )}

          <div className={classes.actionButtons}>
            <Button
              disabled={isSaving}
              onClick={handleDestroyClick}
              className={classes.destroyButton}
              variant="outlined"
            >
              Destroy billing
            </Button>

            <Button
              disabled={isSaving}
              onClick={handleSaveClick}
              color={hasChanged ? 'primary' : 'default'}
              variant="contained"
              className={classes.saveButton}
            >
              {isSaving ? 'Saving...' : 'Save changes'}
            </Button>
          </div>

          <DestroyDialog
            open={isDestroyDialogOpen}
            onClose={handleDestroyDialogClose}
            disabled={isDestroying}
            onConfirm={handleDestroyConfirm}
          />

          <ProrationDialog
            open={isProrationDialogOpen}
            onClose={handleProrationDialogClose}
            disabled={isSaving}
            onSave={handleProrationDialogSave}
          />

        </div>
      )}

    </div>
  );

}

const styles = (theme) => ({
  actionButtons: {
    overflow: 'auto',
  },
  destroyButton: {
    color: theme.palette.error.main,
  },
  saveButton: {
    float: 'right',
  },
});

BillingV2.propTypes = {
  account: PropTypes.object.isRequired,
  notify: PropTypes.func.isRequired,
  loadAccountBilling: PropTypes.func.isRequired,
};

export default withStyles(styles)(BillingV2);
