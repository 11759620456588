import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';

import { DateTimePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';

const FreeTrialExpiresAt = ({ account, updateAccount, classes }) => {

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const handleOnChange = async (freeTrialExpiresAt) => {

    setIsSaving(true);

    try {
      await updateAccount(account.id, { free_trial_expires_at: freeTrialExpiresAt.format() }, 'Free Trial expiry date updated');
      setIsSaving(false);
    } catch (err) {
      setError(get(err, 'error.message', 'Oh shoot! An unknown error occurred.'));
    }
  };

  return (
    <div className={classes.wrapper}>

      {error && (
        <Alert severity="error">
          {error}
        </Alert>
      )}
      <DateTimePicker
        clearable
        disabled={isSaving}
        value={account.free_trial_expires_at}
        onChange={handleOnChange}
        minDate={new Date()}
      />
    </div>
  );
};

const styles = () => ({
  wrapper: {
    display: 'inline-block',
  },
});

FreeTrialExpiresAt.propTypes = {
  account: PropTypes.object.isRequired,
  updateAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(FreeTrialExpiresAt);
