import {
  map,
  get,
  trim,
} from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Tooltip,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import isEmail from 'validator/lib/isEmail';
import countryCodes from '@beacon/utils/countryCodes';

import arePropsEqual from 'utils/arePropsEqual';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Icon from '../../../../../icon';


const BillingFinance = ({
  draftBilling,
  isSaving,
  isBillingActive,
  dispatch,
  classes,
}) => {

  // There's no need to edit billing info for a customer before they've gone through the
  // billing setup process, so this is hidden.
  if (!isBillingActive) {
    return null;
  }

  const handleAddressKeyChange = subKey => (e) => {
    const newAddress = {
      ...draftBilling.stripe_customer_address,
      [subKey]: e.target.value || null,
    };
    dispatch({
      type: 'set',
      key: 'stripe_customer_address',
      value: newAddress,
    });
  };

  return (
    <Card>
      <CardContent>

        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-k8xXTceQVvPp" />
          </CardTitleIcon>
          Billing info
        </CardTitle>

        <br />

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Finance email address</strong>
              <Tooltip
                title="Where invoices, receipts, and other finance notifications should be sent"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.stripe_customer_email || ''}
              onChange={e => dispatch({
                type: 'set',
                key: 'stripe_customer_email',
                value: e.target.value,
              })}
              variant="outlined"
              required
              disabled={isSaving}
              error={(
                !trim(draftBilling.stripe_customer_email)
                || (
                  draftBilling.stripe_customer_email
                  && !isEmail(trim(draftBilling.stripe_customer_email))
                )
              )}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Organisation name</strong>
              <Tooltip
                title="Name of the organisation to show on invoices"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.stripe_customer_name || ''}
              onChange={e => dispatch({
                type: 'set',
                key: 'stripe_customer_name',
                value: e.target.value,
              })}
              variant="outlined"
              required
              disabled={isSaving}
              error={!trim(draftBilling.stripe_customer_name)}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Address</strong>
              <Tooltip
                title="Address shown on invoices"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Line 1"
              margin="normal"
              value={get(draftBilling, 'stripe_customer_address.line1') || ''}
              onChange={handleAddressKeyChange('line1')}
              variant="outlined"
              disabled={isSaving}
              fullWidth
            />
            <TextField
              label="Line 2"
              margin="normal"
              value={get(draftBilling, 'stripe_customer_address.line2') || ''}
              onChange={handleAddressKeyChange('line2')}
              variant="outlined"
              disabled={isSaving}
              fullWidth
            />
            <TextField
              label="City"
              margin="normal"
              value={get(draftBilling, 'stripe_customer_address.city') || ''}
              onChange={handleAddressKeyChange('city')}
              variant="outlined"
              disabled={isSaving}
              fullWidth
            />
            <TextField
              label="Region"
              margin="normal"
              value={get(draftBilling, 'stripe_customer_address.state') || ''}
              onChange={handleAddressKeyChange('state')}
              variant="outlined"
              disabled={isSaving}
              fullWidth
            />
            <TextField
              label="Postcode"
              margin="normal"
              value={get(draftBilling, 'stripe_customer_address.postal_code') || ''}
              onChange={handleAddressKeyChange('postal_code')}
              variant="outlined"
              disabled={isSaving}
              fullWidth
            />
            <TextField
              label="Country"
              margin="normal"
              value={get(draftBilling, 'stripe_customer_address.country') || 'GB'}
              onChange={handleAddressKeyChange('country')}
              variant="outlined"
              disabled={isSaving}
              fullWidth
              select
              SelectProps={{
                native: true,
              }}
            >
              {map(countryCodes, option => (
                <option
                  key={option.code}
                  value={option.code}
                >
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );

};

const styles = theme => ({
  fieldLabel: {
    marginTop: theme.spacing(2),
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
});

BillingFinance.propTypes = {
  draftBilling: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isBillingActive: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Only re-render when these props change
const watchedProps = [
  'isSaving',
  'isBillingActive',
  'draftBilling.stripe_customer_email',
  'draftBilling.stripe_customer_name',
  'draftBilling.stripe_customer_address',
];

export default withStyles(styles)(memo(BillingFinance, arePropsEqual(watchedProps)));
