import env from './env';

const appKeys = {
  production: 'b719d48ef57607c377f8',
  staging: 'ff4149a449bbf73cb022',
  development: '0c8387cc06639c404e89',
};

const appKey = appKeys[env] || appKeys.development;

export const PUSHER_APP_KEY = appKey;
export const PUSHER_APP_CLUSTER = 'eu';
export const PUSHER_LOG = env === 'development' || env === 'staging';
