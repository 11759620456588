import {
  map,
  isNil,
  isInteger,
} from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  InputAdornment,
  TextField,
  MenuItem,
  Tooltip,
  Grid,
  Checkbox,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import NumberInput from 'components/NumberInput';
import { DatePicker } from '@material-ui/pickers';
import red from '@material-ui/core/colors/red';
import moment from 'moment';

import arePropsEqual from 'utils/arePropsEqual';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';

import Icon from '../../../../../../icon';
import ChangePlan from './ChangePlan';

const BillingOverview = ({
  draftBilling,
  billing,
  account,
  isSaving,
  isBillingActive,
  billingPlans,
  dispatch,
  saveButton,
  classes,
}) => (
  <Card>
    <CardContent>

      <CardTitle>
        <CardTitleIcon>
          <Icon value="plumpy plumpy-vkDKKBC6Xb7o" />
        </CardTitleIcon>
        Plan overview
      </CardTitle>

      <br />

      <Grid container spacing={5}>
        <Grid item xs={4}>
          <Typography variant="body1" className={classes.fieldLabel}>
            <strong>Plan</strong>
            <Tooltip
              title="Which plan is this customer on? (Note that you can still change prices, limits and feature flags on this page - regardless of this setting)"
              placement="top"
            >
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={draftBilling.plan}
            fullWidth
            // need to destroy billing and start again if you want to change plan
            // because there are lots of prices and things that would need to change
            disabled
            variant="outlined"
            select
          >
            {map(billingPlans, (plan) => (
              <MenuItem
                key={plan.key}
                value={plan.key}
              >
                {plan.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {isBillingActive && billing.plan !== 'ultimate' && (
          <Grid item xs={2}>
            <ChangePlan billing={billing} account={account} />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={4}>
          <Typography variant="body1" className={classes.fieldLabel}>
            <strong>Notes</strong>
            <Tooltip
              title="Internal notes about the price plan that this customer is on. Because memory is hard."
              placement="top"
            >
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={draftBilling.notes || ''}
            onChange={(e) => dispatch({
              type: 'set',
              key: 'notes',
              value: e.target.value,
            })}
            fullWidth
            disabled={isSaving}
            variant="outlined"
            multiline
          />
        </Grid>
      </Grid>

      {/* We don't support 10% annual discount on ultimate. Just stick the number you want it to be
      * and that's it really. */}
      {billing.plan !== 'ultimate' && (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Annual payment % discount</strong>
              <Tooltip
                title="If the customer opts for annual payment, this % will be automatically deducted to ALL prices on this page. E.g. if a customer was buying 1 user on the Premium plan, the monthly cost would be £100. Paid annually, a 10% discount would bring this to £90/month: (£100 x 0.9) x 12 = £1,080"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.technology_annual_discount_percent}
              error={isNil(draftBilling.technology_annual_discount_percent) || draftBilling.technology_annual_discount_percent > 100}
              variant="outlined"
              disabled={isSaving}
              fullWidth
              InputProps={{
                inputComponent: NumberInput,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(e) => dispatch({
                type: 'set_number',
                key: 'technology_annual_discount_percent',
                value: e.target.value,
              })}
            />
          </Grid>
        </Grid>
      )}

      {/* We can't change the frequency before billing is active */}
      {isBillingActive && (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Frequency</strong>
              <Tooltip
                title={draftBilling.collection_method === 'send_invoice'
                  ? 'How often (in months) invoices are sent to the customer'
                  : 'How often (in months) the customer\'s card is charged'}
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.technology_plan_frequency}
              onChange={(e) => dispatch({
                type: 'set_number',
                key: 'technology_plan_frequency',
                value: e.target.value,
              })}
              variant="outlined"
              disabled
              error={(
                !isInteger(draftBilling.technology_plan_frequency)
                || draftBilling.technology_plan_frequency < 1
                || draftBilling.technology_plan_frequency > 12
              )}
              fullWidth
              InputProps={{
                inputComponent: NumberInput,
              }}
            />
          </Grid>
        </Grid>
      )}

      {/* User chooses this during the setup process, we can't pre-set it */}
      {isBillingActive && (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Collection method</strong>

              <Tooltip
                title="Is the customer paying via card, or invoice?"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.collection_method}
              onChange={(e) => dispatch({
                type: 'set',
                key: 'collection_method',
                value: e.target.value,
              })}
              variant="outlined"
              fullWidth
              select
              disabled // always disabled
            >
              <MenuItem value="charge_automatically">
                Charge card automatically
              </MenuItem>
              <MenuItem value="send_invoice">
                Send invoices via email
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      )}

      {billing.plan !== 'ultimate' && (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Always allow invoice billing?</strong>
              <Tooltip
                title="For the rare case when a customer doesn't have a credit card, but doesn't want to pay annually via invoice. This will allow monthly invoice billing - but this is NOT cool! They should either get a credit card, or switch to annual."
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              className={classes.flagCheckbox}
              color="primary"
              checked={draftBilling.is_send_invoice_always_allowed || false}
              disabled={isSaving}
              onChange={(e) => dispatch({
                type: 'set',
                key: 'is_send_invoice_always_allowed',
                value: e.target.checked,
              })}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}

      {/* We don't support 10% annual discount on ultimate. Just stick the number you want it to be
      * and that's it really. */}

      <Grid container spacing={5}>
        <Grid item xs={4}>
          <Typography variant="body1" className={classes.fieldLabel}>
            <strong>Invoice days until due</strong>
            <Tooltip
              title="If the customer chooses to pay by invoice, how many days until their invoices should be due?"
              placement="top"
            >
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={draftBilling.send_invoice_days_until_due}
            error={isNil(draftBilling.send_invoice_days_until_due) || draftBilling.send_invoice_days_until_due < 0 || draftBilling.send_invoice_days_until_due > 90}
            variant="outlined"
            disabled={isSaving}
            fullWidth
            InputProps={{
              inputComponent: NumberInput,
              endAdornment: <InputAdornment position="end">days</InputAdornment>,
            }}
            onChange={(e) => dispatch({
              type: 'set_number',
              key: 'send_invoice_days_until_due',
              value: e.target.value,
            })}
          />
        </Grid>
      </Grid>

      {/*
        If subscription is scheduled to start in the future then allow
        the editing of this field, otherwise don't render. Note that we do this
        based on saved state, not draft state
      */}
      {billing.subscription_start_at && moment(billing.subscription_start_at).isAfter(moment()) && (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Subscription start date</strong>
              <Tooltip
                title="When the customer's subscription is set to start. You can change this to a date in the future, or to today to start the subscription immediately."
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              inputVariant="outlined"
              fullWidth
              disabled={isSaving}
              value={draftBilling.subscription_start_at}
              format="Do MMMM, YYYY"
              onChange={(e) => dispatch({
                type: 'set',
                key: 'subscription_start_at',
                value: e.toISOString(),
              })}
              disablePast
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={5}>
        <Grid item xs={4}>
          <Typography variant="body1" className={classes.fieldLabel}>
            <strong>Services day rate</strong>
            <Tooltip
              title="How much we charge per day (excluding tax) for implementation/services/training."
              placement="top"
            >
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={draftBilling.services_day_rate_amount || ''}
            error={isNil(draftBilling.services_day_rate_amount)}
            variant="outlined"
            disabled={isSaving}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">£</InputAdornment>,
              inputComponent: NumberInput,
            }}
            inputProps={{ // eslint-disable-line
              decimalScale: 2,
              fixedDecimalScale: true,
            }}
            onChange={(e) => dispatch({
              type: 'set_number',
              key: 'services_day_rate_amount',
              value: e.target.value,
            })}
          />

        </Grid>
      </Grid>

      <Grid container spacing={5}>
        <Grid item xs={4}>
          <Typography variant="body1" className={classes.fieldLabel}>
            <strong>Services hourly rate</strong>
            <Tooltip
              title="How much we charge per hour (excluding tax) for implementation/services/training. Should be approx 1/6 of the day rate."
              placement="top"
            >
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={draftBilling.services_hour_rate_amount || ''}
            error={isNil(draftBilling.services_hour_rate_amount)}
            variant="outlined"
            disabled={isSaving}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">£</InputAdornment>,
              inputComponent: NumberInput,
            }}
            inputProps={{ // eslint-disable-line
              decimalScale: 2,
              fixedDecimalScale: true,
            }}
            onChange={(e) => dispatch({
              type: 'set_number',
              key: 'services_hour_rate_amount',
              value: e.target.value,
            })}
          />

        </Grid>
      </Grid>

    </CardContent>
  </Card>
);

const styles = (theme) => ({
  fieldLabel: {
    marginTop: theme.spacing(2),
  },
  flagCheckbox: {
    marginTop: theme.spacing(1),
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
  trialExpired: {
    color: red[500],
    marginLeft: theme.spacing(1),
  },
  billingCalculationMenuItem: {
    width: 500,
    whiteSpace: 'normal',
  },
});

BillingOverview.propTypes = {
  billing: PropTypes.object.isRequired, // saved state
  account: PropTypes.object.isRequired, // saved state
  draftBilling: PropTypes.object.isRequired, // draft state
  isSaving: PropTypes.bool.isRequired,
  isBillingActive: PropTypes.bool.isRequired,
  billingPlans: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const watchedProps = [
  'isSaving',
  'isBillingActive',
  'account',
  'draftBilling.plan',
  'draftBilling.notes',
  'draftBilling.technology_annual_discount_percent',
  'draftBilling.collection_method',
  'draftBilling.technology_plan_frequency',
  'draftBilling.is_send_invoice_always_allowed',
  'draftBilling.send_invoice_days_until_due',
  'billing.subscription_start_at',
  'draftBilling.subscription_start_at',
  'draftBilling.services_day_rate_amount',
  'draftBilling.services_hour_rate_amount',
];

export default withStyles(styles)(memo(BillingOverview, arePropsEqual(watchedProps)));
