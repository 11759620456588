import { isInteger, map, trim } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import { withStyles } from '@material-ui/core/styles';

import {
  TextField, TableRow,
  TableHead, TableCell, TableBody, Table, CardContent,
  Card, Typography,
} from '@material-ui/core';


import browserHistory from 'app/history';
import Loading from 'components/Loading';


class UserList extends Component {

  static handleRowClick(accountId) {
    return () => {
      browserHistory.push(`/user/${accountId}`);
    };
  }

  constructor() {
    super();

    this.state = {
      searchQuery: '',
      isFakeLoading: false,
    };

    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
  }

  componentDidMount() {
    const { loadUsersList } = this.props;

    loadUsersList({
      page: 1,
    });
  }

  handleLoadMore(page) {
    const { loadUsersList } = this.props;

    loadUsersList({
      page,
    });
  }

  handleSearchQueryChange(e) {
    const { loadUsersList } = this.props;

    const searchQuery = e.target.value;

    this.setState({
      searchQuery,
      isFakeLoading: true,
    });

    clearTimeout(this.searchLoadTimeout);

    this.searchLoadTimeout = setTimeout(() => {
      loadUsersList({
        page: 1,
        query: searchQuery,
      });

      this.setState({
        isFakeLoading: false,
      });
    }, 300);
  }

  render() {
    const {
      isLoading,
      isLoadingMore,
      users,
      totalUsers,
      classes,
    } = this.props;
    const {
      searchQuery,
      isFakeLoading,
    } = this.state;

    return (
      <div>
        <Typography variant="h6">
          {'Users'}
          {isInteger(totalUsers) && ` (${totalUsers})`}
        </Typography>

        <Card>
          <CardContent className={classes.userWrapper}>

            <TextField
              variant="outlined"
              className={classes.searchBar}
              fullWidth
              placeholder="Search users"
              value={searchQuery}
              onChange={this.handleSearchQueryChange}
              autoFocus
            />

            <br />
            <br />

            {isLoading || isFakeLoading ? (
              <Loading />
            ) : (
              <div>

                <InfiniteScroll
                  loadMore={this.handleLoadMore}
                  loader={<span key={0} />}
                  hasMore={totalUsers > users.length && !isLoadingMore && !trim(searchQuery)}
                  pageStart={1}
                  initialLoad={false}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Created</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(users, user => (
                        <TableRow
                          key={user.id}
                          className={classes.tableRow}
                          onClick={UserList.handleRowClick(user.id)}
                        >
                          <TableCell>{user.id}</TableCell>
                          <TableCell>
                            {user.first_name}
                            {' '}
                            {user.last_name}
                          </TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{moment(user.created_at).format('ll')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                </InfiniteScroll>

                {isLoadingMore && (
                  <div>
                    <br />
                    <Loading />
                  </div>
                )}

              </div>
            )}

          </CardContent>
        </Card>

      </div>
    );
  }

}

const styles = theme => ({
  userWrapper: {
    overflow: 'auto',
  },
  searchBar: {
    paddingBottom: theme.spacing(0.5),
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    height: 60,
  },
  tableCellHeader: {
    borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[1],
  },
  userTitle: {
    padding: 10,
  },
});

UserList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  loadUsersList: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  totalUsers: PropTypes.number,
};

export default withStyles(styles)(UserList);
