import { includes } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import {
  CardContent,
  Card,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Button,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowUp';
import { withStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client';

import Alert from 'components/Alert';
import Loading from 'components/Loading';
import CardTitle from 'components/CardTitle';
import HealthStatusIcon from 'components/HealthStatusIcon';
import usePusherChannel from 'utils/usePusherChannel';
import usePusherEvent from 'utils/usePusherEvent';

import getHelpfulDisabledText from '../../../../../utils/getHelpfulDisabledMessage';
import Icon from '../../../../../../icon';
import ToggleAppIsEnabled from './ToggleAppIsEnabled';

const CHECK_APP_CONFIGURATIONS_HEALTH_STATUS = gql`
  mutation UpdateAppConfigurationHealthStatus($id: Int, $account_id: Int) {
    updateAppConfigurationHealthStatus(id: $id, account_id: $account_id) {
      id
      health_status
      health_description
    }
  }
`;

const RUN_APP_CONFIGURATIONS_SCHEDULE = gql`
  mutation runAppConfigurationSchedule($routing_id: String!, $account_id: Int) {
    runAppConfigurationSchedule(routing_id: $routing_id, account_id: $account_id) {
      id
    }
  }
`;

const Apps = ({
  configuration,
  classes,
}) => {

  const isPollingApp = includes(['enthuse', 'fundraiseup', 'justgiving', 'muchloved'], configuration.app.key);
  const { id, account_id, routing_id } = configuration || {};

  const [isRunningSchdule, setIsRunningSchdule] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  const [checkAppConfigurationsHealthStatus, { loading: loadingHealthStatus }] = useMutation(
    CHECK_APP_CONFIGURATIONS_HEALTH_STATUS,
    {
      variables: {
        id,
        account_id,
      },
    },
  );

  const [runAppConfigurationSchedule] = useMutation(
    RUN_APP_CONFIGURATIONS_SCHEDULE,
    {
      variables: {
        routing_id,
        account_id,
      },
    },
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDropdown = () => setIsExpanded(!isExpanded);

  const channelName = `private-account-${configuration.account.id}-admin-dashboard`;
  const { channel } = usePusherChannel(channelName);
  usePusherEvent(channel, `run-schedule-${routing_id}`, ({ message: response }) => {
    const { status: newStatus } = response;

    setStatus(newStatus);
    setIsRunningSchdule(false);

    if (newStatus === 'success') {
      setMessage(
        `Polling has been successfully run on ${moment().format('lll')}. `
        + 'This only means there was no problem running the polling, it does not mean the data was added successfully.',
      );
    }
    if (newStatus === 'error') {
      setMessage(`Error running polling: ${response.message}`);
    }
  });

  return (
    <Card>
      <CardContent>
        <CardTitle className={classes.cardTitle}>
          <span className={classes.cardLabel}>
            <img src={configuration.app.icon} alt={configuration.app.title} className={classes.appIcon} />
            {`${configuration.app.title}${getHelpfulDisabledText({ app: configuration.app, configuration })}`}
            <HealthStatusIcon configuration={configuration} />
          </span>

          <IconButton
            aria-label={`Open ${configuration.app.title} dropdown`}
            onClick={toggleDropdown}
          >
            <Tooltip
              placement="top"
              title={isExpanded ? `Hide ${configuration.app.title}` : `Show ${configuration.app.title}`}
            >
              <KeyboardArrowDownIcon
                className={classNames({
                  [classes.expandIcon]: true,
                  [classes.expandIconExpanded]: !isExpanded,
                })}
              />
            </Tooltip>
          </IconButton>
        </CardTitle>

        <Collapse in={isExpanded}>
          <br />

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Installed</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {moment(configuration.created_at).format('ll')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Is enabled?</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={classes.inline}>
                {configuration.is_enabled ? 'Yes' : 'No'}
              </Typography>
              <div className={classes.inline}>
                <ToggleAppIsEnabled
                  account={configuration.account}
                  configuration={configuration}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Health status</strong>
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ alignItems: 'center' }}>
              {loadingHealthStatus ? (
                <Loading
                  size={24}
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                />
              ) : (
                <HealthStatusIcon configuration={configuration} />
              )}

              <div className={classNames({
                [classes.inline]: true,
                [classes.expandIconExpanded]: isExpanded,
              })}
              >
                <div className={classes.wrapper}>
                  <Button
                    onClick={checkAppConfigurationsHealthStatus}
                    disabled={loadingHealthStatus}
                  >
                    <RefreshIcon className={classes.buttonIcon} />
                    {' '}
                    Check health status
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Routing ID</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {configuration.routing_id}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Last updated</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {moment(configuration.updated_at).format('lll')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Disabled</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {configuration.disbled_at
                  ? moment(configuration.disabled_at).format('ll')
                  : ''}
              </Typography>
            </Grid>
          </Grid>

          {isPollingApp && (
            <>
              <br />
              <Divider variant="middle" />
              <br />

              <CardTitle className={classes.cardTitle}>
                <span className={classes.cardLabel}>
                  <Icon value="plumpy plumpy-g9bwTL1qiomp" />
                  {`${configuration.app.title} Polling`}
                </span>
              </CardTitle>

              <br />

              {status && (
                <Alert
                  type={status === 'success' ? 'info' : 'error'}
                  message={message}
                />
              )}

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Next poll run</strong>
                    <Tooltip
                      title="What does polling mean? Polling is the process of checking for new data from the app and adding it into Beacon."
                      placement="top"
                    >
                      <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {moment(configuration.run_next_schedule_at).format('lll')}

                    <div className={classNames({
                      [classes.inline]: true,
                      [classes.expandIconExpanded]: isExpanded,
                    })}
                    >
                      <div className={classes.wrapper}>
                        <Button
                          onClick={async () => {
                            setIsRunningSchdule(true);
                            await runAppConfigurationSchedule();
                          }}
                          disabled={isRunningSchdule}
                        >
                          <RefreshIcon className={classes.buttonIcon} />
                          {' '}
                          {isRunningSchdule ? 'Runing poll' : 'Run poll'}
                        </Button>
                      </div>
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

        </Collapse>
      </CardContent>
    </Card>

  );
};

Apps.propTypes = {
  configuration: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0',
  },
  cardLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '16px',
  },
  appIcon: {
    width: 32,
    height: 32,
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandIconExpanded: {
    transform: 'rotate(180deg)',
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
  inline: {
    display: 'inline',
    marginRight: theme.spacing(1),
  },
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
});

export default withStyles(styles)(Apps);
