import {
  map,
  filter,
  isArray,
} from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import CardTitleIcon from 'components/CardTitleIcon';
import CardTitle from 'components/CardTitle';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import browserHistory from 'app/history';
import Loading from 'components/Loading';

import Icon from '../../../../../icon';


const getCountLabel = userAccounts => filter(userAccounts, {
  is_deactivated: false,
  is_invited: false,
}).length;


const UserAccounts = ({
  userId,
  loadUserAccounts,
  userAccounts,
  isLoading,
}) => {

  useEffect(() => {
    loadUserAccounts(userId);
  }, [loadUserAccounts, userId]);

  // const isLoading = isUndefined(userAccounts);

  const handleClick = id => () => browserHistory.push(`/account/${id}`);

  return (
    <Card>
      <CardContent>

        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-ZQcnHO3UJQZy" />
          </CardTitleIcon>
          {'Accounts'}
          {isArray(userAccounts) && ` (${getCountLabel(userAccounts)})`}
        </CardTitle>

        <br />

        {isLoading ? (
          <Loading />
        ) : (
          <List>
            {map(userAccounts, userAccount => (
              <ListItem
                button
                key={userAccount.id}
                onClick={handleClick(userAccount.account_id)}
              >
                <ListItemText
                  primary={`${userAccount.account.name}`}
                  secondary={(
                    <span>
                      {userAccount.account.id}
                      {' · '}
                      {userAccount.account.is_locked ? 'Account is locked' : 'Account is unlocked'}
                      {' · '}
                      {moment(userAccount.account.created_at).format('ll')}
                    </span>
                  )}
                />
              </ListItem>
            ))}
          </List>
        )}


      </CardContent>
    </Card>

  );
};


UserAccounts.propTypes = {
  loadUserAccounts: PropTypes.func.isRequired,
  userAccounts: PropTypes.arrayOf(PropTypes.object),
  userId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default UserAccounts;
