import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import Loading from 'components/Loading';

import Overview from './Overview';
import Billing from './Billing';

const Account = ({
  accountId,
  loadAccount,
  loadAccountBilling,
  loadAccountUsers,
  loadEntityTypes,
  isLoading,
}) => {
  useEffect(() => {
    loadAccount(accountId);
    loadAccountBilling(accountId);
    loadAccountUsers(accountId);
    loadEntityTypes(accountId);
  }, [
    accountId,
    loadAccount,
    loadAccountBilling,
    loadAccountUsers,
    loadEntityTypes,
  ]);

  return (
    <div>

      {isLoading ? (
        <Loading />
      ) : (
        <div>

          <Switch>
            <Route exact path="/account/:account_id" component={Overview} />
            <Route exact path="/account/:account_id/billing" component={Billing} />
          </Switch>

        </div>
      )}

    </div>
  );
};

Account.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  accountId: PropTypes.number.isRequired,
  loadAccount: PropTypes.func.isRequired,
  loadAccountBilling: PropTypes.func.isRequired,
  loadAccountUsers: PropTypes.func.isRequired,
  loadEntityTypes: PropTypes.func.isRequired,
};

export default Account;
