import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateByKey } from 'reducers';
import {
  loadAccount,
  updateAccount,
  loadAccountUsers,
  loadAccountBillingUsage,
} from 'actions/account';
import { filter, find, isUndefined } from 'lodash';
import notify from 'actions/notify';
import isBeaconEmail from '@beacon/utils/isBeaconEmail';
import Account from './component';

const selector = createSelector([
  (state, props) => Number(props.match.params.account_id),
  getStateByKey('account.byId'),
  getStateByKey('account.billingById'),
  getStateByKey('account.accountUsersById'),
  getStateByKey('account.billingUsage'),
  getStateByKey('user.currentUser'),
], (accountId, accountsById, billingById, accountUsersById, billingUsage, currentUser) => {

  const account = accountsById[accountId];
  const billing = billingById[accountId];
  const accountUsers = filter(
    accountUsersById[accountId],
    ['is_temporary_access', false],
  );
  const currentUserAccountUser = find(accountUsersById[accountId], ['user_id', currentUser.id]);
  // If changing from read only, then there needs to be at least
  // 1 user slot free
  const numberOfFullUsers = filter(accountUsers, (accountUser) => {
    // Exclude beacon emails
    if (isBeaconEmail(accountUser.user.email)) {
      return false;
    }

    // Exclude beacon partners
    if (accountUser.user.is_partner) {
      return false;
    }

    // Exclude deactivated
    if (accountUser.is_deactivated) {
      return false;
    }

    // Exclude read only
    if (accountUser.role === 'read_only') {
      return false;
    }

    // Otherwise all good
    return true;
  }).length;

  return {
    accountId,
    account,
    billing,
    accountUsers,
    isLoadingBillingUsage: isUndefined(billingUsage),
    billingUsage,
    currentUser,
    currentUserAccountUser,
    numberOfFullUsers,
  };
});

const mapDispatchToProps = {
  notify,
  loadAccount,
  updateAccount,
  loadAccountUsers,
  loadAccountBillingUsage,
};

export default connect(
  selector,
  mapDispatchToProps,
)(Account);
