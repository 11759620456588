import React, { useState } from 'react';
import {
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Icon from '../../../../../../icon';
import AccountConfigDialog from './AccountConfigDialog';

function CopyAccountConfigButton({
  account,
  classes,
}) {
  const [open, setOpen] = useState(false);

  const onOpen = async () => setOpen(true);

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>

      <Button
        onClick={onOpen}
        color="secondary"
        variant="outlined"
        className={classes.configButton}
      >
        <Icon
          value="S8veKJ9H8EOa"
          alt="Copy account icon"
          color="000F4F"
          className={classes.buttonIcon}
        />
        {' '}
        Config
      </Button>

      {open && (
        <AccountConfigDialog account={account} onClose={onClose} />
      )}
    </div>
  );
}

CopyAccountConfigButton.propTypes = {
  account: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  configButton: {
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(CopyAccountConfigButton);
