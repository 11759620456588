import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateByKey } from 'reducers';
import PortalFormSubmission from './component';

const selector = createSelector([
  getStateByKey('user'),
], (currentUser) => ({ currentUser }));

export default connect(
  selector,
)(PortalFormSubmission);
