import { isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateByKey } from 'reducers';
import { loadUnpaidInvoices } from 'actions/finance';
import UnpaidInvoices from './component';

const selector = createSelector([
  getStateByKey('finance.unpaidInvoices'),
], (unpaidInvoices) => ({
  unpaidInvoices,
  isLoading: isUndefined(unpaidInvoices),
}));

const mapDispatchToProps = {
  loadUnpaidInvoices,
};

export default connect(
  selector,
  mapDispatchToProps,
)(UnpaidInvoices);
