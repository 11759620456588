import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'; // v1.x
import PropTypes from 'prop-types';

// import grey from '@material-ui/core/colors/grey';


// variable up here so it can be re-used
const palette = {
  // flare red
  primary: {
    main: '#FF0069',
    contrastText: '#fff',
  },
  // beacon blue
  secondary: {
    // light: '#315464',
    main: '#000F4F',
    // dark: '#000015',
    contrastText: '#fff',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
  },

  // Other brand colours (custom theme colors, can be
  // used via `theme.palette.sunshineYellow.main` syntax
  // From brand guidelines:
  // https://drive.google.com/file/d/1cM5rqURQ0dbkJVBkkzDdGmPRs7FE7RZ8/view
  sunshineYellow: {
    main: '#FFBA00',
  },
  indigo: {
    main: '#9E15BF',
  },
  slate: {
    main: '#1F2B37',
  },
  ivory: {
    main: '#F5F5FA',
  },
  skyBlue: {
    main: '#458CFF',
  },
  mint: {
    main: '#29E8BA',
  },
  lavender: {
    main: '#BB93FF',
  },
  oceanTeal: {
    main: '#1CC9CC',
  },
};

const AdminTheme = createTheme({

  // Add our brand font
  typography: {
    fontFamily: 'greycliff-cf, sans-serif',
  },

  palette,

  overrides: {

    MuiCard: {
      root: {
        marginBottom: 25,
        borderRadius: 10, // slightly more than the default 4
      },
      // .MuiCardContent-root-168:last-child
    },

    MuiPaper: {
      root: {
        color: '#000',
        fontSize: '1rem',
      },

      // let's chill out on the shadows this isn't a cave
      elevation2: {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
      },
    },

    MuiTypography: {

      body2: {
        fontSize: '0.9rem',
      },
      // h4: {
      //   marginBottom: 25,
      //   fontWeight: 350,
      //   fontSize: '2em',
      // },
      h5: {
        fontSize: '1em',
        color: '#777',
        margin: 0,
        fontWeight: 700,
        padding: '0 22px',
        marginBottom: 25,
        position: 'relative',
        display: 'inline-block',
      },
      h6: {
        color: palette.text.primary, // theme.palette.text.primary
        fontWeight: 700,
        paddingBottom: 10,
      },
      caption: {
        fontSize: '0.85rem',
        fontWeight: 500,
        letterSpacing: 'inherit',
      },
      subtitle1: {
        marginBottom: 15,
        lineHeight: 1.5,
      },
    },

    MuiAccordionSummary: {
      root: {
        color: palette.text.primary,
      },
    },

    MuiAlert: {
      standardSuccess: {
        border: '1.5px solid #388e3c',
      },
      standardInfo: {
        border: '1.5px solid #1976d2',
      },
      standardWarning: {
        border: '1.5px solid #f57c00',
      },
      standardError: {
        border: '1.5px solid #d32f2f',
      },
    },

    // MuiChip: {
    //   avatar: {
    //     marginLeft: '0px !important', // needed to override combined class
    //   },
    // },

    MuiInput: {
      // make the hover underline not black! that's ugly
      underline: {
        '&:hover:not($disabled):not($focused):before': {
          borderBottom: '2px solid rgba(0, 0, 0, 0.6) !important',
          height: 2,
        },
      },
    },

    MuiTextField: {
      // https://v4.mui.com/components/text-fields/#customized-inputs
      // We want the secondary color on focus as primary resembles error state
      root: {
        '& label.Mui-focused': {
          color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: palette.secondary.main,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: palette.secondary.main,
          },
        },
      },
    },


    MuiDialogTitle: {
      root: {
        fontSize: 20,
      },
    },

    // make tooltips bigger and more helpful
    MuiTooltip: {
      popper: {
        maxWidth: 280,
      },
      tooltip: {
        fontSize: '0.95rem',
        padding: 10,
      },
    },

    MuiButtonBase: {
      root: {
        fontWeight: 500, // more than before
      },
    },

    MuiButton: {
      root: {
        borderRadius: 40,
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '0.95em', // bit bigger
        paddingLeft: 20,
        paddingRight: 20,
      },
      text: {
        paddingLeft: 12,
        paddingRight: 12,
      },
    },

    MuiMenuItem: {
      root: {
        fontWeight: 500,
      },
    },

    MuiListItemText: {
      primary: {
        fontWeight: 500,
      },
    },

    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '0.95rem', // bit bigger
      },
    },

  },
});

const Theme = ({ children }) => (
  <MuiThemeProvider theme={AdminTheme}>
    {children}
  </MuiThemeProvider>
);


Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
