import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Card,
  CardContent,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { compact, isEmpty, map } from 'lodash';
import confetti from 'canvas-confetti';

import CardTitleIcon from 'components/CardTitleIcon';
import CardTitle from 'components/CardTitle';
import Icon from '../../../../../../icon';
import Discount from './Discount';

const fireEmoji = ({ x, y }) => {
  const emoji = '💸';
  const scalar = 5.0;
  const shapes = [confetti.shapeFromText({ text: emoji, scalar })];
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const origin = {
    x: x / screenWidth,
    y: y / screenHeight,
  };

  const options = {
    origin,
    shapes,
    scalar,
    particleCount: 10,
    startVelocity: 12,
    spread: 45,
    gravity: -2,
    decay: 1.002,
    ticks: 200,
    angle: 0,
  };

  requestAnimationFrame(() => confetti(options));
};

function Discounts({
  draftBilling,
  dispatch,
  isSaving,
  classes,
}) {
  const [newDiscountIndex, setNewDiscountIndex] = useState(null);
  const discountInputRefs = useRef([]);

  useEffect(() => {
    if (newDiscountIndex !== null) {
      discountInputRefs.current[newDiscountIndex].focus();
    }
  }, [newDiscountIndex]);

  const discounts = compact(
    !isEmpty(draftBilling.discounts)
      ? draftBilling.discounts
      : [draftBilling.discount],
  );

  const handleAddDiscountClick = (e) => {
    const newDiscount = {
      name: '',
      monthly_amount_off: null,
      percent_off: null,
      duration: 'forever',
      duration_in_months: null,
    };
    const value = [...discounts, newDiscount];

    dispatch({
      type: 'set',
      key: 'discounts',
      value,
    });

    setNewDiscountIndex(value.length - 1);

    const rect = e.currentTarget.getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    // push y position down a bit so it doesn't overlap the new text input
    const y = rect.bottom + rect.height * 2;
    fireEmoji({ x, y });
  };

  const handleDiscountChange = (index, newDiscount) => {
    const newValue = [...discounts];
    newValue[index] = newDiscount;

    dispatch({
      type: 'set',
      key: 'discounts',
      value: compact(newValue),
    });
  };

  return (
    <Card>
      <CardContent>
        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-UgTNmFmesjlS" />
          </CardTitleIcon>
          Discount
        </CardTitle>

        <Typography variant="subtitle1">
          You can add custom discounts for customers! These will appear on the customer's invoice,
          {' '}
          <strong>in addition</strong>
          {' '}
          to the annual billing discount which is baked into the invoice line-items.
        </Typography>

        <Typography variant="subtitle1">
          <WarningIcon className={classes.warningIcon} />
          <strong>Important:</strong>
          {' '}
          Using multiple discounts? Discounts in Stripe "compound", and if you use both types of discount at once, the order matters. Double check the subscription in Stripe.
        </Typography>

        <br />

        {map(discounts, (discount, index) => (
          <Discount
            ref={(r) => { discountInputRefs.current[index] = r; }}
            key={index}
            discount={discount}
            onChange={(newDiscount) => handleDiscountChange(index, newDiscount)}
            isSaving={isSaving}
          />
        ))}

        <br />

        <Button
          onClick={handleAddDiscountClick}
          disabled={isSaving}
        >
          <AddIcon className={classes.buttonIcon} />
          Add discount
        </Button>

      </CardContent>
    </Card>
  );
}

Discounts.propTypes = {
  draftBilling: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

const styles = (theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  warningIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    marginTop: '-2px',
    color: theme.palette.grey[600],
  },
});

export default withStyles(styles)(Discounts);
