import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, Typography, Button, Dialog, DialogTitle, DialogActions,
  DialogContent,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ACCOUNT_FRAGMENT } from 'app/fragments';
import {
  map, join, isNil,
} from 'lodash';
import ApiKeyCreator from './ApiKeyCreator';

const GET_ACCOUNT = gql` 
  query GetAccount($id: Int) {
    account (id: $id) {
      ...Account
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

const ApiKeyCreatorSearch = ({
  classes,
  apiKeyListRefetch,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [query, setQuery] = useState('');

  const {
    error,
    data,
    refetch,
  } = useQuery(GET_ACCOUNT, {
    variables: {
      id: Number(query),
    },
    skip: query.length < 3,
  });

  const onClose = async () => {
    setQuery('');
    setIsDialogOpen(false);
  };

  const handleAccountSearchQueryChange = async (e) => {
    setQuery(e.target.value);
    if (e.target.value.length >= 3) {
      await refetch({
        id: Number(e.target.value),
      });
    }
  };

  // has an account been found?
  const foundQuery = data && !isNil(data.account);

  let hasApiKeys = false;

  if (foundQuery) {
    const { account } = data;
    // if an account ID has been entered, determine if it has API keys or not
    // as it determines whether we show a informative Alert which lists the keys
    hasApiKeys = account.apiKey.length > 0;
  }

  return (

    <div className={classes.wrapper}>
      <Button
        variant="contained"
        onClick={() => setIsDialogOpen(true)}
        className={classes.createButton}

      >
        Create API key
      </Button>

      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          Create a API key
        </DialogTitle>
        <DialogContent>

          {error && (
          <Alert severity="error">
            {getHelpfulErrorMessage(error)}
          </Alert>
          )}

          <Typography variant="subtitle1">
            Search for the account using the account ID
          </Typography>

          <TextField
            variant="outlined"
            className={classes.searchBar}
            fullWidth
            placeholder="Search by account ID"
            value={query}
            onChange={handleAccountSearchQueryChange}
            autoFocus
            type="number"
          />

          <br />
          <br />

          <div>
            { hasApiKeys ? (
              <div className={classes.apiKeys}>
                <Alert severity="info">
                  <>
                    <AlertTitle>
                      {`There's ${data.account.apiKey.length} API `}
                      {data.account.apiKey.length > 1 ? 'keys ' : 'key '}
                      {`for ${data.account.name} : `}
                    </AlertTitle>
                    <div className={classes.apiKeysList}>
                      {join(map(data.account.apiKey, 'last4'), ', ')}
                    </div>
                  </>
                </Alert>
              </div>
            ) : (
              <div>
                { foundQuery && (
                <Typography>
                  Create an API key for
                    {' '}
                  <b>{data.account.name}</b>
                </Typography>
                )}
              </div>
            )}
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Close
          </Button>
          {foundQuery && (
          <ApiKeyCreator
            key={query}
            accountId={query}
            apiKeyListRefetch={apiKeyListRefetch}
            apiKeyCreatorSearchRefetch={refetch}
          />
          )}
        </DialogActions>
      </Dialog>
    </div>

  );
};

const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
  createButton: {
    marginLeft: 10,
    alignText: 'center',
  },
  searchBar: {
    paddingBottom: theme.spacing(0.5),
  },
  apiKeysList: {
    display: 'flex',
    flexDirection: 'row',
  },
});

ApiKeyCreatorSearch.propTypes = () => ({
  apiKeyListRefetch: PropTypes.func.isRequired,
  apiKeysList: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
});

export default withStyles(styles)(ApiKeyCreatorSearch);
