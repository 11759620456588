import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { trim, get } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const ToggleEditSlug = ({
  account,
  updateAccount,
  classes,
}) => {

  const [isSaving, setIsSaving] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [newSlug, setNewSlug] = useState(account.slug);

  const handleToggleClick = () => {
    setIsDialogOpen(true);

  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setError(null);
  };

  const handleSlugChange = (e) => {
    setNewSlug(e.target.value);
  };

  const handleSaveClick = async () => {

    setIsSaving(true);

    try {
    // update the account (this call will also
    // sync the slug to the portal service)
      await updateAccount(account.id, {
        slug: newSlug,
      }, 'Slug updated');
      setIsSaving(false);
      setIsDialogOpen(false);
    } catch (err) {
      setError(get(err, 'error.full.errors[0].message', 'Oh shoot! An unknown error occurred.'));
      setIsSaving(false);
    }

  };

  return (
    <div className={classes.wrapper}>

      <Button
        onClick={handleToggleClick}
        disabled={isSaving}
      >
        Edit slug
      </Button>

      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={handleDialogClose}
      >
        <DialogTitle>
          Edit slug
        </DialogTitle>
        <DialogContent>

          {error && (
          <Alert
            severity="error"
            className={classes.alert}
          >
            {error}
          </Alert>
          )}

          <Typography variant="subtitle1">
            When you make this change all of the URLs for this account's forms will change.
            The smart bcc address will also change.
            Is that cool with you?
            {' '}
            💅
            {' '}
          </Typography>
          <TextField
            fullWidth
            className={classes.editTextBox}
            value={trim(newSlug) || ''}
            variant="outlined"
            required
            onChange={handleSlugChange}

          />

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveClick}
            disabled={isSaving}
          >
            {isSaving ? 'Saving' : 'Save slug'}
          </Button>
        </DialogActions>
      </Dialog>

    </div>

  );
};
const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
  editTextBox: {
    paddingTop: theme.spacing(1.5),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
});

ToggleEditSlug.propTypes = {
  account: PropTypes.object.isRequired,
  updateAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(ToggleEditSlug);
