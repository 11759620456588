import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/styles';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';

function ExpandIcon({
  isExpanded,
  onChange,
  label,
  classes,
}) {

  const handleToggleExpand = () => {
    onChange(!isExpanded);
  };

  return (
    <IconButton
      className={classes.expandIconButton}
      onClick={handleToggleExpand}
    >
      <Tooltip
        placement="top"
        title={isExpanded ? `Hide ${label}` : `Show ${label}`}
      >
        <KeyboardArrowDownIcon
          className={classNames({
            [classes.expandIcon]: true,
            [classes.expandIconExpanded]: isExpanded,
          })}
        />
      </Tooltip>
    </IconButton>
  );
}

ExpandIcon.defaultProps = {
  label: 'content',
};

ExpandIcon.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

const styles = () => ({
  expandIconButton: {
    float: 'right',
    position: 'relative',
    top: -8,
    right: -8,
  },
  expandIcon: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandIconExpanded: {
    transform: 'rotate(180deg)',
  },
});

export default withStyles(styles)(ExpandIcon);
