// Given an `err` object, get the most helpful error message
// for the user possible.
//
// Often this is from an API request, and there are details in the `error`
// object returned. If that is available, use that, otherwise, use the
// more standard `err.message`.
//
// Fall back to an unknown error
import { get, isError } from 'lodash';

const defaultFallback = 'Something went wrong! Please contact us on support@beaconcrm.org';

export default (err, fallback = defaultFallback) => {

  // Prioritise graphql network errors (just the first one)
  // Turns out Apollo client handles these pretty poorly so we need
  // to rip them out from deep within the error instance.
  // https://github.com/apollographql/apollo-client/issues/2810
  const firstGraphQLError = get(err, 'networkError.result.errors.0.message');
  if (firstGraphQLError) {
    return firstGraphQLError;
  }

  // Prioritise the http error messages
  const httpRaw = get(err, 'error.raw');
  if (httpRaw) {
    return httpRaw;
  }

  // then pass back the err.message
  if (isError(err) && err.message) {
    return err.message;
  }

  // and finally the fallback
  return fallback;

};
