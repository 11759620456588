import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogTitle, DialogActions,
  DialogContent, Typography,
} from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { API_KEYS_FRAGMENT } from 'app/fragments';
import Icon from '../../../../icon';

const REVOKE_API_KEY = gql`
  mutation RevokeAPIKey( 
    $id: Int!, 
    $input: ApiKeyInput!
  ) {
    updateAPIKey(
      id: $id
      input: $input  
    ){
      ...ApiKey
    }
  }
${API_KEYS_FRAGMENT}
`;

const ApiKeyRevoker = ({
  apiKey,
  classes,
  refetch,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);

  const [revokeAPIKey, {
    error,
  }] = useMutation(REVOKE_API_KEY, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleAPIKeyRevoking = async (id) => {
    setIsRevoking(true);
    await revokeAPIKey({
      variables: {
        id,
        input: {
          is_revoked: true,
          account_id: apiKey.account_id,
        },
      },
    });
    setIsDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const onClose = () => {
    setIsRevoking(false);
    setIsDialogOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleDialogOpen}
        disabled={isRevoking || apiKey.is_revoked}
      >
        Revoke
      </Button>

      <div className={classes.dialogBox}>
        <Dialog
          open={isDialogOpen}
          fullWidth
          onClose={onClose}
        >
          <div className={classes.titleAndIcon}>

            <Icon
              className={classes.icon}
              value="plumpy plumpy-ec36mAlg8v3P"
              width="3.5rem"
            />
            <DialogTitle
              className={classes.title}
            >
              Are you 100% sure?
            </DialogTitle>

            <Icon
              className={classes.icon}
              value="plumpy plumpy-ec36mAlg8v3P"
              width="3.5rem"
            />
          </div>
          <DialogContent>
            <Typography variant="subtitle1">
              Revoking keys can cause serious account breaking issues!
            </Typography>
            <Typography variant="subtitle1">
              You better be absolutely sure, as there's no going back.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              disabled={isRevoking}
            >
              Close
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleAPIKeyRevoking(apiKey.id)}
              disabled={isRevoking || apiKey.is_revoked}
            >
              {isRevoking ? 'Revoking' : 'Revoke'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {error && (
      <Alert severity="error">
        {getHelpfulErrorMessage(error)}
      </Alert>
      )}

    </div>
  );

};

const styles = (theme) => ({
  titleAndIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    padding: theme.spacing(1),
  },
});

ApiKeyRevoker.propTypes = {
  apiKey: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default withStyles(styles)(ApiKeyRevoker);
