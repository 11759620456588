import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Loading from 'components/Loading';
import ToggleTwoFactor from './ToggleTwoFactor';
import ToggleLock from './ToggleLock';
import TogglePartner from './TogglePartner';
import UserAccounts from './UserAccounts';

import Icon from '../../../../icon';


const User = ({
  userId,
  user,
  loadUser,
  isLoading,
  updateUser,
  classes,
  notify,
}) => {

  useEffect(() => {
    loadUser(userId);
  }, [loadUser, userId]);

  return (
    <div>

      {isLoading ? (
        <Loading />
      ) : (
        <div>

          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} to="/users">
              <Typography variant="h6">
              Users
              </Typography>
            </Link>
            <Typography variant="h6">
              {user.first_name}
              {' '}
              {user.last_name}
            </Typography>
          </Breadcrumbs>

          <br />

          <Card>
            <CardContent>

              <CardTitle>
                <CardTitleIcon>
                  <Icon value="plumpy plumpy-xXjlE05o3dcg" />
                </CardTitleIcon>
                Overview
              </CardTitle>

              <br />

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Name</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {user.first_name}
                    {' '}
                    {user.last_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Created</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {moment(user.created_at).format('ll')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Email</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {user.email}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Timezone</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {user.timezone}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Status</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {user.is_invited ? 'Invited' : 'Active'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Verfied email?</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {user.is_email_verified ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Is locked?</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" className={classes.inline}>
                    {user.is_locked ? 'Yes' : 'No'}
                  </Typography>
                  <div className={classes.inline}>
                    <ToggleLock
                      user={user}
                      updateUser={updateUser}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Is partner?</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" className={classes.inline}>
                    {user.is_partner ? 'Yes' : 'No'}
                  </Typography>
                  <div className={classes.inline}>
                    <TogglePartner
                      user={user}
                      updateUser={updateUser}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Two factor on?</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" className={classes.inline}>
                    {user.is_two_factor_enabled ? 'Yes' : 'No'}
                    {user.is_two_factor_enabled && ` (${user.two_factor_method})`}
                  </Typography>
                  {user.is_two_factor_enabled && (
                  <div className={classes.inline}>
                    <ToggleTwoFactor
                      user={user}
                      userId={userId}
                      notify={notify}
                    />
                  </div>
                  )}

                </Grid>
              </Grid>

            </CardContent>
          </Card>

          <UserAccounts
            userId={userId}
          />
        </div>

      )}

    </div>
  );

};

const styles = theme => ({
  inline: {
    display: 'inline',
    marginRight: theme.spacing(1),
  },
});

User.propTypes = {
  userId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  loadUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
};

export default withStyles(styles)(User);
