import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab';


const ProrationDialog = ({
  open,
  onClose,
  onConfirm,
  disabled,
}) => (
  <Dialog
    open={open}
    fullWidth
    onClose={onClose}
  >
    <DialogTitle>
      Delete billing permanently?
    </DialogTitle>

    <DialogContent>
      <Alert severity="warning">
        This will delete all of the customer's billing configuration, cancel their subscription in Stripe, and void all outstanding invoices.
      </Alert>
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose} disabled={disabled}>
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        disabled={disabled}
        variant="contained"
      >
        {disabled ? 'Deleting...' : 'Delete'}
      </Button>
    </DialogActions>
  </Dialog>
);

ProrationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ProrationDialog;
