import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import Alert from 'components/Alert';

const TogglePartner = ({
  user,
  updateUser,
  classes,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [partnerError, setPartnerError] = useState(null);

  const onClose = () => {
    setIsDialogOpen(false);
    setIsSaving(false);
  };

  return (
    <div className={classes.wrapper}>

      <Button
        onClick={async () => {
          if (user.is_partner) {

            setIsSaving(true);

            updateUser(user.id, {
              is_partner: false,
            }, 'User is no longer partnerised');

            setIsSaving(false);

          } else {

            setIsDialogOpen(true);

          }
        }}
        disabled={isSaving}
      >
        {user.is_partner && isSaving && 'Revoking partnerisation...'}
        {user.is_partner && !isSaving && 'Revoke partnerisation'}
        {!user.is_partner && 'Partnerise'}
      </Button>

      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          Give user partner access
        </DialogTitle>
        <DialogContent>

          {partnerError && (
            <Alert
              type="danger"
              message={partnerError}
            />
          )}

          <Typography variant="subtitle1">
            {'Are you sure you want to give '}
            <strong>
              {`${user.first_name} ${user.last_name}`}
            </strong>
            {' partner access?'}
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {

              try {
                setIsSaving(true);

                await updateUser(user.id, {
                  is_partner: true,
                }, 'User is now partnerised');

                onClose();
              } catch (e) {
                setIsSaving(false);
                setPartnerError(e.message);
              }
            }}
            disabled={isSaving}
          >
            {isSaving ? 'Partnerising...' : 'Partnerise'}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );

};

const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
});

TogglePartner.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default withStyles(styles)(TogglePartner);
