import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import moment from 'moment';
import { gql, useQuery } from '@apollo/client';
import {
  Card, CardContent, TableRow,
  TableHead, TableCell, TableBody, Table,
} from '@material-ui/core';
import { propTypes } from 'react-recaptcha';
import { API_KEYS_FRAGMENT } from 'app/fragments';
import {
  map, isEmpty, toString,
} from 'lodash';
import Loading from 'components/Loading';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { Alert } from '@material-ui/lab';
import Icon from '../../../../../icon';
import ApiKeyRevoker from '../../ApiKeyList/ApiKeyRevoker';
import ApiKeyCreator from '../../ApiKeyList/ApiKeyCreator';

const GET_API_KEYS = gql`
  query GetApiKeys($query: String, $offset: Int, $limit: Int) {
    apiKeys(query: $query, offset: $offset, limit: $limit){
      ...ApiKey
    }
  }
  ${API_KEYS_FRAGMENT}
`;

const ApiKeysSection = ({ classes, account }) => {

  const {
    error,
    data,
    refetch: refetchAccountsPageOverview,
    loading,
  } = useQuery(GET_API_KEYS, {
    variables: {
      query: toString(account.id),
      offset: 0,
      limit: 100,
    },
    fetchPolicy: 'network-only',
  });

  const hasApiKeys = data && !isEmpty(data.apiKeys);

  if (loading) {
    return <Loading />;
  }

  return (

    <div>
      <Card>
        <CardContent>
          <div className={classes.titleAndCreateButton}>
            <CardTitle>
              <CardTitleIcon>
                <Icon value="plumpy plumpy-ouUGCqMPbE3Q" />
              </CardTitleIcon>
              API keys
            </CardTitle>

            <br />

            <div className={classes.apiKeyCreatorButton}>
              <ApiKeyCreator
                accountId={account.id}
                apiKeyCreatorSearchRefetch={refetchAccountsPageOverview}
              />
            </div>

          </div>
          <div>
            { hasApiKeys && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Last 4 characters of API Key</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>API key created</TableCell>
                  <TableCell>Is revoked?</TableCell>
                  <TableCell>Revoke?</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {map(data.apiKeys, (apiKey) => (
                  <TableRow
                    key={apiKey.id}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      {apiKey.last4}
                    </TableCell>
                    <TableCell>{`Created by ${apiKey.created_by_user.email}`}</TableCell>
                    <TableCell>{moment(apiKey.created_at).format('ll')}</TableCell>
                    <TableCell>{`${apiKey.is_revoked}`}</TableCell>
                    <TableCell>
                      <ApiKeyRevoker
                        apiKey={apiKey}
                        refetch={refetchAccountsPageOverview}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            )}
          </div>

          {error && (

            <div>
              <Alert severity="error">
                {getHelpfulErrorMessage(error)}
              </Alert>
            </div>

          )}

        </CardContent>
      </Card>
    </div>
  );
};

const styles = (theme) => ({
  titleAndCreateButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  apiKeyCreatorButton: {
    paddingRight: theme.spacing(2),
  },
});

ApiKeysSection.propTypes = () => ({
  account: propTypes.object.isRequired,
});

export default withStyles(styles)(ApiKeysSection);
