import {
  keys,
  map,
  union,
  without,
  filter,
  includes,
  partition,
  find,
} from 'lodash';
import moment from 'moment';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { gql, useQuery, useMutation } from '@apollo/client';
import elementConfigs from '@beacon/utils/elements';
import getElementName from '@beacon/utils/getElementName';

import arePropsEqual from 'utils/arePropsEqual';
import Loading from 'components/Loading';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Expandomatic from 'components/Expandomatic';
import Icon from '../../../../../../icon';
import ExpandIcon from '../ExpandIcon';
import ElementTrial from './ElementTrial';

// Billing features with Element.elements for the element trials handling
const GET_BILLING_FEATURES = gql`
  query GetBillingFeatures($accountId: Int!, $version: Int!) {
    billingFeatures (version: $version) {
      key
      title
      elements
      plans
    }
    elements (account_id: $accountId, is_enabled: true) {
      key
    }
  }
`;

// Allow the admin user to force disable an Element.element from the dasboard
// in the case that an element trial has expired with element still in use and
// the accoun administrator has not acted on the notifications...
const DISABLE_ELEMENT = gql`
  mutation DisableElement($key: String!, $account_id: Int!) {
    disableElement(key: $key, account_id: $account_id) {
      key
    }
  }
`;

const BillingFeatures = ({
  accountId,
  draftBilling,
  elementTrials,
  isSaving,
  dispatch,
  notify,
  classes,
}) => {

  const {
    loading,
    data = {},
    refetch: refetchBillingFeatures,
  } = useQuery(GET_BILLING_FEATURES, {
    variables: {
      accountId,
      version: draftBilling.version,
    },
  });

  const [disableElement] = useMutation(DISABLE_ELEMENT);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const elementKeys = keys(elementConfigs);

  const getElementFeatures = (elementKey) => (
    filter(data.billingFeatures, ({ elements }) => (
      includes(elements, elementKey)
    ))
  );

  const handleFeatureToggle = (featureKey) => (e) => {
    const isEnabled = e.target.checked;

    let newEnabledFeatures;

    if (isEnabled) {
      newEnabledFeatures = union(draftBilling.enabled_features, [featureKey]);
    } else {
      newEnabledFeatures = without(draftBilling.enabled_features, featureKey);
    }

    dispatch({
      type: 'set',
      key: 'enabled_features',
      value: newEnabledFeatures,
    });
  };

  // Change the expiration date for an element trial
  const handleUpdateElementTrial = (elementKey) => (e) => {
    const state = draftBilling.element_trials || [];

    const [, rest] = partition(state, ['element', elementKey]);

    dispatch({
      type: 'set',
      key: 'element_trials',
      value: [...rest, {
        element: elementKey,
        expires: e.toISOString(),
      }],
    });
  };

  // This is possible only during the drafting stage - once the trial is set on
  // customer account billing it can only be updated. On toggle on the trial, a
  // default expiry date is set for midday in two weeks time, but this is
  // direcly configurable by a date picker
  const handleToggleElementTrial = (elementKey) => () => {
    const state = draftBilling.element_trials || [];

    const [[match], rest] = partition(state, ['element', elementKey]);

    dispatch({
      type: 'set',
      key: 'element_trials',
      value: match
        ? rest
        : [...rest, {
          element: elementKey,
          expires: moment()
            .hours(12)
            .minutes(0)
            .seconds(0)
            .add(2, 'weeks')
            .toISOString(),
        }],
    });
  };

  // Allow admin user to disable an element with force against a customer
  // dashboard account - note this is only possible in the UI where an element
  // trial is expired but the corresponding element is still in use.
  const handleDisableElement = (elementKey) => async () => {
    await disableElement({
      variables: {
        key: elementKey,
        account_id: accountId,
      },
    });
    notify({ message: 'Element disabled' });
    await refetchBillingFeatures();
  };

  return (
    <Card>
      <CardContent>

        <ExpandIcon
          onChange={handleExpandClick}
          isExpanded={isExpanded}
          label="features & elements"
        />

        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-k8xXTceQVvPp" />
          </CardTitleIcon>
          Allowed features
        </CardTitle>

        {isExpanded ? (

          <div>

            <br />

            {loading ? <Loading /> : (
              <div>

                {map(elementKeys, (elementKey) => {

                  const features = getElementFeatures(elementKey);

                  const isElementEnabled = (
                    elementKey === 'core' || includes(draftBilling.elements, elementKey)
                  );

                  // Is the element enabled by the customer in the dashboard (note
                  // this is different from whether it is purchased in `billing` or
                  // `draftBilling` - elements can be enabled either when purchased
                  // or when part of an unexpired element trial)
                  const isElementInUse = Boolean(find(
                    data.elements,
                    ['key', elementKey],
                  ));

                  // Is the trial (if exists) only in the draft state or has it been
                  // persisted to account billing already?
                  const isNewTrialSetting = !find(
                    elementTrials,
                    ['element', elementKey],
                  );

                  return (
                    <div key={elementKey}>
                      <div className={classes.actionHeading}>
                        <CardTitle>
                          {getElementName(elementKey)}
                        </CardTitle>

                        {!isElementEnabled && draftBilling.plan !== 'ultimate' && (
                        <ElementTrial
                          draftBilling={draftBilling}
                          elementKey={elementKey}
                          isInUse={isElementInUse}
                          isNew={isNewTrialSetting}
                          isSaving={isSaving}
                          onDisableElement={handleDisableElement(elementKey)}
                          onToggleTrial={handleToggleElementTrial(elementKey)}
                          onUpdateTrial={handleUpdateElementTrial(elementKey)}
                        />
                        )}
                      </div>

                      <Divider />

                      <br />

                      {!isElementEnabled && (
                      <div>
                        <Alert severity="info">
                          {'The '}
                          {getElementName(elementKey)}
                          {' element has not been purchased. You can still edit the feature flags below.'}
                        </Alert>
                        <br />
                      </div>
                      )}

                      {features.length ? (
                        <div>
                          {map(features, (feature) => (
                            <Grid
                              key={feature.key}
                              container
                              spacing={5}
                            >
                              <Grid item xs={4}>
                                <Typography variant="body1" className={classes.fieldLabel}>
                                  <strong>{feature.title}</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Checkbox
                                  className={classes.flagCheckbox}
                                  color="primary"
                                  checked={includes(draftBilling.enabled_features, feature.key)}
                                  disabled={isSaving}
                                  onChange={handleFeatureToggle(feature.key)}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                      ) : (
                        <Typography variant="subtitle1">
                          There aren't any feature flags in this element yet.
                        </Typography>
                      )}

                      <br />
                      <br />
                      <br />

                    </div>
                  );

                })}

              </div>
            )}

          </div>
        ) : (
          <Expandomatic onClick={() => setIsExpanded(true)}>
            Click to expand 👉👉
          </Expandomatic>
        )}

      </CardContent>
    </Card>
  );

};

const styles = (theme) => ({
  fieldLabel: {
    marginTop: theme.spacing(1),
  },
  actionHeading: {
    height: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

BillingFeatures.propTypes = {
  accountId: PropTypes.number.isRequired,
  draftBilling: PropTypes.object.isRequired,
  elementTrials: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSaving: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
};

// Only re-render when these props change
const watchedProps = [
  'isSaving',
  'draftBilling.elements',
  'draftBilling.enabled_features',
  'draftBilling.element_trials',
  'enabledElements',
];

export default withStyles(styles)(memo(BillingFeatures, arePropsEqual(watchedProps)));
