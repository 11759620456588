import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import browserHistory from 'app/history';

import UserAvatar from 'components/UserAvatar';

const getSecondaryLabel = ({ accountUser }) => {
  let label = `${accountUser.user.email} · ${startCase(
    accountUser.role,
  )} · ${moment(accountUser.created_at).format('ll')}`;

  // Add their status
  let status;
  if (accountUser.is_invited === true) {
    status = 'Invited';
  } else if (accountUser.is_deactivated === true) {
    status = 'Deactivated';
  } else {
    status = 'Active';
  }
  label += ` · ${status}`;

  return label;
};

function AccountUserItem({ accountUser, billing }) {
  return (
    <div>
      <ListItem
        key={accountUser.id}
        onClick={() => browserHistory.push(`/user/${accountUser.user.id}`)}
        button
      >
        <ListItemAvatar>
          <UserAvatar user={accountUser.user} />
        </ListItemAvatar>
        <ListItemText
          primary={`${accountUser.user.first_name} ${accountUser.user.last_name}`}
          secondary={getSecondaryLabel({
            accountUser,
          })}
        />
      </ListItem>
    </div>
  );
}

AccountUserItem.propTypes = {
  accountUser: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired,
};

export default AccountUserItem;
