/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Card, CardContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Loading from 'components/Loading';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import Icon from '../../../../../../icon';

import AccessRequestButton from './AccessRequestButton';
import AccessRequestsList from './AccessRequestsList';

const GET_ACCESS_REQUESTS = gql`
  query GetAccessRequests($accountId: Int!) {
    accountAccessRequests(account_id: $accountId) {
      id
      account_user_id
      account_user {
        id
        expires_at
        user {
          id
          first_name
          last_name
          email
        }
      }
      approved_at
      approved_by_user_id
      approved_by_user {
        id
        first_name
        last_name
        email
      }
      created_at
      is_expired
      revoked_by_user_id
    }
  }
`;

const CREATE_ACCESS_REQUEST = gql`
  mutation CreateAccessRequest(
    $accountId: Int!
    $requestUserId: Int!
    $receiveUserId: Int!
  ) {
    createAccessRequest(
      account_id: $accountId
      request_user_id: $requestUserId
      receive_user_id: $receiveUserId
    ) {
      id
    }
  }
`;

const AccessRequests = ({
  account,
  currentUser,
  loadAccountUsers,
  classes,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [createRequestError, setCreateRequestError] = useState(null);

  const {
    data,
    error,
    loading,
    refetch,
  } = useQuery(GET_ACCESS_REQUESTS, {
    variables: {
      accountId: account.id,
    },
  });

  const [createAccessRequest] = useMutation(
    CREATE_ACCESS_REQUEST,
    {
      onError: setCreateRequestError,
      onCompleted: () => {
        refetch();
        setTimeout(() => setIsDialogOpen(false), 750);
      },
    },
  );

  const accessRequestsList = loading || error
    ? null
    : data.accountAccessRequests;

  const activeRequestsCount = (accessRequestsList || []).length;

  const handleCreateRequest = async (userId) => {
    await createAccessRequest({
      variables: {
        accountId: account.id,
        requestUserId: currentUser.id,
        receiveUserId: userId,
      },
    });
    await loadAccountUsers(account.id);
  };

  const handleToggleDialog = () => {
    setIsDialogOpen((state) => !state);
    setTimeout(() => setCreateRequestError(null), 500);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <div className={classes.header}>
            <CardTitle>
              <CardTitleIcon>
                <Icon value="plumpy plumpy-hCfHYg6AYTX0" />
              </CardTitleIcon>

              {`Access requests  (${activeRequestsCount})`}
            </CardTitle>

            <br />

            <AccessRequestButton
              account={account}
              currentUser={currentUser}
              isDialogOpen={isDialogOpen}
              activeRequests={accessRequestsList}
              onToggleDialog={handleToggleDialog}
              onCreateRequest={handleCreateRequest}
              createRequestError={createRequestError}
            />
          </div>

          {loading ? (
            <Loading />
          ) : error ? (
            <Alert severity="error">
              {`Error loading account access requests: ${getHelpfulErrorMessage(error)}`}
            </Alert>
          ) : accessRequestsList.length ? (
            <AccessRequestsList accessRequests={accessRequestsList} />
          ) : null}

        </CardContent>
      </Card>
    </div>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

AccessRequests.propTypes = {
  account: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  loadAccountUsers: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccessRequests);
