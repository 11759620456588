import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { gql, useMutation } from '@apollo/client';

import { APP_CONFIGURATION_FRAGMENT } from 'app/fragments';

const TOGGLE_APP = gql`
  mutation toggleAppConfiguration($id: Int!, $is_enabled: Boolean!, $account_id: Int!) {
    toggleAppConfiguration(id: $id, is_enabled: $is_enabled, account_id: $account_id) {
      ...AppConfiguration
    }
  }
  ${APP_CONFIGURATION_FRAGMENT}
`;

const ToggleIsAppEnabled = ({
  account,
  configuration,
  classes,
}) => {

  const [toggleApp, { loading: isSaving, error }] = useMutation(TOGGLE_APP, {
    variables: {
      id: configuration.id,
      account_id: account.id,
    },
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleToggleClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSaveClick = async () => {
    toggleApp({
      variables: {
        is_enabled: !configuration.is_enabled,
      },
      onCompleted: () => setIsDialogOpen(false),
    });
  };

  return (
    <div className={classes.wrapper}>

      <Button
        onClick={handleToggleClick}
        disabled={isSaving}
      >
        {configuration.is_enabled ? 'Disable' : 'Enable'}
      </Button>

      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={handleDialogClose}
      >
        <DialogTitle>
          {configuration.is_enabled
            ? `Disable ${configuration.app.title} on ${account.name}`
            : `Enable ${configuration.app.title} on ${account.name}`}
        </DialogTitle>
        <DialogContent>

          {error && (
          <Alert
            severity="error"
            className={classes.alert}
          >
            {error}
          </Alert>
          )}

          <Typography variant="subtitle1">
            {`This will ${configuration.is_enabled ? 'disable' : 'enable'} `}
            {`${configuration.app.title} on ${account.name} (${account.id}).`}
            {' '}
            🤖
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveClick}
            disabled={isSaving}
          >
            {configuration.is_enabled ? 'Disable' : 'Enable'}
          </Button>
        </DialogActions>
      </Dialog>

    </div>

  );
};
const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
  editTextBox: {
    paddingTop: theme.spacing(1.5),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
});

ToggleIsAppEnabled.propTypes = {
  account: PropTypes.object.isRequired,
  configuration: PropTypes.object.isRequired,
};

export default withStyles(styles)(ToggleIsAppEnabled);
