import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

// using context to pass down the search query prop, and keep hold of
// it even when the user navigates to another page, and the component re-renders
// passing in a default value
const ApplicationContext = createContext('');

export const ApplicationData = ({ children }) => {
  const [searchQuery, updateSearchQuery] = useState('');
  return (
    <ApplicationContext.Provider value={{ searchQuery, updateSearchQuery }}>
      {children}
    </ApplicationContext.Provider>
  );
};

ApplicationData.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ApplicationContext;
