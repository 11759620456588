import {
  map,
  replace,
  includes,
} from 'lodash';
import React, { memo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Divider,
  Grid,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { withStyles } from '@material-ui/core/styles';
import NumberInput from 'components/NumberInput';
import getElementName from '@beacon/utils/getElementName';
import sentenceCase from 'mout/string/sentenceCase';
import currencyFormat from 'mout/number/currencyFormat';

import arePropsEqual from 'utils/arePropsEqual';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';

import Expandomatic from 'components/Expandomatic';
import ChangeAllPrices from './ChangeAllPrices';
import getBillingEstimateTooltip from './getBillingEstimateTooltip';
import Icon from '../../../../../../icon';
import ExpandIcon from '../ExpandIcon';

const BillingPrices = ({
  draftBilling,
  newBillingEstimate, // passed if prices have changed
  oldBillingEstimate, // ditto
  billing,
  billingPlans,
  isSaving,
  dispatch,
  classes,
}) => {

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleElementPriceChange = (elementKey, priceKey) => (e) => {
    const newElementPrices = map(draftBilling.element_prices, (existingPrice) => {
      if (existingPrice.key !== elementKey) {
        return existingPrice;
      }
      return {
        ...existingPrice,
        [priceKey]: Number(e.target.value),
      };
    });

    dispatch({
      type: 'set',
      key: 'element_prices',
      value: newElementPrices,
    });
  };

  const handleElementHiddenToggle = (elementKey) => () => {
    const newElementPrices = map(draftBilling.element_prices, (existingPrice) => {
      if (existingPrice.key === elementKey) {
        return {
          ...existingPrice,
          hidden: !existingPrice.hidden,
        };
      }
      return existingPrice;
    });

    dispatch({
      type: 'set',
      key: 'element_prices',
      value: newElementPrices,
    });
  };

  const handleLimitIncreaseHiddenToggle = (limitIncreaseKey) => () => {
    const newLimitIncreasePrices = map(draftBilling.limit_increase_prices, (existingPrice) => {
      if (existingPrice.key === limitIncreaseKey) {
        return {
          ...existingPrice,
          hidden: !existingPrice.hidden,
        };
      }
      return existingPrice;
    });

    dispatch({
      type: 'set',
      key: 'limit_increase_prices',
      value: newLimitIncreasePrices,
    });
  };

  const handleLimitIncreasePriceChange = (limitKey, priceKey) => (e) => {
    const newLimitIncreasePrices = map(draftBilling.limit_increase_prices, (existingPrice) => {
      if (existingPrice.key !== limitKey) {
        return existingPrice;
      }
      return {
        ...existingPrice,
        [priceKey]: Number(e.target.value),
      };
    });

    dispatch({
      type: 'set',
      key: 'limit_increase_prices',
      value: newLimitIncreasePrices,
    });
  };

  return (
    <Card>
      <CardContent>

        <ExpandIcon
          onChange={handleExpandClick}
          isExpanded={isExpanded}
          label="prices"
        />

        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-3zh7UOATW4ER" />
          </CardTitleIcon>
          Prices
        </CardTitle>

        {isExpanded ? (
          <div>

            <br />
            <br />

            <ChangeAllPrices
              dispatch={dispatch}
              draftBilling={draftBilling}
              billing={billing}
              billingPlans={billingPlans}
            />

            <br />
            <br />

            <Divider />

            <br />

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body1" className={classes.fieldLabel}>
                  <strong>Core platform cost</strong>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={draftBilling.base_prices.rate_fee}
                  disabled={isSaving}
                  label="Rate fee (cost / multiplier)"
                  fullWidth
                  margin="normal"
                  onChange={(e) => dispatch({
                    type: 'set',
                    key: 'base_prices',
                    value: {
                      ...draftBilling.base_prices,
                      rate_fee: Number(e.target.value),
                    },
                  })}
                  error={draftBilling.base_prices.rate_fee <= 0}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    inputComponent: NumberInput,
                  }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={draftBilling.base_prices.flat_fee}
                  disabled={isSaving}
                  label="Flat fee"
                  fullWidth
                  margin="normal"
                  onChange={(e) => dispatch({
                    type: 'set',
                    key: 'base_prices',
                    value: {
                      ...draftBilling.base_prices,
                      flat_fee: Number(e.target.value),
                    },
                  })}
                  InputProps={{
                    inputComponent: NumberInput,
                  }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                  variant="outlined"
                />
              </Grid>

            </Grid>

            <br />
            <br />
            <br />

            <CardTitle>
              <CardTitleIcon>
                <Icon value="plumpy plumpy-3zh7UOATW4ER" />
              </CardTitleIcon>
              Element prices
            </CardTitle>
            <Divider />
            <br />

            {map(draftBilling.element_prices, (elementPrice) => (
              <Grid
                key={elementPrice.key}
                container
                spacing={2}
                className={classNames({
                  [classes.hiddenPrice]: elementPrice.hidden === true,
                })}
              >
                <Grid item xs={4}>
                  <Typography variant="body1" className={classes.fieldLabel}>
                    <strong>{getElementName(elementPrice.key)}</strong>
                    {!includes(draftBilling.elements, elementPrice.key) && (
                    <span>
                      <br />
                      <small>
                        <i>Not purchased</i>
                      </small>
                    </span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={7}>

                  <Grid container spacing={2}>

                    <Grid item xs={6}>
                      <TextField
                        value={elementPrice.rate_fee}
                        disabled={isSaving}
                        label="Rate fee (cost / multiplier)"
                        fullWidth
                        margin="normal"
                        onChange={handleElementPriceChange(elementPrice.key, 'rate_fee')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">£</InputAdornment>,
                          inputComponent: NumberInput,
                        }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          decimalScale: 2,
                          fixedDecimalScale: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        value={elementPrice.flat_fee}
                        disabled={isSaving}
                        label="Flat fee"
                        fullWidth
                        margin="normal"
                        onChange={handleElementPriceChange(elementPrice.key, 'flat_fee')}
                        InputProps={{
                          inputComponent: NumberInput,
                        }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          decimalScale: 2,
                          fixedDecimalScale: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>

                </Grid>

                {elementPrice.key === 'portal' && draftBilling.plan === 'standard' && (
                <Grid item xs={1}>
                  <Tooltip
                    title={elementPrice.hidden === true ? (
                      <span>
                        Element is hidden (cannot be purchased)
                      </span>
                    ) : (
                      <span>
                        Element can be purchased
                      </span>
                    )}
                    placement="top"
                  >
                    <IconButton
                      onClick={handleElementHiddenToggle(elementPrice.key)}
                      className={classes.hiddenToggleButton}
                    >
                      {elementPrice.hidden === true ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
                )}

              </Grid>
            ))}

            <br />
            <br />
            <br />

            <CardTitle>
              <CardTitleIcon>
                <Icon value="plumpy plumpy-3zh7UOATW4ER" />
              </CardTitleIcon>
              Limit increase prices
            </CardTitle>

            <Divider />
            <br />

            {map(draftBilling.limit_increase_prices, (limitIncreasePrice) => (
              <Grid
                key={limitIncreasePrice.key}
                container
                spacing={2}
                className={classNames({
                  [classes.hiddenPrice]: limitIncreasePrice.hidden === true,
                })}
              >
                <Grid item xs={4}>
                  <Typography variant="body1" className={classes.fieldLabel}>
                    <strong>{sentenceCase(replace(limitIncreasePrice.key, /_/g, ' '))}</strong>
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Grid container spacing={2}>

                    <Grid item xs={3}>
                      <TextField
                        value={limitIncreasePrice.unit_cost}
                        disabled={isSaving}
                        label="Cost / unit"
                        margin="normal"
                        fullWidth
                        onChange={handleLimitIncreasePriceChange(limitIncreasePrice.key, 'unit_cost')}
                        InputProps={{
                          inputComponent: NumberInput,
                          startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        value={limitIncreasePrice.step}
                        disabled={isSaving}
                        label="Step"
                        margin="normal"
                        fullWidth
                        onChange={handleLimitIncreasePriceChange(limitIncreasePrice.key, 'step')}
                        InputProps={{
                          inputComponent: NumberInput,
                        }}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        value={limitIncreasePrice.number_free}
                        label="Number free"
                        disabled // can't be changed - pulls from the `max_X` limit columns
                        margin="normal"
                        fullWidth
                    // onChange={handleLimitIncreasePriceChange(limitIncreasePrice.key, 'number_free')}
                        InputProps={{
                          inputComponent: NumberInput,
                        }}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        value={limitIncreasePrice.maximum}
                        disabled={isSaving}
                        label="Max limit"
                        margin="normal"
                        fullWidth
                        onChange={handleLimitIncreasePriceChange(limitIncreasePrice.key, 'maximum')}
                        InputProps={{
                          inputComponent: NumberInput,
                        }}
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>
                </Grid>

                {limitIncreasePrice.key === 'users' && (
                <Grid item xs={1}>
                  <Tooltip
                    title={limitIncreasePrice.hidden === true ? (
                      <span>
                        Limit increase is hidden (cannot be purchased)
                      </span>
                    ) : (
                      <span>
                        Limit increase can be purchased
                      </span>
                    )}
                    placement="top"
                  >
                    <IconButton
                      onClick={handleLimitIncreaseHiddenToggle(limitIncreasePrice.key)}
                      className={classes.hiddenToggleButton}
                    >
                      {limitIncreasePrice.hidden === true ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
                )}

              </Grid>
            ))}

            {newBillingEstimate && (
            <div>
              <br />
              <br />

              <CardTitle>
                <CardTitleIcon>
                  <VisibilityIcon />
                </CardTitleIcon>
                New price
              </CardTitle>
              <Divider />

              <br />

              <Alert severity="info">
                Based on the prices changes you've just made, and their current billing setup, here is what they will now be paying:
              </Alert>

              <br />

              <Typography variant="subtitle1">

                <Tooltip
                  placement="top"
                  title={getBillingEstimateTooltip(oldBillingEstimate)}
                >
                  <span>
                    £
                    {currencyFormat(oldBillingEstimate.total, 2)}
                    /month
                    {draftBilling.stripe_customer_tax_exempt === 'none' && ' + VAT'}
                  </span>
                </Tooltip>

                <ArrowForwardIcon className={classes.priceChangeArrow} />

                <Tooltip
                  placement="top"
                  title={getBillingEstimateTooltip(newBillingEstimate)}
                >
                  <strong>
                    £
                    {currencyFormat(newBillingEstimate.total, 2)}
                    /month
                    {draftBilling.stripe_customer_tax_exempt === 'none' && ' + VAT'}
                  </strong>
                </Tooltip>

              </Typography>

              <br />

            </div>
            )}

          </div>
        ) : (
          <Expandomatic onClick={() => setIsExpanded(true)}>
            Click to expand 👉👉
          </Expandomatic>
        )}

      </CardContent>
    </Card>
  );

};

const styles = (theme) => ({
  fieldLabel: {
    marginTop: theme.spacing(3),
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
  hiddenPrice: {
    opacity: 0.6,
  },
  hiddenToggleButton: {
    marginTop: theme.spacing(2),
  },
  priceChangeArrow: {
    display: 'inline',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
});

BillingPrices.propTypes = {
  billing: PropTypes.object.isRequired,
  draftBilling: PropTypes.object.isRequired,
  newBillingEstimate: PropTypes.object,
  oldBillingEstimate: PropTypes.object,
  billingPlans: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSaving: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Only re-render when these props change
const watchedProps = [
  'isSaving',
  'billing',
  'draftBilling.elements',
  'draftBilling.base_prices',
  'draftBilling.element_prices',
  'draftBilling.limit_increase_prices',
];

export default withStyles(styles)(memo(BillingPrices, arePropsEqual(watchedProps)));
