import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {
  CardContent,
  Card,
  Typography,
} from '@material-ui/core';

import browserHistory from 'app/history';
import ToggleAllAppsIsEnabled from '../App/ToggleAllAppsIsEnabled';

const AppListItem = ({
  app,
  classes,
}) => {
  const [disableHoverEffect, setDisableHoverEffect] = useState(false);

  return (
    <Card
      classes={{ root: disableHoverEffect ? classes.card : classes.cardHover }}
      onClick={() => browserHistory.push(`/app/${app.id}`)}
    >
      <CardContent>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <img
              src={app.icon}
              className={classes.icon}
              alt={app.title}
            />
            <div>
              <Typography variant="h6" className={classes.title}>{`${app.title}${app.is_enabled ? '' : ' (disabled)'}`}</Typography>
              {!app.is_coming_soon && app.is_visible && (
                <>
                  <Typography>
                    {'Installed on '}
                    <strong>{app.total_installs}</strong>
                    {` ${app.total_installs === 1 ? 'account' : 'accounts'}.`}
                  </Typography>
                  <Typography>
                    <em>
                      {app.total_installs_enabled === app.total_installs ? 'Enabled on all accounts.' : (
                        <>
                          {'But only enabled on '}
                          <strong>{app.total_installs_enabled}</strong>
                          {` ${app.total_installs_enabled === 1 ? 'account' : 'accounts'}.`}
                        </>
                      )}
                    </em>
                  </Typography>
                </>
              )}
            </div>
          </div>

          <div
            onMouseEnter={() => setDisableHoverEffect(true)}
            onMouseLeave={() => setDisableHoverEffect(false)}
          >
            <ToggleAllAppsIsEnabled
              app={app}
              buttonStyles={app.is_enabled ? {} : {
                color: 'primary',
                variant: 'contained',
              }}
              setDisableHoverEffect={setDisableHoverEffect}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

AppListItem.propTypes = {
  app: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  card: {
    marginBottom: 20,
    cursor: 'pointer',
  },
  cardHover: {
    marginBottom: 20,
    cursor: 'pointer',
    '&:hover': {
      background: '#f6f6f6',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 64,
    height: 'auto',
    marginRight: 30,
    marginTop: 6,
    maxWidth: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 0,
    paddingBottom: 0,
  },
  viewButton: {
    marginRight: theme.spacing(1),
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(AppListItem);
