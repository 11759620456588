import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { gql, useMutation, useQuery } from '@apollo/client';

import NumberInput from 'components/NumberInput';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Icon from '../../../../../icon';

const ADDRESS_LOOKUP_LICENCE_DETAILS = gql`
  query GetLicenceBillingDetails($account_id: Int!) {
    addressLookupLicence(account_id: $account_id) {
      free_allocation
    }
  }
`;

const UPDATE_ADDRESS_LOOKUP_FREE_ALLOCATION = gql`
  mutation ResetFreeLookupAllocation($accountId: Int!, $value: Int!) {
    resetAllocation(account_id: $accountId, value: $value) {
      free_allocation
    }
  }
`;

const AddressLookupLicence = ({
  account,
  notify,
  classes,
}) => {

  const {
    data = {},
    loading = true,
    error = undefined,
  } = useQuery(ADDRESS_LOOKUP_LICENCE_DETAILS, {
    variables: {
      account_id: account.id,
    },
  });

  const [resetAllocation] = useMutation(UPDATE_ADDRESS_LOOKUP_FREE_ALLOCATION);

  const [freeAllocation, setFreeAllocation] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const addressLookupLicence = loading || error
    ? null
    : data.addressLookupLicence;

  useEffect(() => {
    if (addressLookupLicence) {
      setFreeAllocation(get(addressLookupLicence, 'free_allocation', 0));
    }
  }, [addressLookupLicence]);

  const handleSetAllocation = ({ target }) => {
    const value = target.value === '' ? null : Number(target.value);

    setIsDisabled(value === null || value === freeAllocation);
    setFreeAllocation(value);
  };

  const handleUpdateAllocation = async () => {
    await resetAllocation({
      variables: {
        accountId: account.id,
        value: freeAllocation,
      },
    });

    notify({
      message: 'Free allocation updated',
    });

    setIsDisabled(true);
  };

  return (
    <Card>
      <CardContent>
        <div className={classes.row}>
          <CardTitle>
            <CardTitleIcon>
              <Icon value="plumpy plumpy-ijINnZ1Zwhd0" />
            </CardTitleIcon>

            Address autocomplete
          </CardTitle>
        </div>

        <div className={classes.row}>
          <div className={classes.row}>
            <Typography variant="body1" className={classes.key}>
              <strong>Free allocation: </strong>
            </Typography>

            <TextField
              value={freeAllocation}
              onChange={handleSetAllocation}
              variant="outlined"
              InputProps={{
                inputComponent: NumberInput,
              }}
            />
          </div>

          <Button
            onClick={handleUpdateAllocation}
            disabled={isDisabled}
          >
            Update
          </Button>
        </div>

      </CardContent>
    </Card>
  );

};

const styles = (theme) => ({
  key: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
});

AddressLookupLicence.propTypes = {
  account: PropTypes.object.isRequired,
  notify: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddressLookupLicence);
