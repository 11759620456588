// Handy util for checking whether components should re-render
// to be used within `React.memo`.
// General purpose util to watch specific component prop
// keys for changes.

import {
  every,
  isEqual,
  get,
} from 'lodash';

export default (watchedKeys) => (prevProps, nextProps) => (
  every(watchedKeys, (key) => (
    isEqual(get(prevProps, key), get(nextProps, key))
  ))
);
