import { get, isInteger, map } from 'lodash';
import React from 'react';
import moment from 'moment';
import { gql, useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import {
  Card,
  CardActions,
  CardContent,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';

import Loading from 'components/Loading';

const GET_ACCESS_REQUEST_LOGS = gql` 
  query GetAccessRequestLogs ($dateStart: DateTime, $dateEnd: DateTime, $offset: Int, $limit: Int) {
    accessRequests (dateStart: $dateStart, dateEnd: $dateEnd, offset: $offset, limit: $limit) {
      id
      created_at
      updated_at
      is_expired
      account_id
      account {
        name
      }
      account_user_id
      account_user {
        user {
          first_name
          last_name
          email
        }
      }
      approved_by_user_id
      approved_by_user {
        first_name
        last_name
        email
      }
      approved_at
      revoked_by_user_id
      revoked_by_user {
        first_name
        last_name
        email
      }
      revoked_at
    }
    accessRequestsCount (dateStart: $dateStart, dateEnd: $dateEnd)
  }
`;

const AccountList = ({
  classes,
}) => {

  const limit = 30;

  const minDate = moment('2024-01-01').format();
  const maxDate = moment().format();

  const [range, setRange] = React.useState({
    dateStart: minDate,
    dateEnd: maxDate,
  });

  const {
    loading,
    data: results,
    fetchMore,
  } = useQuery(GET_ACCESS_REQUEST_LOGS, {
    variables: {
      limit,
      offset: 0,
      ...range,
    },
  });

  const currentCount = get(results, 'accessRequestsCount', undefined);

  return (
    <div>
      <Typography variant="h6">
        Access requests
        {!loading && isInteger(currentCount) && ` (${currentCount})`}
      </Typography>

      <Card>

        <CardContent className={classes.tableWrapper}>
          <CardActions>
            <DatePicker
              label="DATE START"
              value={range.dateStart}
              format="Do MMMM, YYYY"
              onChange={(value) => {
                setRange((state) => ({
                  ...state,
                  dateStart: value.format(),
                }));
              }}
              minDate={minDate}
              maxDate={maxDate}
            />

            <Icon value="plumpy plumpy-hCfHYg6AYTX0" />

            <DatePicker
              label="DATE END"
              value={range.dateEnd}
              format="Do MMMM, YYYY"
              onChange={(value) => {
                setRange((state) => ({
                  ...state,
                  dateEnd: value.format(),
                }));
              }}
              minDate={minDate}
              maxDate={maxDate}
            />
          </CardActions>

          <br />

          {loading ? (
            <Loading />
          ) : (
            <div>
              <InfiniteScroll
                loadMore={() => {
                  fetchMore({
                    variables: {
                      limit,
                      offset: get(results, 'accessRequests.length'),
                      ...range,
                    },
                  });
                }}
                hasMore={get(results, 'accessRequests.length') < currentCount}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Account name</TableCell>
                      <TableCell>Created at</TableCell>
                      <TableCell>Created for user</TableCell>
                      <TableCell>Approved at</TableCell>
                      <TableCell>Approved by user</TableCell>
                      <TableCell>Revoked at</TableCell>
                      <TableCell>Revoked by user</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(results?.accessRequests || [], ({
                      id,
                      account,
                      account_user,
                      created_at,
                      approved_at,
                      approved_by_user,
                      revoked_at,
                      revoked_by_user,
                    }) => (
                      <TableRow
                        key={id}
                        className={classes.tableRow}
                      >
                        <TableCell>{id}</TableCell>
                        <TableCell>{account.name}</TableCell>
                        <TableCell>{created_at ? moment(created_at).format('lll') : '-'}</TableCell>
                        <TableCell>{account_user.user.email}</TableCell>
                        <TableCell>{approved_at ? moment(approved_at).format('lll') : '-'}</TableCell>
                        <TableCell>{approved_by_user?.email}</TableCell>
                        <TableCell>{revoked_at ? moment(revoked_at).format('lll') : '-'}</TableCell>
                        <TableCell>{revoked_by_user?.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const styles = (theme) => ({
  tableWrapper: {
    overflow: 'auto',
  },
  tableRow: {
    '&:hover': {
      background: theme.palette.grey[200],
    },
    height: 60,
  },
});

export default withStyles(styles)(AccountList);
