import {
  get, isInteger, map, trim,
} from 'lodash';
import React, { useContext } from 'react';
import moment from 'moment';

import InfiniteScroll from 'react-infinite-scroller';
import { withStyles } from '@material-ui/core/styles';

import {
  TextField, TableRow,
  TableHead, TableCell, TableBody, Table, CardContent,
  Card, Typography,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';

import browserHistory from 'app/history';
import Loading from 'components/Loading';
import { ACCOUNT_FRAGMENT } from 'app/fragments';
import ApplicationContext from '../../../ApplicationContext';


const GET_ACCOUNTS_WITH_BILLING_MRR = gql` 
  query GetAccountsWithBillingMRR ($query: String, $offset: Int, $limit: Int) {
    accounts (query: $query, offset: $offset, limit: $limit) {
      ...Account
    }
    total: accountsTotal (query: $query)
  }
  ${ACCOUNT_FRAGMENT}
`;

const AccountList = ({
  classes,
}) => {


  const { searchQuery, updateSearchQuery } = useContext(ApplicationContext);
  const hasQueryResults = searchQuery.length > 2;


  const {
    loading,
    data: results,
    refetch,
    fetchMore,
  } = useQuery(GET_ACCOUNTS_WITH_BILLING_MRR, {
    variables: {
      query: hasQueryResults ? searchQuery : '',
      offset: 0,
      limit: 100,
    },
  });

  const handleRowClick = accountId => () => {
    browserHistory.push(`/account/${accountId}`);
  };

  const handleSearchQueryChange = async (e) => {
    updateSearchQuery(e.target.value);
    await refetch({
      offset: 0,
      query: e.target.value.length > 2 ? e.target.value : '',
    });
  };

  return (
    <div>
      <Typography variant="h6">
        {'Accounts'}
        {!loading && isInteger(results?.total) && `(${results.total})`}
      </Typography>

      <Card>
        <CardContent className={classes.accountWrapper}>

          <TextField
            variant="outlined"
            className={classes.searchBar}
            fullWidth
            placeholder="Search accounts"
            value={searchQuery}
            onChange={handleSearchQueryChange}
            autoFocus
          />

          <br />
          <br />

          {loading ? (
            <Loading />
          ) : (
            <div>

              <InfiniteScroll
                loadMore={() => {
                  fetchMore({
                    variables: {
                      offset: get(results, 'accounts.length'),
                      searchQuery,
                    },
                  });
                }}
                hasMore={get(results, 'accounts.length') < results?.total && !trim(searchQuery)}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Slug</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Timezone</TableCell>
                      <TableCell>Created</TableCell>
                      {<TableCell>MRR</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(results?.accounts || [], account => (
                      <TableRow
                        key={account.id}
                        className={classes.tableRow}
                        onClick={handleRowClick(account.id)}
                      >
                        <TableCell>{account.id}</TableCell>
                        <TableCell>{account.name}</TableCell>
                        <TableCell>{account.slug}</TableCell>
                        <TableCell>{account.country_code}</TableCell>
                        <TableCell>{account.timezone}</TableCell>
                        <TableCell>{moment(account.created_at).format('ll')}</TableCell>
                        <TableCell>{account.billing ? account.billing.mrr : null }</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

              </InfiniteScroll>
            </div>
          )}

        </CardContent>
      </Card>
    </div>

  );
};


const styles = theme => ({
  accountWrapper: {
    overflow: 'auto',
  },
  searchBar: {
    paddingBottom: theme.spacing(0.5),
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    height: 60,
  },
  tableCellHeader: {
    borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[1],
  },
  accountTitle: {
    padding: 10,
  },
});

export default withStyles(styles)(AccountList);
