import React, { Component } from 'react';
import PropTypes from 'prop-types';
import typecast from 'mout/string/typecast';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import Alert from 'components/Alert';

export const getFriendlyLockReason = (reason) => {
  const friendlyReasons = {
    security: 'Security',
    competitor: 'Competitor',
    unpaid_invoice: 'Unpaid invoice',
  };
  return friendlyReasons[reason] || 'Other';
};

class ToggleLock extends Component {

  constructor() {
    super();

    this.state = {
      isSaving: false,
      isDialogOpen: false,
      lockReason: 'security',
      lockError: null,
    };

    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleLockReasonChange = this.handleLockReasonChange.bind(this);
    this.handleLockClick = this.handleLockClick.bind(this);
  }

  getLockReasonHelpText() {
    const { lockReason } = this.state;

    if (lockReason === 'security') {
      return 'Not a genuine potential customer - e.g account being used to test out credit cards';
    }
    if (lockReason === 'competitor') {
      return 'Trying to find out what goes into our secret sauce';
    }
    if (lockReason === 'unpaid_invoice') {
      return 'Haven\'t paid an invoice';
    }

    return 'General purpose locking';
  }

  handleToggleClick() {
    const {
      account,
      updateAccount,
    } = this.props;

    if (account.is_locked === true) {

      this.setState({
        isSaving: true,
      });

      updateAccount(account.id, {
        is_locked: false,
        locked_reason: null,
      })

        .then(() => {
          this.setState({
            isSaving: false,
            isDialogOpen: false,
          });
        });

    } else {

      this.setState({
        isDialogOpen: true,
      });

    }
  }

  handleLockReasonChange(e) {
    this.setState({
      lockReason: typecast(e.target.value),
    });
  }

  handleLockClick() {
    const {
      updateAccount,
      account,
    } = this.props;
    const {
      lockReason,
    } = this.state;

    this.setState({
      isSaving: true,
    });

    updateAccount(account.id, {
      is_locked: true,
      locked_reason: lockReason,
    })

      .then(() => {
        this.setState({
          isSaving: false,
          isDialogOpen: false,
        });
      })

      .catch((err) => {
        this.setState({
          lockError: err.message,
        });
      });

  }

  handleDialogClose() {
    this.setState({
      isDialogOpen: false,
    });
  }

  render() {
    const {
      account,
      classes,
    } = this.props;
    const {
      isDialogOpen,
      isSaving,
      lockReason,
      lockError,
    } = this.state;

    return (
      <div className={classes.wrapper}>

        <Button
          onClick={this.handleToggleClick}
          disabled={isSaving}
        >
          {account.is_locked && isSaving && 'Unlocking...'}
          {account.is_locked && !isSaving && 'Unlock'}
          {!account.is_locked && 'Lock'}
        </Button>

        <Dialog
          open={isDialogOpen}
          fullWidth
          onClose={this.handleDialogClose}
        >
          <DialogTitle>
            Lock account
          </DialogTitle>
          <DialogContent>

            {lockError && (
              <Alert
                type="danger"
                message={lockError}
              />
            )}

            <Typography variant="subtitle1">
              Why would you like to lock this account?
            </Typography>

            <TextField
              onChange={this.handleLockReasonChange}
              select
              disabled={isSaving}
              variant="outlined"
              fullWidth
              margin="normal"
              value={String(lockReason)}
              helperText={this.getLockReasonHelpText()}
            >
              <MenuItem value="security">
                {getFriendlyLockReason('security')}
              </MenuItem>
              <MenuItem value="competitor">
                {getFriendlyLockReason('competitor')}
              </MenuItem>
              <MenuItem value="unpaid_invoice">
                {getFriendlyLockReason('unpaid_invoice')}
              </MenuItem>
              <MenuItem value="null">
                Other
              </MenuItem>
            </TextField>

          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleLockClick}
              disabled={isSaving}
            >
              {isSaving ? 'Locking...' : 'Lock'}
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }

}

const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
});

ToggleLock.propTypes = {
  account: PropTypes.object.isRequired,
  updateAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(ToggleLock);
