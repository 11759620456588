import {
  get,
  isEmpty,
  map,
  sortBy,
} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {
  CardContent,
  Card,
  Typography,
} from '@material-ui/core';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import { gql, useQuery } from '@apollo/client';

import { APP_FRAGMENT, APP_CONFIGURATION_FRAGMENT } from 'app/fragments';
import Icon from '../../../../../../icon';
import AppCard from './AppCard';

const GET_APP_CONFIGURATIONS_BY_APP = gql` 
  query GetAppConfigurationsByApp ($account_id: Int) {
    appConfigurations (account_id: $account_id) {
      ...AppConfiguration
      app {
      ...App
    }
    }
    
  }
  ${APP_CONFIGURATION_FRAGMENT}
  ${APP_FRAGMENT}
`;

const Apps = ({
  account,
}) => {

  const {
    loading,
    data: results,
  } = useQuery(GET_APP_CONFIGURATIONS_BY_APP, {
    variables: {
      account_id: account.id,
    },
  });
  const configurations = get(results, 'appConfigurations', []);
  // -new Date(app.created_at).getTime() will return a timestamp for each date from
  // oldest to newest. The - is used to sort the apps by the oldest created app first.
  const sortedConfigurations = sortBy(configurations, ({ app }) => -new Date(app.created_at).getTime());

  return (
    <Card>
      <CardContent>

        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-XuLLfXB9jZ9R" />
          </CardTitleIcon>
          Apps
          {' '}
          {!loading && ` (${sortedConfigurations.length})`}
        </CardTitle>

        <br />

        {isEmpty(get(results, 'appConfigurations', [])) ? (
          <Typography>
            {account.name}
            {' '}
            has no apps installed.
          </Typography>
        ) : (
          <>
            {map(sortedConfigurations, (configuration) => (
              <AppCard key={configuration.id} configuration={configuration} />
            ))}
          </>
        )}
      </CardContent>
    </Card>
  );
};

Apps.propTypes = {
  account: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  appTitleCell: {
    display: 'flex',
    alignItems: 'center',
  },
  appIcon: {
    float: 'left',
    marginRight: 15,
    width: 32,
    height: 32,
  },
  disableButton: {
    float: 'right',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    height: 60,
  },
});

export default withStyles(styles)(Apps);
