import React from 'react';
import { replace, keys } from 'lodash';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import env from 'config/env';
import colorSet from 'utils/color-filters';

import GetIconQuery from './__queries__/get-icon';

const BASE_URL = `https://beacon-icons-${env || 'development'}.s3.eu-west-2.amazonaws.com`;

function Icon({
  alt,
  className,
  color,
  set,
  value,
  width,
}) {
  const iconId = replace(value, /(plumpy|-|\s)/g, '');
  const [getImage] = useLazyQuery(GetIconQuery);

  // If the icon isn't on s3, fetch it from icons8 and cache it on s3
  const onError = async ({ currentTarget }) => {
    const { data } = await getImage({
      variables: {
        iconId,
      },
    });
    currentTarget.src = data.icon.url; // eslint-disable-line no-param-reassign
  };

  return (
    value ? (
      <img
        alt={alt}
        className={className}
        onError={onError}
        style={{ filter: colorSet[color], width }}
        src={`${BASE_URL}/${set}/${iconId}.svg`}
      />
    ) : null
  );
}

Icon.defaultProps = {
  alt: '',
  color: '000000',
  set: 'plumpy',
  width: '1.5rem',
};

Icon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(keys(colorSet)),
  set: PropTypes.oneOf(['plumpy']),
  value: PropTypes.string,
  width: PropTypes.string,
};

export default Icon;
