import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions,
  DialogContent, Tooltip, Typography,
} from '@material-ui/core';
import copy from 'clipboard-copy';
import { gql, useMutation } from '@apollo/client';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { Alert } from '@material-ui/lab';
import { toInteger } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { API_KEYS_FRAGMENT } from 'app/fragments';

const CREATE_API_KEY = gql`
  mutation CreateAPIKey( $account_id: Int! ) {
    createAPIKey(account_id: $account_id){
        ...ApiKey
    }
  }
  ${API_KEYS_FRAGMENT}
`;

const ApiKeyCreator = ({
  accountId,
  classes,
  apiKeyListRefetch,
  apiKeyCreatorSearchRefetch,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [newKey, setNewKey] = useState();

  const [createAPIKey, {
    error,
    loading: isCreating,
  }] = useMutation(CREATE_API_KEY);

  const handleCreateAPIKey = async (id) => {

    // change the account id back into a integer (graphQL search query funkiness
    // has made it a string)
    const integerAccountId = toInteger(id);

    const newApiKeyData = await createAPIKey({
      variables: {
        account_id: integerAccountId,
      },
    });

    setNewKey(newApiKeyData.data.createAPIKey.key);
    setIsDialogOpen(true);
  };

  const onClose = () => {
    apiKeyCreatorSearchRefetch();
    if (apiKeyListRefetch) {
      apiKeyListRefetch();
    }

    setIsDialogOpen(false);
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          color={apiKeyListRefetch && 'primary'}
          onClick={() => handleCreateAPIKey(accountId)}
          disabled={isCreating}
        >
          {isCreating ? 'Creating a API key' : 'Create a API key'}
        </Button>
      </div>
      <div>
        <Dialog
          open={isDialogOpen}
          fullWidth
          onClose={onClose}
        >
          <DialogContent>
            <Typography className={classes.NewKeyNumber}>
              {newKey}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              Close
            </Button>
            <Tooltip
              title={isCopied ? 'Copied' : 'Copy API key to clipboard'}
              placement="top"
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  copy(newKey);
                  setIsCopied(true);
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 2000);
                  onClose();
                }}
              >
                Copy
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        {error && (
        <Alert severity="error">
          {getHelpfulErrorMessage(error)}
        </Alert>
        )}
      </div>
    </>
  );
};

const styles = {
  NewKeyNumber: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

ApiKeyCreator.propTypes = () => ({
  accountId: PropTypes.number.isRequired,
  apiKeyListRefetch: PropTypes.function,
  apiKeyCreatorSearchRefetch: PropTypes.function.isRequired,
});

export default withStyles(styles)(ApiKeyCreator);
