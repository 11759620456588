import {
  sortBy,
  map,
} from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { loadUsersList } from 'actions/user';
import { getStateByKey } from 'reducers';
import UserList from './component';

const selector = createSelector([
  getStateByKey('user.byId'),
  getStateByKey('user.total'),
  getStateByKey('user.listIds'),
  getStateByKey('user.isListLoading'),
  getStateByKey('user.isListLoadingMore'),
], (usersById, totalUsers, userListIds, isListLoading, isListLoadingMore) => ({

  isLoading: isListLoading,
  isLoadingMore: isListLoadingMore,

  users: sortBy(map(userListIds, (userId) => usersById[userId]), (user) => (
    -(new Date(user.created_at)).getTime()
  )),

  totalUsers,
}));

const mapDispatchToProps = {
  loadUsersList,
};

export default connect(
  selector,
  mapDispatchToProps,
)(UserList);
