import React from 'react';
import PropTypes from 'prop-types';

import FontIcon from '@material-ui/core/Icon';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import { Tooltip } from '@material-ui/core';

const green500 = green['500'];
const red500 = red['500'];

const HealthStatusIcon = ({
  configuration,
  style,
}) => {

  const newStyle = {
    display: 'inline-block',
    verticalAlign: 'middle',
    ...style,
  };

  const isConfigurationHealthy = configuration.health_status === 'healthy';

  if (isConfigurationHealthy) {
    return (
      <FontIcon
        className="zmdi zmdi-check-circle"
        aria-label="health status healthy"
        style={{
          color: green500,
          ...newStyle,
        }}
      />
    );
  }

  return (
    <Tooltip
      title={configuration.health_description}
      placement="top"
    >
      <FontIcon
        aria-label="health status failing"
        className="zmdi zmdi-alert-circle"
        style={{
          color: red500,
          ...newStyle,
        }}
      />
    </Tooltip>
  );
};

HealthStatusIcon.propTypes = {
  configuration: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default HealthStatusIcon;
