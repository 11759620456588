import {
  map,
  get,
  trim,
} from 'lodash';
import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { gql, useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import {
  TextField, TableRow,
  TableHead, TableCell, TableBody, Table, CardContent,
  Card, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import Loading from 'components/Loading';
import browserHistory from 'app/history';
import ApplicationContext from '../../../ApplicationContext';

const GET_PORTAL_FORM_SUBMISSIONS = gql`
  query GetPortalFormSubmissions($query: String, $offset: Int, $limit: Int) {
    portalFormSubmissions(query: $query, offset: $offset, limit: $limit) {
      id
      account_id
      status
      created_at
      account {
        name
      }
      portal_form {
        id
        type
        slug
        title
      }
    }
    total: portalFormSubmissionsTotal(query: $query)
  }
`;

const PortalFormSubmissionsList = ({ classes }) => {

  const { searchQuery, updateSearchQuery } = useContext(ApplicationContext);

  // only show a query result when they have typed in the entire account id number
  const hasQueryResults = searchQuery.length >= 3;

  const {
    loading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(GET_PORTAL_FORM_SUBMISSIONS, {
    variables: {
      query: hasQueryResults ? searchQuery : '',
      offset: 0,
      limit: 100,
    },
  });

  const handleSearchQueryChange = async (e) => {
    const { value } = e.target;

    updateSearchQuery(value);
    await refetch({
      offset: 0,
      query: value.length >= 3 ? value : '',
    });
  };

  const handleRowClick = (id) => () => {
    browserHistory.push(`/formsubmission/${id}`);
  };

  return (
    <div>

      <Typography variant="h6">
        Form submissions
      </Typography>

      <Card>
        <CardContent className={classes.portalFormSubmissionsWrapper}>
          <TextField
            variant="outlined"
            className={classes.searchBar}
            fullWidth
            placeholder="Search form submissions"
            value={searchQuery}
            onChange={handleSearchQueryChange}
            autoFocus
          />
          <br />
          <br />

          {loading ? (
            <Loading />
          ) : (
            <div>

              <InfiniteScroll
                loadMore={() => {
                  fetchMore({
                    variables: {
                      offset: get(data, 'portalFormSubmissions.length'),
                      searchQuery,
                    },
                  });
                }}
                hasMore={get(data, 'portalFormSubmissions.length') < data?.total && !trim(searchQuery)}
              >

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Submission ID</TableCell>
                      <TableCell>Account ID</TableCell>
                      <TableCell>Account name</TableCell>
                      <TableCell>Form name</TableCell>
                      <TableCell>Form slug</TableCell>
                      <TableCell>Form type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(data?.portalFormSubmissions || [], (submission) => (
                      <TableRow
                        key={submission.id}
                        className={classes.tableRow}
                        onClick={handleRowClick(submission.id)}
                      >
                        <TableCell>{submission.id}</TableCell>
                        <TableCell>{submission.account_id}</TableCell>
                        <TableCell>{submission.account?.name}</TableCell>
                        <TableCell>{submission.portal_form?.title}</TableCell>
                        <TableCell>{submission.portal_form?.slug}</TableCell>
                        <TableCell>{submission.portal_form?.type}</TableCell>
                        <TableCell>{submission.status}</TableCell>
                        <TableCell>{moment(submission.created_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

              </InfiniteScroll>
            </div>
          )}
        </CardContent>

        {error && (
        <div>
          <Alert severity="error">
            {getHelpfulErrorMessage(error)}
          </Alert>
          <br />
        </div>
        )}
      </Card>

    </div>
  );

};

const styles = (theme) => ({
  portalFormSubmissionsWrapper: {
    overflow: 'auto',
  },
  searchBar: {
    paddingBottom: theme.spacing(0.5),
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    height: 60,
  },
});

export default withStyles(styles)(PortalFormSubmissionsList);
