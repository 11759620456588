import {
  get,
  isInteger,
  map,
  toLower,
} from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import currencyFormat from 'mout/number/currencyFormat';
import properCase from 'mout/string/properCase';
import { Link as RouterLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import isBillingActive from '@beacon/utils/isBillingActive';
// import isBillingConfigured from '@beacon/utils/isBillingConfigured';
import getElementName from '@beacon/utils/getElementName';

import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Alert from 'components/Alert';

import browserHistory from 'app/history';

import copy from 'clipboard-copy';
import { host as dashboardHost } from 'config/dashboard';
import { gql, useQuery } from '@apollo/client';
import { APP_FRAGMENT, APP_CONFIGURATION_FRAGMENT } from 'app/fragments';

import AccountUsers from './AccountUsers';
import Files from './Files';
import ToggleLock, { getFriendlyLockReason } from './ToggleLock';
import FreeTrialExpiresAt from './FreeTrialExpiresAt';
import ToggleBillingExempt from './ToggleBillingExempt';
import ServicesInvoice from './ServicesInvoice';
import ToggleEditSlug from './ToggleEditSlug';
import Icon from '../../../../../icon';
import ApiKeysSection from './ApiKeysSection';
import AccessRequests from './AccessRequests';
import TogglePartnerReferral from './TogglePartnerReferral';
import AccountConfigButton from './AccountConfigButton';
import CopyAccountButton from './CopyAccountButton';
import AddressLookups from './AddressLookups';
import Apps from './Apps';

const GET_APP_CONFIGURATIONS_BY_APP = gql` 
  query GetAppConfigurationsByApp ($account_id: Int) {
    appConfigurations (account_id: $account_id) {
      ...AppConfiguration
      app {
      ...App
      }
    }
    customEntityFieldsTotal (account_id: $account_id)
  }
  ${APP_CONFIGURATION_FRAGMENT}
  ${APP_FRAGMENT}
`;

const Account = ({
  notify,
  account,
  accountUsers,
  billing,
  updateAccount,
  classes,
  isLoadingBillingUsage,
  billingUsage,
  currentUser,
  currentUserAccountUser,
  loadAccountBillingUsage,
  loadAccountUsers,
  numberOfFullUsers,
}) => {

  const { data = {} } = useQuery(GET_APP_CONFIGURATIONS_BY_APP, {
    variables: {
      account_id: account.id,
    },
  });

  const [isCopied, setIsCopied] = useState(false);

  const handleClick = () => {
    setIsCopied(true);
    copy(`${dashboardHost}/account/${account.id}/settings/billing`);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const currentUserHasActiveAccountUser = (
    currentUserAccountUser
    && !get(currentUserAccountUser, 'is_deactivated')
    && !get(currentUserAccountUser, 'is_expired')
  );

  const redirectToDashboard = () => window.open(`${dashboardHost}/account/${account.id}/home`);

  useEffect(() => {
    loadAccountBillingUsage(account.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id]);

  return (

    <div>

      <div className={classes.header}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/accounts">
            <Typography variant="h6">
              Accounts
            </Typography>
          </Link>
          <Typography variant="h6">{account.name}</Typography>
        </Breadcrumbs>
        <div className={classes.buttonContainer}>
          <CopyAccountButton account={account} />
          <AccountConfigButton account={account} />
          <div>
            {currentUserHasActiveAccountUser ? (
              <Button
                onClick={redirectToDashboard}
                color="primary"
                variant="contained"
              >
                <Icon
                  value="bvkMUSBRi9QO"
                  color="FFFFFF"
                  alt="Copy account icon"
                  className={classes.buttonIcon}
                />
                {` Go to ${account.name}`}
              </Button>
            ) : (
              <Tooltip
                title="You need to have an active account user to view this account. You can make an access request below. You can thank Haydn for that feature!!!"
                placement="top"
              >
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled
                  >
                    <Icon
                      value="bvkMUSBRi9QO"
                      color="FFFFFF"
                      alt="Copy account icon"
                      className={classes.buttonIcon}
                    />
                    {` Go to ${account.name}`}
                  </Button>
                </div>
              </Tooltip>
            )}

          </div>
        </div>
      </div>

      <br />

      <Card>
        <CardContent>

          <CardTitle>
            <CardTitleIcon>
              <Icon value="plumpy plumpy-ZQcnHO3UJQZy" />
            </CardTitleIcon>
            Overview
          </CardTitle>

          <br />

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Name</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {account.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Created</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {moment(account.created_at).format('ll')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Slug</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={classes.inline}>
                {account.slug}
              </Typography>
              <div className={classes.inline}>
                <ToggleEditSlug
                  account={account}
                  updateAccount={updateAccount}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Country</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {account.country_code}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Timezone</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {account.timezone}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Gift Aid enabled?</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {get(account, 'tax_reclaim_settings.is_enabled') === true ? 'Yes' : 'No' }
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Is locked?</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={classes.inline}>
                {account.is_locked ? 'Yes' : 'No'}
                {account.is_locked && ` (reason: ${toLower(getFriendlyLockReason(account.locked_reason))})`}
              </Typography>
              <div className={classes.inline}>
                <ToggleLock
                  account={account}
                  updateAccount={updateAccount}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Free Trial expiry date</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <div className={classes.inline}>
                <FreeTrialExpiresAt
                  account={account}
                  updateAccount={updateAccount}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Is billing exempt?</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={classes.inline}>
                {account.is_billing_exempt ? 'Yes' : 'No'}
              </Typography>
              <div className={classes.inline}>
                <ToggleBillingExempt
                  account={account}
                  updateAccount={updateAccount}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Is partner referral?</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={classes.inline}>
                {account.is_partner_referral ? 'Yes' : 'No'}
              </Typography>
              <div className={classes.inline}>
                <TogglePartnerReferral
                  account={account}
                  isBillingActive={isBillingActive(billing)}
                  updateAccount={updateAccount}
                />
              </div>
            </Grid>
          </Grid>

        </CardContent>
      </Card>

      <AccessRequests
        account={account}
        currentUser={currentUser}
        loadAccountUsers={loadAccountUsers}
      />

      <Card>
        <CardContent>

          <CardTitle>
            <CardTitleIcon>
              <Icon value="plumpy plumpy-vkDKKBC6Xb7o" />
            </CardTitleIcon>
            Billing
          </CardTitle>

          <br />

          {billing && isInteger(billing.id) ? (
            <div>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>MRR</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    £
                    {currencyFormat(billing.mrr, 2)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Plan</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {properCase(billing.plan)}
                    {' '}
                    <span>
                      (version:
                      {' '}
                      {billing.version}
                      )
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Contacts</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {currencyFormat(billing.contacts, 0)}
                    {' '}

                    {billing.contacts_override && (
                    <span className={classes.contactsOverride}>
                      (
                      {currencyFormat(billing.contacts_override, 0)}
                      {' override)'}
                    </span>
                    )}

                    {!isLoadingBillingUsage && (
                    <span>
                      {`(${billingUsage.contacts} used)`}
                    </span>
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Billing frequency</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {'Every '}
                    {billing.technology_plan_frequency}
                    {' '}
                    {billing.technology_plan_frequency === 1 ? 'month' : 'months'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Elements</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {map(billing.elements, (elementKey) => (
                      getElementName(elementKey)
                    )).join(', ')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Custom Fields</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <span>
                      {`${data.customEntityFieldsTotal} used`}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    <strong>Users</strong>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    <span>
                      {`${numberOfFullUsers} used`}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

            </div>
          ) : (
            <Alert
              type="info"
              message="Billing hasn't been set up for this account."
            />
          )}

          <br />
          <br />

          <Button
            onClick={() => browserHistory.push(`/account/${account.id}/billing`)}
            className={classes.buttonBilling}
          >
            Edit billing
          </Button>

          <Button
            onClick={handleClick}
            className={classes.buttonBilling}
          >
            {isCopied ? 'Copied' : 'Copy billing settings link'}
          </Button>

          {isBillingActive(billing) && (
            <ServicesInvoice billing={billing} />
          )}

        </CardContent>
      </Card>

      <AccountUsers
        accountUsers={accountUsers}
        billing={billing}
      />

      <Apps
        account={account}
      />

      <Files
        account={account}
      />

      <ApiKeysSection
        account={account}
      />

      {billing && isInteger(billing.id) ? null : (
        <AddressLookups
          account={account}
          notify={notify}
        />
      )}

    </div>
  );
};

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  copyButton: {
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  inline: {
    display: 'inline',
    marginRight: theme.spacing(1),
  },
  userTypeLabel: {
    display: 'block',
  },
  contactsOverride: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
  buttonBilling: {
    margin: 10,
  },
});

Account.propTypes = {
  notify: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  accountUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateAccount: PropTypes.func.isRequired,
  billing: PropTypes.object.isRequired,
  billingUsage: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  currentUserAccountUser: PropTypes.object.isRequired,
  isLoadingBillingUsage: PropTypes.bool.isRequired,
  loadAccountBillingUsage: PropTypes.func.isRequired,
  loadAccountUsers: PropTypes.func.isRequired,
  numberOfFullUsers: PropTypes.number.isRequired,
};

export default withStyles(styles)(Account);
