import { includes } from 'lodash';
import React from 'react';
import {
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';

import Icon from '../../../../../../icon';

const GET_ENTITY_LIST_VIEWS_TOTAL = gql`
  query entityListViewsTotal($entity_type_id: Int, $account_id: Int) {
    entityListViewsTotal(entity_type_id: $entity_type_id, account_id: $account_id)
  }
`;

const ToggleCopyView = ({
  account,
  additionalFeatures,
  entityType,
  viewKey,
  onChange,
  classes,
}) => {
  const { data } = useQuery(GET_ENTITY_LIST_VIEWS_TOTAL, {
    variables: {
      account_id: account.id,
      entity_type_id: entityType.id,
    },
  });

  const { entityListViewsTotal = 0 } = data || {};

  return (
    <FormControlLabel
      control={(
        <Switch
          checked={includes(additionalFeatures, viewKey)}
          onChange={onChange}
          color="primary"
        />
        )}
      label={(
        <div className={classes.labelContainer}>
          <Icon
            className={classes.icon}
            value={entityType.icon}
            color={entityType.icon_color}
          />
          <span>{`${entityType.label} views (${entityListViewsTotal})`}</span>
        </div>
        )}
    />
  );
};

ToggleCopyView.propTypes = {
  account: PropTypes.object.isRequired,
  additionalFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  entityType: PropTypes.object.isRequired,
  viewKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  labelContainer: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(ToggleCopyView);
