import { startsWith } from 'lodash';
import React from 'react';
import Icon from '../../../icon';

export default ({ location }) => ([

  [
    {
      icon: <Icon value="plumpy plumpy-hIghFNFzU0jJ" />,
      label: 'Home',
      link: '/',
      isActive: location.pathname === '/',
      show: true,
    },
  ],

  [
    {
      icon: <Icon value="plumpy plumpy-ZQcnHO3UJQZy" />,
      label: 'Accounts',
      link: '/accounts',
      isActive: startsWith(location.pathname, '/account'),
      show: true,
    },
    {
      icon: <Icon value="plumpy plumpy-xXjlE05o3dcg" />,
      label: 'Users',
      link: '/users',
      isActive: location.pathname === '/users',
      show: true,
    },
    {
      icon: <Icon value="plumpy plumpy-qBNkI2wumaPi" />,
      label: 'Security',
      link: '/security',
      isActive: location.pathname === '/security',
      show: true,
    },
    {
      icon: <Icon value="plumpy plumpy-aPtgRkkLiNl2" />,
      label: 'API keys',
      link: '/apikeys',
      isActive: location.pathname === '/apikeys',
      show: true,
    },
    {
      icon: <Icon value="plumpy plumpy-hCfHYg6AYTX0" />,
      label: 'Access requests',
      link: '/accessrequests',
      isActive: location.pathname === '/accessrequests',
      show: true,
    },
    {
      icon: <Icon value="plumpy plumpy-XuLLfXB9jZ9R" />,
      label: 'Apps',
      link: '/apps',
      isActive: location.pathname === '/apps',
      show: true,
    },
    {
      icon: <Icon value="plumpy plumpy-gvQcBTr1z1Iw" />,
      label: 'Form submissions',
      link: '/formsubmissions',
      isActive: location.pathname === '/formsubmissions',
      show: true,
    },
  ],

]);
