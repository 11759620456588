import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Alert from '@material-ui/lab/Alert';
import DialogTitle from '@material-ui/core/DialogTitle';

import api from 'api';


const ToggleTwoFactor = ({
  userId,
  user,
  classes,
  notify,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [twoFactorError, setTwoFactorError] = useState(null);


  const onClose = () => {
    setIsDialogOpen(false);
    setTwoFactorError(false);
  };

  async function handleDisableClick() {
    try {
      await api.put(`/user/${userId}/two_factor/disable`, {
        userId,
      });
      notify({
        message: 'Two factor authentication disabled',
      });
      onClose();
    } catch (e) {
      setTwoFactorError(e.message);
    }

  }

  return (

    <div className={classes.wrapper}>

      <Button
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        Disable
      </Button>

      {isDialogOpen && (
      <Dialog
        onClose={onClose}
        open={isDialogOpen}
        fullWidth
        disableBackdropClick
      >
        <DialogTitle>
          Disable two-factor
          {' 😱'}
        </DialogTitle>
        <DialogContent>
          {twoFactorError && (
          <Alert
            severity="error"
          >
            {twoFactorError}
          </Alert>
          )}
          <Typography variant="subtitle1">
            {'This is a dangerous thing to do!! You must do at least one of the following:'}
            <br />
            {`1. If you know ${user.first_name} ${user.last_name}, give them a call to confirm
                using the phone number we have for them in Beacon one or go via the public phone number
                on their website (e.g. don't let them call us and don't call them on a number they provide).`}
            <br />
            {'2. Ask over email or chat: What was your last Beacon invoice amount?'}
            <br />
            {'3. Ask for another of their admins to email us and confirm the request.'}
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >
            Nope!
            {' 🤪'}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleDisableClick}
          >
            Yes! Turn off two-factor
            {' 😎'}
          </Button>
        </DialogActions>
      </Dialog>

      )}

    </div>
  );

};

const styles = theme => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
});

ToggleTwoFactor.propTypes = {
  userId: PropTypes.number.isRequired,
  user: PropTypes.object,
  notify: PropTypes.func.isRequired,
};

export default withStyles(styles)(ToggleTwoFactor);
