/*
 * An instance of a Pusher client, from which channels can be subscribed
 * to, etc.
 */
import Pusher from 'pusher-js';

import api from 'api';
import {
  PUSHER_APP_KEY,
  PUSHER_APP_CLUSTER,
  PUSHER_LOG,
} from 'app/config/pusher';

Pusher.logToConsole = PUSHER_LOG;

const pusher = new Pusher(PUSHER_APP_KEY, {
  cluster: PUSHER_APP_CLUSTER,
  encrypted: true,
  authorizer(channel) {
    return {
      authorize(socketId, callback) {
        // Track how Pusher authenticated (we've had customers who haven't
        // been able to connect)
        window.bugsnagClient?.leaveBreadcrumb('Pusher auth request', {
          socketId,
          channel: channel.name,
        });

        api
          .post(
            '/user/auth/pusher',
            {
              socket_id: socketId,
              channel: channel.name,
            },
            { inAccount: false },
          )

          .then((authInfo) => {
            // Track how Pusher authenticated (we've had customers who haven't
            // been able to connect)
            window.bugsnagClient?.leaveBreadcrumb('Pusher auth response', {
              socketId,
              channel: channel.name,
              authInfo,
            });

            callback(false, authInfo);
          })

          .catch((err) => {
            callback(err);
          });
      },
    };
  },
});

// Export a utility function for subscribe to a channel. Returns a promise
// which resolves once the subscribe is successful.
// We should use this from now on (rather than `pusher.subscribe`
// on the above instance) because it's simpler.
export const subscribeToChannel = (channelName) => new Promise((resolve, reject) => {
  // Subscribe to the channel
  const channel = pusher.subscribe(channelName);

  // Wait for a subscription succeed message and then resolve with the
  // channel so the consumer can do stuff with it
  channel.bind('pusher:subscription_succeeded', () => {
    resolve(channel);
  });

  // Reject if fails to connect
  channel.bind('pusher:subscription_error', (error) => {
    reject(new Error(error.error));
  });
});

// Unsubscribe from a pusher channel. This is async because convention
// but will always be succcessful.
export const unsubscribeFromChannel = async (channelName) => {
  pusher.unsubscribe(channelName);
  // no events to bind to, so just resolve with undefined
};

// Export the whole pusher instance (legacy). We shouldn't need it
// now we have the above
export default pusher;
