// Reducer to allow different parts of the billing settings page
// to change draft billing state.
// https://reactjs.org/docs/hooks-reference.html#usereducer
import {
  cloneDeep,
  isPlainObject,
  map,
  includes,
} from 'lodash';

export default (state, action) => {

  switch (action.type) {

    case 'onload':
      return cloneDeep(action.billing);

    // set a value within the `state`. This
    // expects a `key` and `value` in the `action`, or
    // a `data` object if you need to set more than one key
    // at once.
    case 'set': {
      if (isPlainObject(action.data)) {
        return {
          ...state,
          ...action.data,
        };
      }

      if (action.key === 'discounts') {
        // if updating discounts, also clear the deprecated single discount
        // field, otherwise removing the last discount will cause us to display
        // the old single discount as the fallback. This will be cleaned up once
        // we have migrated discount -> discounts.
        return {
          ...state,
          discount: null,
          discounts: action.value,
        };
      }

      return {
        ...state,
        [action.key]: action.value,
      };
    }

    case 'set_number': {
      const newValue = action.value === '' ? null : Number(action.value);

      const newState = {
        ...state,
        [action.key]: newValue,
      };

      // Automatically set the `number_free` columns within limit increases
      // if you change the max users, roles, or custom fields
      const affectsLimitIncreases = [
        'max_users',
        'max_roles',
        'max_custom_entity_fields',
      ];
      if (includes(affectsLimitIncreases, action.key)) {
        newState.limit_increase_prices = map(newState.limit_increase_prices, (increasePrice) => {
          const isMatch = (
            (increasePrice.key === 'custom_fields' && action.key === 'max_custom_entity_fields')
            || (increasePrice.key === 'roles' && action.key === 'max_roles')
            || (increasePrice.key === 'users' && action.key === 'max_users')
          );
          if (isMatch) {
            return {
              ...increasePrice,
              number_free: newValue,
            };
          }
          return increasePrice;
        });
      }

      return newState;
    }

    default:
      throw new Error(`Invalid draft billing action: ${action.type}`);

  }

};
