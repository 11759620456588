import React from 'react';
import moment from 'moment';
import { gql, useQuery } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core';
import Loading from 'components/Loading';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import PropTypes from 'prop-types';
import { get, includes } from 'lodash';
import Icon from '../../../../icon';
import EditPortalFormSubmission from './EditPortalFormSubmission';
import RerunPortalFormSubmission from './RerunPortalFormSubmission';

const GET_PORTAL_FORM_SUBMISSION = gql`
  query GetPortalFormSubmission($id: String) { 
    portalFormSubmission(id: $id) {
      id
      account_id
      status
      created_at
      account {
        name
      }
      portal_form {
        id
        type
        slug
        title
      }
    }
  }
`;

const PortalFormSubmission = ({ classes, match, currentUser }) => {

  const { id } = match.params;

  const {
    loading,
    error,
    data,
  } = useQuery(GET_PORTAL_FORM_SUBMISSION, {
    variables: {
      id,
    },
  });

  return (
    loading ? (
      <Loading />
    ) : (
      <div>
        <div className={classes.header}>
          <Typography variant="h6">
            Form Submission
          </Typography>
          <div className={classes.buttonContainer}>
            <RerunPortalFormSubmission
              id={id}
              currentUser={currentUser}
            />
          </div>

        </div>

        <Card>
          <CardContent>
            <CardHeader
              title="Overview"
              avatar={<Icon value="plumpy plumpy-gvQcBTr1z1Iw" />}
              titleTypographyProps={{ variant: 'h6' }}
              classes={{ root: classes.root }}
            />

            <br />
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <strong>Account name</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {data.portalFormSubmission.account?.name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <strong>Status</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {data.portalFormSubmission.status}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <strong>Created at</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {moment(data.portalFormSubmission.created_at).format('lll')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <strong>Title</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {data.portalFormSubmission.portal_form?.title}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <strong>Type</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {data.portalFormSubmission.portal_form?.type}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <strong>Slug</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {data.portalFormSubmission.portal_form?.slug}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <strong>Submission ID</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {data.portalFormSubmission.id}
                </Typography>
              </Grid>
            </Grid>

            { error && (
            <Alert severity="error">
              {getHelpfulErrorMessage(error)}
            </Alert>
            )}

          </CardContent>
        </Card>

        <EditPortalFormSubmission
          id={id}
          loading={loading}
          error={error}
        />

      </div>

    )
  );

};

PortalFormSubmission.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    padding: theme.spacing(0.5),
  },

});

export default withStyles(styles)(PortalFormSubmission);
