import { isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateByKey } from 'reducers';
import {
  loadUser,
  updateUser,
} from 'actions/user';
import notify from 'actions/notify';
import User from './component';

const selector = createSelector([
  (state, props) => Number(props.match.params.user_id),
  getStateByKey('user.byId'),
  getStateByKey('user.isLoading'),
], (
  userId,
  usersById,
  isLoading,
) => {

  const user = usersById[userId];

  return {
    userId,
    isLoading: (
      isLoading
      || isUndefined(user)
    ),
    user,
  };
});

const mapDispatchToProps = {
  loadUser,
  updateUser,
  notify,
};

export default connect(
  selector,
  mapDispatchToProps,
)(User);
