import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({ style, size = 50 }) => (
  <div style={style}>
    <CircularProgress
      size={size}
      thickness={2}
    />
  </div>
);

Loading.defaultProps = {
  style: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
};

Loading.propTypes = {
  style: PropTypes.object,
  size: PropTypes.number,
};

export default Loading;
