import {
  map,
} from 'lodash';
import React, { useState } from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';


import { withStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';

import {
  TextField, TableRow,
  TableHead, TableCell, TableBody, Table, CardContent,
  Card, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import Loading from 'components/Loading';
import { ACCOUNT_USERS_FRAGMENT } from 'app/fragments';
import SecurityLock from './SecurityLock';


const GET_ACCOUNT_USERS_TO_FLAG = gql`
  query GetAccountUsersToFlag($query: String, $offset: Int, $limit: Int) {
    accountUsersToFlag(query: $query, offset: $offset, limit: $limit) {
      ...AccountUser
    }
    total: accountUsersToFlagTotal(query: $query)
  }
  ${ACCOUNT_USERS_FRAGMENT}
`;


const SecurityList = ({ classes }) => {
  const [query, setQuery] = useState('');

  const {
    loading,
    error,
    data: accountUsers,
    refetch,
    fetchMore,
  } = useQuery(GET_ACCOUNT_USERS_TO_FLAG, {
    variables: {
      query: '',
      offset: 0,
      limit: 100,
    },
  });

  const handleSearchQueryChange = async (e) => {
    setQuery(e.target.value);
    await refetch({
      offset: 0,
      query: e.target.value.length > 2 ? e.target.value : '',
    });
  };

  return (
    <div>

      <Typography variant="h6">
        {'Security'}
      </Typography>


      <Card>
        <CardContent className={classes.accountWrapper}>


          <TextField
            variant="outlined"
            className={classes.searchBar}
            fullWidth
            placeholder="Search accounts"
            value={query}
            onChange={handleSearchQueryChange}
            autoFocus
          />

          <br />
          <br />

          { loading ? (
            <Loading />
          ) : (
            accountUsers && (
            <InfiniteScroll
              loadMore={() => {
                fetchMore({
                  variables: {
                    offset: accountUsers.accountUsersToFlag.length,
                    limit: 100,
                    query,
                  },
                });
              }}
              hasMore={!loading && accountUsers.accountUsersToFlag.length < accountUsers.total}
            >
              <div>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Account user ID</TableCell>
                      <TableCell>User name</TableCell>
                      <TableCell>Account name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Account Created</TableCell>
                      <TableCell>Account is locked?</TableCell>
                      <TableCell>User is locked?</TableCell>
                      <TableCell>Lock?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(accountUsers.accountUsersToFlag, accountUser => (
                      <TableRow
                        key={accountUser.id}
                        className={classes.tableRow}
                      >
                        <TableCell>{accountUser.user.id}</TableCell>
                        <TableCell>
                          {accountUser.user.first_name}
                          {' '}
                          {accountUser.user.last_name}
                        </TableCell>
                        <TableCell>{accountUser.account.name}</TableCell>
                        <TableCell>{accountUser.user.email}</TableCell>
                        <TableCell>{moment(accountUser.created_at).format('ll')}</TableCell>
                        <TableCell>{`${accountUser.account.is_locked}`}</TableCell>
                        <TableCell>{`${accountUser.user.is_locked}`}</TableCell>
                        <TableCell>
                          <SecurityLock
                            accountUser={accountUser}
                            refetch={refetch}
                          />
                        </TableCell>
                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
              </div>

            </InfiniteScroll>
            )
          )}
        </CardContent>

        {error && (
        <div>
          <Alert severity="error">
            {getHelpfulErrorMessage(error)}
          </Alert>
          <br />
        </div>
        )}
      </Card>

    </div>

  );

};

const styles = theme => ({
  accountWrapper: {
    overflow: 'auto',
  },
  searchBar: {
    paddingBottom: theme.spacing(0.5),
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    height: 60,
  },
  tableCellHeader: {
    borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[1],
  },
  accountTitle: {
    padding: 10,
  },
});


export default withStyles(styles)(SecurityList);
