import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, withStyles, Tooltip } from '@material-ui/core';
import Proptypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import notify from 'actions/notify';
import { useDispatch } from 'react-redux';
import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import { includes, get } from 'lodash';

const RERUN_PORTAL_FORM_SUBMISSION = gql`
  mutation rerunPortalFormSubmission($id: String) {
    rerunPortalFormSubmission(id: $id) {
      message
      success
    }
  }
`;

const RerunPortalFormSubmission = ({ classes, id, currentUser }) => {
  const [disabled, setDisabled] = useState(false);
  const [formError, setFormError] = useState(null);
  const dispatch = useDispatch();

  const [rerunPortalFormSubmission, { error }] = useMutation(
    RERUN_PORTAL_FORM_SUBMISSION,
    {
      onCompleted: (data) => {
        const { message, success } = data.rerunPortalFormSubmission;
        if (success) {
          dispatch(notify({ message }));
        } else {
          setFormError(message);
        }
        setDisabled(false);
      },
    },
  );

  const rerunPortalForm = () => {
    setFormError(null);
    setDisabled(true);
    rerunPortalFormSubmission({ variables: { id } });
  };

  const allowedBeaconUsersEmails = [
    'chris@beaconcrm.org',
    'david@beaconcrm.org',
    'sam@beaconcrm.org',
    'ellen@beaconcrm.org',
    'haydn@beaconcrm.org',
  ];

  const userEmail = get(currentUser.currentUser, 'email');

  const isUserAuthorized = includes(allowedBeaconUsersEmails, userEmail);

  return (
    <div className={classes.buttonAndError}>
      <div>
        {(formError || error) && (
          <Alert className={classes.alert} severity="error">
            {formError || getHelpfulErrorMessage(error)}
          </Alert>
        )}
      </div>
      <div>
        <Tooltip
          title={
            !isUserAuthorized ? "You don't have permission to rerun forms." : ''
          }
          placement="top"
          disableFocusListener
          disableTouchListener
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => rerunPortalForm()}
              disabled={disabled || !isUserAuthorized}
            >
              Rerun
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  buttonAndError: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

RerunPortalFormSubmission.propTypes = {
  id: Proptypes.string.isRequired,
  currentUser: Proptypes.object.isRequired,
};

export default withStyles(styles)(RerunPortalFormSubmission);
