import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import UnpaidInvoices from './UnpaidInvoices';
import Trials from './Trials';


const Home = () => (
  <div>
    <Typography variant="h6">
      {'Home'}
    </Typography>

    <Grid
      spacing={6}
      container
    >
      <Grid item xs={6}>
        <UnpaidInvoices />
      </Grid>
      <Grid item xs={6}>
        <Trials />
      </Grid>
    </Grid>

  </div>
);

export default Home;
