import {
  map,
  find,
  cloneDeep,
} from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { gql, useQuery, useMutation } from '@apollo/client';

import Loading from 'components/Loading';
import { SERVICES_DAY_RATE, SERVICES_HOUR_RATE } from 'constants/billing';

import omitDeep from 'omit-deep';
import getDefaultCustomerEmail from './getDefaultCustomerEmail';

const GET_CREATE_BILLING_DATA = gql`
  query GetCreateBillingData($accountId: Int!) {
    billingPlans {
      key
      name
      core_features {
        key
      }
      base_prices {
        flat_fee
        rate_fee
      }
      element_prices {
        key
        rate_fee
        flat_fee
        hidden
      }
      limit_increase_prices {
        key
        number_free
        unit_cost
        step
        maximum
        hidden
      }
      min_contacts
      max_contacts
      max_users
      max_read_only_users
      max_custom_fields
      max_roles
      max_workflows
    }
    billingTaxRates {
      id
      jurisdiction
    }
    accountUsers(account_id: $accountId) {
      id
      role
      created_at
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
`;

const CREATE_UPDATE_BILLING = gql`
  mutation CreateBilling($accountId: Int!, $data: CreateOrUpdateBillingInput!) {
    createOrUpdateBilling(account_id: $accountId, input: $data) {
      plan
    }
  }
`;

const CreateBilling = ({
  account,
  onCreate,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    data = {},
    loading,
  } = useQuery(GET_CREATE_BILLING_DATA, {
    variables: {
      accountId: account.id,
    },
  });

  const [createUpdateBilling] = useMutation(CREATE_UPDATE_BILLING);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handlePlanClick = (planKey) => async () => {

    // // get rid of '__typename'
    const cleanData = omitDeep(cloneDeep(data), '__typename');

    const {
      billingTaxRates,
      accountUsers,
      billingPlans,
    } = cleanData;

    const plan = find(billingPlans, ['key', planKey]);

    const newBilling = {
      plan: planKey,

      // get the default stripe email address
      stripe_customer_email: getDefaultCustomerEmail(accountUsers),

      // default name should be the account name but can be different
      stripe_customer_name: account.name,

      // monthly by default
      technology_plan_frequency: 1,

      // 10% annual discount by default
      technology_annual_discount_percent: 10,

      // auto charge default
      collection_method: 'charge_automatically',

      // don't allow invoices for monthly billing by default
      is_send_invoice_always_allowed: false,

      // Always default to 7 days due date
      send_invoice_days_until_due: 7,

      // no tax exemptions by default
      stripe_customer_tax_exempt: 'none',

      // uk vat tax rate
      stripe_tax_rate_id: find(billingTaxRates, ['jurisdiction', 'GB']).id,

      // standard services rates
      services_day_rate_amount: SERVICES_DAY_RATE,
      services_hour_rate_amount: SERVICES_HOUR_RATE,

      // core features on this plan are enabled on setup. others are changed
      // when elements are enabled/disabled. They can also be enabled on-demand
      // outside of the enable/disable flow.
      enabled_features: map(plan.core_features, 'key'),

      // inherit prices from the plan
      base_prices: plan.base_prices,
      element_prices: plan.element_prices,
      limit_increase_prices: plan.limit_increase_prices,

      // Also inherit all limits from the selected plan
      min_contacts: plan.min_contacts,
      max_contacts: plan.max_contacts,
      max_users: plan.max_users,
      max_read_only_users: plan.max_read_only_users,
      max_workflows: plan.max_workflows,
      max_custom_entity_fields: plan.max_custom_fields,
      max_roles: plan.max_roles,
    };

    // On ultimate, set/override a bunch of defaults
    if (newBilling.plan === 'ultimate') {
      // Set the elements included as a blank array
      newBilling.elements = [];
      // Start with 100k contacts. Should be adjusted
      newBilling.contacts = 100000;
      // Always billed via invoice
      newBilling.collection_method = 'send_invoice';
      // Always net 30 days for ultimate (they need longer)
      newBilling.send_invoice_days_until_due = 30;
      // Annual by default
      newBilling.technology_plan_frequency = 12;
      // We don't do annual discounts for ultimate customers, it's
      // kinda expected
      newBilling.technology_annual_discount_percent = 0;
      // Default to 20k / year
      newBilling.ultimate_price = 20000;
    }

    // Run the graphql mutation
    await createUpdateBilling({
      variables: {
        accountId: account.id,
        data: newBilling,
      },
    });

    // Broadcast to parent
    onCreate(newBilling);

  };

  const { billingPlans } = data;

  return (
    <div>

      <Button
        onClick={handleDialogOpen}
      >
        Set up billing
      </Button>

      <Dialog
        onClose={handleDialogClose}
        open={isDialogOpen}
        maxWidth="xs"
        fullWidth
      >

        {loading && (
          <DialogContent>
            <Loading />
          </DialogContent>
        )}

        {!loading && (
          <div>
            <DialogTitle>
              Choose billing tier
            </DialogTitle>
            <List>
              {map(billingPlans, ({ key, name }) => (
                <ListItem
                  key={key}
                  button
                  onClick={handlePlanClick(key)}
                >
                  <ListItemText primary={name} />
                </ListItem>
              ))}
            </List>
          </div>
        )}

      </Dialog>

    </div>
  );
};

CreateBilling.propTypes = {
  account: PropTypes.object.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateBilling;
