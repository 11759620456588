import {
  map,
  isArray,
  filter,
} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';

import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';

import AccountUserItem from './AccountUserItem';
import Icon from '../../../../../../icon';


const getCountLabel = accountUsers => filter(accountUsers, {
  is_deactivated: false,
  is_invited: false,
}).length;


const AccountUsers = ({
  accountUsers,
  billing,
}) => (
  <Card>
    <CardContent>

      <CardTitle>
        <CardTitleIcon>
          <Icon value="plumpy plumpy-xXjlE05o3dcg" />
        </CardTitleIcon>
        {'Users'}
        {isArray(accountUsers) && ` (${getCountLabel(accountUsers)})`}
      </CardTitle>

      <br />

      <List>
        {map(accountUsers, accountUser => (
          <AccountUserItem
            key={accountUser.id}
            accountUser={accountUser}
            billing={billing}
          />
        ))}
      </List>

    </CardContent>
  </Card>
);


AccountUsers.propTypes = {
  accountUsers: PropTypes.arrayOf(PropTypes.object),
};

export default AccountUsers;
