import { get, isNil } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Checkbox,
  Typography,
  TextField,
  Tooltip,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';

import NumberInput from 'components/NumberInput';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Icon from '../../../../../icon';

const AddressLookupLicence = ({
  account,
  draftBilling,
  isBillingActive,
  isSaving,
  dispatch,
  classes,
}) => {

  const handleUpdateAllocation = ({ target }) => {
    const newValue = target.value === '' ? null : Number(target.value);

    dispatch({
      type: 'set',
      key: 'address_lookup_licence',
      value: {
        ...draftBilling.address_lookup_licence,
        free_allocation: newValue,
      },
    });
  };

  // NOTE: The feature can only be enabled on a billed account, but there is a
  // default trial licence using a generic key... Admins can update the free
  // credit allocation either on a trial account or on a billed account but in
  // the latter case only if the feature is enabled. Trial account allocations
  // are editable from the overview.
  const featureSettings = account.address_autocomplete_settings;
  const isFeatureEnabled = isBillingActive && featureSettings.is_enabled;

  const canShow = isFeatureEnabled || !isNil(draftBilling.address_lookup_licence);

  return canShow ? (
    <Card>
      <CardContent>
        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-ijINnZ1Zwhd0" />
          </CardTitleIcon>
          Address autocomplete
        </CardTitle>

        <br />

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Feature is enabled</strong>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Checkbox
              className={classes.flagCheckbox}
              color="primary"
              checked={featureSettings.is_enabled}
              disabled
              variant="outlined"
            />
          </Grid>
        </Grid>

        <br />

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Free allocation</strong>
              <Tooltip
                title="The number of free address lookup credits available on the licence (usually this will be 0)"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={get(draftBilling, 'address_lookup_licence.free_allocation', 0)}
              onChange={handleUpdateAllocation}
              variant="outlined"
              disabled={isSaving}
              fullWidth
              InputProps={{
                inputComponent: NumberInput,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Credit balance</strong>
              <Tooltip
                title="The number of purchased lookup credits remaining on the licence"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={get(draftBilling, 'address_lookup_licence.credit_balance', 0)}
              variant="outlined"
              disabled
              fullWidth
            />
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  ) : null;

};

const styles = (theme) => ({
  fieldLabel: {
    marginTop: theme.spacing(2),
  },
  flagCheckbox: {
    marginTop: theme.spacing(1),
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
});

AddressLookupLicence.propTypes = {
  account: PropTypes.object.isRequired,
  draftBilling: PropTypes.object.isRequired,
  isBillingActive: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddressLookupLicence);
