import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import isBillingActive from '@beacon/utils/isBillingActive';
import moment from 'moment';

import env from 'config/env';

const getStripeSubscriptionUrl = (billing) => {
  const subscriptionId = billing.stripe_technology_subscription_id;
  if (env === 'production') {
    return `https://dashboard.stripe.com/subscriptions/${subscriptionId}`;
  }
  return `https://dashboard.stripe.com/test/subscriptions/${subscriptionId}`;
};

const SubscriptionStatus = ({
  billing,
  classes,
}) => {

  const hasSubscription = isBillingActive(billing);

  if (hasSubscription) {
    const isSubscriptionScheduled = (
      billing.subscription_start_at
      && moment(billing.subscription_start_at).isAfter(moment())
    );

    return (
      <Alert
        className={classes.subscriptionNotExistAlert}
        severity="success"
      >
        <AlertTitle>
          Subscription is active
          {isSubscriptionScheduled && (
            <span>
              {' (starting on '}
              {moment(billing.subscription_start_at).format('ll')}
              )
            </span>
          )}
        </AlertTitle>
        {'This subscription has been set up and is '}
        {isSubscriptionScheduled ? 'scheduled' : 'active'}
        {' in Stripe.'}
        <br />
        <br />
        <span>
          <Button
            href={getStripeSubscriptionUrl(billing)}
            target="_blank"
          >
            View in Stripe
          </Button>
        </span>
      </Alert>
    );
  }

  if (!hasSubscription) {
    return (
      <Alert
        className={classes.subscriptionNotExistAlert}
        severity="info"
      >
        <AlertTitle>
          Subscription not set up
        </AlertTitle>

        <div>
          {'This customer doesn\'t have a subscription set up in Stripe yet. '}
          {billing.plan !== 'ultimate' && 'It will be created when they complete the billing setup.'}
        </div>

        <br />

        <Button variant="outlined">
          Create subscription
        </Button>
      </Alert>
    );
  }

  return <span />;

};

const styles = (theme) => ({
  subscriptionNotExistAlert: {
    marginBottom: theme.spacing(2),
  },
});

SubscriptionStatus.propTypes = {
  billing: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubscriptionStatus);
