import {
  sortBy,
  includes,
  get,
} from 'lodash';

export default (accountUsers) => {

  const sorted = sortBy(accountUsers, (accountUser) => {
    // beacon users are lowest priority
    if (includes(accountUser.user.email, '@beacon')) {
      return 2;
    }
    // and then team members
    if (accountUser.role === 'member') {
      return 1;
    }
    // oldest admin is the priority
    return -(new Date(accountUser.created_at).getTime());
  });

  return get(sorted, '0.user.email');
};
