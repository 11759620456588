import currencyFormat from 'mout/number/currencyFormat';
import { sumBy } from 'lodash';

export default (billingEstimate) => {

  const helpArr = [];

  // Base pricing
  helpArr.push(`£${currencyFormat(billingEstimate.base, 2)}/mo base price`);

  // Elements pricing
  if (billingEstimate.elements.length > 0) {
    const elementsTotal = sumBy(billingEstimate.elements, 'price');
    helpArr.push(`£${currencyFormat(elementsTotal, 2)}/mo elements`);
  }

  // Limit inrcrease pricing
  if (billingEstimate.limit_increases.length > 0) {
    const limitIncreaseTotal = sumBy(billingEstimate.limit_increases, 'price');
    helpArr.push(`£${currencyFormat(limitIncreaseTotal, 2)}/mo limit increases`);
  }

  return helpArr.join(', ');

};
