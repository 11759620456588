import { map, sumBy, sortBy } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import currencyFormat from 'mout/number/currencyFormat';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Loading from 'components/Loading';

import DueDate from './DueDate';
import Icon from '../../../../../icon';


const handleInvoiceClick = invoice => () => {
  window.open(`https://dashboard.stripe.com/invoices/${invoice.id}`);
};

const UnpaidInvoices = ({
  isLoading,
  unpaidInvoices,
  loadUnpaidInvoices,
}) => {

  // Load invoices just on mount
  useEffect(() => {
    loadUnpaidInvoices();
  }, [loadUnpaidInvoices]);

  const totalUnpaidAmount = sumBy(unpaidInvoices, 'amount_due');
  const sortedInvoices = sortBy(unpaidInvoices, 'due_date');

  return (
    <Card>
      <CardContent>
        <CardTitle>
          <CardTitleIcon>
            <img
              src={`${window.assetsHost}/img/stripe-icon.png`}
              alt="Stripe"
              width={25}
              height={25}
            />
          </CardTitleIcon>
          {'Unpaid Stripe invoices'}
          {!isLoading && ` (£${currencyFormat(totalUnpaidAmount / 100, 2)})`}
        </CardTitle>


        {isLoading ? (
          <Loading />
        ) : (
          <div>

            <List>
              {map(sortedInvoices, invoice => (
                <ListItem
                  key={invoice.id}
                  button
                  onClick={handleInvoiceClick(invoice)}
                >
                  <ListItemIcon>
                    {invoice.subscription ? (
                      <Icon value="plumpy plumpy-vkDKKBC6Xb7o" />
                    ) : (
                      <Icon value="plumpy plumpy-VwosPnJj2Oxk" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={`£${currencyFormat(invoice.amount_due / 100, 2)}`}
                    secondary={(
                      <span>
                        {invoice.customer_email}
                        {' · '}
                        <DueDate invoice={invoice} />
                      </span>
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        )}

      </CardContent>
    </Card>
  );
};


UnpaidInvoices.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadUnpaidInvoices: PropTypes.func.isRequired,
  unpaidInvoices: PropTypes.arrayOf(PropTypes.object),
};

export default UnpaidInvoices;
