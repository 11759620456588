import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import Alert from 'components/Alert';

class ToggleBillingExempt extends Component {

  constructor() {
    super();

    this.state = {
      isSaving: false,
      isDialogOpen: false,
      exemptError: null,
    };

    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleBillingExemptClick = this.handleBillingExemptClick.bind(this);
  }

  handleToggleClick() {
    const {
      account,
      updateAccount,
    } = this.props;

    if (account.is_billing_exempt === true) {

      this.setState({
        isSaving: true,
      });

      updateAccount(account.id, {
        is_billing_exempt: false,
      })

        .then(() => {
          this.setState({
            isSaving: false,
            isDialogOpen: false,
          });
        });

    } else {

      this.setState({
        isDialogOpen: true,
      });

    }
  }

  handleBillingExemptClick() {
    const {
      updateAccount,
      account,
    } = this.props;

    this.setState({
      isSaving: true,
    });

    updateAccount(account.id, {
      is_billing_exempt: true,
    })

      .then(() => {
        this.setState({
          isSaving: false,
          isDialogOpen: false,
        });
      })

      .catch((err) => {
        this.setState({
          exemptError: err.message,
        });
      });

  }

  handleDialogClose() {
    this.setState({
      isDialogOpen: false,
    });
  }

  render() {
    const {
      account,
      classes,
    } = this.props;
    const {
      isDialogOpen,
      isSaving,
      exemptError,
    } = this.state;

    return (
      <div className={classes.wrapper}>

        <Button
          onClick={this.handleToggleClick}
          disabled={isSaving}
        >
          {account.is_billing_exempt && isSaving && 'Making them pay...'}
          {account.is_billing_exempt && !isSaving && 'MAKE THEM PAY'}
          {!account.is_billing_exempt && 'Make billing exempt'}
        </Button>

        <Dialog
          open={isDialogOpen}
          fullWidth
          onClose={this.handleDialogClose}
        >
          <DialogTitle>
            Make billing exempt
          </DialogTitle>
          <DialogContent>

            {exemptError && (
              <Alert
                type="danger"
                message={exemptError}
              />
            )}

            <Typography variant="subtitle1">
              Are you sure you want to make
              {` ${account.name} `}
              billing exempt?
            </Typography>
            <Typography variant="subtitle1">
              Don't you like money?
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleBillingExemptClick}
              disabled={isSaving}
            >
              {isSaving ? 'Exemptifying...' : 'Make billing exempt'}
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }

}

const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
});

ToggleBillingExempt.propTypes = {
  account: PropTypes.object.isRequired,
  updateAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(ToggleBillingExempt);
