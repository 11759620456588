import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { ApolloProvider } from '@apollo/client';

import Notify from 'components/Notify';

import apiClient from './apollo';
import { ApplicationData } from './ApplicationContext';
import browserHistory from './history';
import store from './store';
import Theme from './Theme';
import Admin from './views/Admin';

// Set the locale to British english
// GLORY TO THE EMPIRE 🇬🇧
moment.locale('en_GB');

function App() {
  return (
    <div>

      <CssBaseline />

      <Provider store={store}>
        <Theme>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ApolloProvider client={apiClient}>
              <ApplicationData>
                <Router history={browserHistory}>
                  <div>
                    <Switch>
                      <Route path="/" component={Admin} />
                    </Switch>
                  </div>
                </Router>
              </ApplicationData>
              {/* Wrapper component for notifications that show at the bottom */}
              <Notify />
            </ApolloProvider>
          </MuiPickersUtilsProvider>
        </Theme>
      </Provider>
    </div>
  );
}

export default hot(App);
