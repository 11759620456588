import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';


const ExpiryDate = ({ trial, classes }) => (
  <span
    className={classNames({
      [classes.expired]: moment(trial.free_trial_expires_at ? trial.free_trial_expires_at : moment(trial.created_at).add(14, 'd')).isBefore(moment()),
    })}
  >
    {(moment(trial.free_trial_expires_at ? trial.free_trial_expires_at : moment(trial.created_at).add(14, 'd')).isBefore(moment())) ? 'Expired ' : 'Expires '}
    {moment(trial.free_trial_expires_at ? trial.free_trial_expires_at : moment(trial.created_at).add(14, 'd')).format('Do MMM, YYYY')}
  </span>
);

const styles = {
  expired: {
    color: red[500],
  },
};

ExpiryDate.propTypes = {
  trial: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExpiryDate);
