import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';


const DueDate = ({ invoice, classes }) => (
  <span
    className={classNames({
      [classes.overdue]: moment(invoice.due_date, 'X').isBefore(moment()),
    })}
  >
    {'Due '}
    {moment(invoice.due_date, 'X').format('Do MMM, YYYY')}
  </span>

);

const styles = {
  overdue: {
    color: red[500],
  },
};

DueDate.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default withStyles(styles)(DueDate);
