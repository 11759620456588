import {
  InMemoryCache,
} from '@apollo/client';
import { isObject, isArray } from 'lodash';

export const offsetPagination = (
  keyArgs = false,
) => ({
  keyArgs,
  merge(existing, incoming, { args }) {
    const { offset, page } = args;

    if (!existing || offset === 0 || page === 1) {
      return incoming;
    }

    const returnValue = [];
    if (isObject(existing) || isArray(existing)) {
      returnValue.push(...existing);
    } else {
      returnValue.push(existing);
    }

    if (isObject(incoming) || isArray(incoming)) {
      returnValue.push(...incoming);
    } else {
      returnValue.push(incoming);
    }

    return returnValue;
  },
});

export default new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        accountUsersToFlag: offsetPagination(['id']),
        accounts: offsetPagination(['id']),
        apiKeys: offsetPagination(['id']),
        accessRequests: offsetPagination(['id']),
        portalFormSubmissions: offsetPagination(['id']),
      },
    },
  },
});
