import {
  get,
  head,
  isInteger,
  map,
  split,
} from 'lodash';
import React from 'react';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import FontIcon from '@material-ui/core/Icon';
import green from '@material-ui/core/colors/green';

import {
  Grid,
  Link,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  CardContent,
  Card,
  Typography,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';

import browserHistory from 'app/history';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Loading from 'components/Loading';
import HealthStatusIcon from 'components/HealthStatusIcon';
import { APP_FRAGMENT, APP_CONFIGURATION_FRAGMENT } from 'app/fragments';

import Icon from '../../../../icon';
import ToggleAllAppsIsEnabled from './ToggleAllAppsIsEnabled';

const GET_APP_CONFIGURATIONS_BY_APP = gql`
  query GetAppConfigurationsByApp ($app_id: Int) {
    appConfigurationsByApp (app_id: $app_id) {
      ...AppConfiguration
    }
    app (id: $app_id){
      ...App
    }
  }
  ${APP_CONFIGURATION_FRAGMENT}
  ${APP_FRAGMENT}
`;

const green500 = green['500'];

const App = ({
  match,
  classes,
}) => {

  const {
    loading,
    data: results,
  } = useQuery(GET_APP_CONFIGURATIONS_BY_APP, {
    variables: {
      app_id: Number(match.params.app_id),
    },
  });
  const appConfigurations = get(results, 'appConfigurationsByApp', []);
  const app = get(results, 'app', {});

  const handleRowClick = (accountId) => () => {
    browserHistory.push(`/account/${accountId}`);
  };

  return (
    <div>

      <div className={classes.appHeader}>

        <div className={classes.disableButton} />

        <div className={classes.appHeaderTop}>
          <img src={app.icon} alt={app.title} className={classes.appIcon} />
          <Typography variant="h2" className={classes.appHeaderTitle}>
            {`${app.title}${app.is_enabled ? '' : ' (disabled)'}`}
          </Typography>
          <Typography className={classes.description}>
            {`${head(split(app.short_description, '.'))}.`}
          </Typography>
        </div>

      </div>

      <br />

      <Card>
        <CardContent>

          <CardTitle>
            <CardTitleIcon>
              <Icon value="plumpy plumpy-XuLLfXB9jZ9R" />
            </CardTitleIcon>
            Overview
          </CardTitle>

          <br />

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Key</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {app.key}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Created</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {moment(app.created_at).format('ll')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Beacon guide</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                <Link href={app.help_link} target="_blank">
                  {app.help_link}
                </Link>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Is enabled?</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className={classes.inline}>
                {app.is_enabled ? 'Yes' : 'No'}
              </Typography>
              <div className={classes.inline}>
                <ToggleAllAppsIsEnabled
                  app={app}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1">
                <strong>Last updated</strong>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {moment(app.updated_at).format('lll')}
                {' – '}
                {moment(app.updated_at).fromNow()}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <br />
      <br />

      <Card>
        <CardContent className={classes.accountWrapper}>

          <Typography variant="h6">
            Accounts with
            {' '}
            {app.title}
            {' '}
            installed
            {!loading && isInteger(app.total_installs) && ` (${app.total_installs})`}
          </Typography>

          <br />

          {loading ? (
            <Loading />
          ) : (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Account ID</TableCell>
                    <TableCell>Account name</TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Health status</TableCell>
                    <TableCell>Installed at</TableCell>
                    <TableCell>Last updated</TableCell>
                    <TableCell>Disabled at</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(appConfigurations || [], (appConfiguration) => (
                    <TableRow
                      key={appConfiguration.account.id}
                      className={classes.tableRow}
                      onClick={handleRowClick(appConfiguration.account.id)}
                    >
                      <TableCell>{appConfiguration.account.id}</TableCell>
                      <TableCell>{appConfiguration.account.name}</TableCell>
                      <TableCell>
                        {appConfiguration.is_enabled ? (
                          <FontIcon
                            aria-label="app configuration enabled"
                            className="zmdi zmdi-check-circle"
                            style={{
                              color: green500,
                            }}
                          />
                        ) : (
                          <span aria-label="app configuration disabled" />
                        )}
                      </TableCell>
                      <TableCell>
                        <HealthStatusIcon configuration={appConfiguration} />
                      </TableCell>
                      <TableCell>{moment(appConfiguration.created_at).format('ll')}</TableCell>
                      <TableCell>{moment(appConfiguration.updated_at).format('ll')}</TableCell>
                      <TableCell>
                        {appConfiguration.disabled_at
                          ? moment(appConfiguration.disabled_at).format('ll')
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

        </CardContent>
      </Card>
    </div>

  );
};

const styles = (theme) => ({
  accountWrapper: {
    overflow: 'auto',
  },
  appHeaderTitle: {
    fontSize: 24,
    fontWeight: 400,
    color: '#000',
    marginBottom: 5,
    marginTop: 0,
  },
  appHeaderTop: {
    overflow: 'auto',
    marginBottom: 15,
  },
  appIcon: {
    float: 'left',
    marginRight: 15,
    width: 64,
    height: 64,
  },
  description: {
    marginBottom: 0,
    color: theme.palette.grey[700],
    '& p': {
      marginBottom: 8,
    },
  },
  disableButton: {
    float: 'right',
  },
  inline: {
    display: 'inline',
    marginRight: theme.spacing(1),
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[200],
    },
    height: 60,
  },
});

export default withStyles(styles)(App);
