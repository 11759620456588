import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateByKey } from 'reducers';

import notify from 'actions/notify';
import { loadAccountBilling } from 'actions/account';

import BillingV2 from './component';

const selector = createSelector([
  (state, props) => Number(props.match.params.account_id),
  getStateByKey('account.byId'),
], (accountId, accountsById) => ({
  account: accountsById[accountId],
}));

const mapDispatchToProps = {
  notify,
  loadAccountBilling,
};

export default connect(
  selector,
  mapDispatchToProps,
)(BillingV2);
