/*
 * React hook for connecting to a Pusher channel. Returns a
 * `status` (pending, connected, error), along with the `channel`
 * itself.
 *
 * Example:
 * `const { status, channel } = usePusherChannel('mychannel')
 */
import { useState, useEffect } from 'react';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import pusher from '../../lib/pusher';

const usePusherChannel = (channelName) => {
  const [channel, setChannel] = useState(null);
  const [status, setStatus] = useState('pending');

  useEffect(() => {
    // No channel provided yet? That's fine - just skip
    if (!channelName) {
      return;
    }

    const pusherChannel = pusher.subscribe(channelName);
    setChannel(pusherChannel);

    // resolve the promise only after subscription is succeeded
    pusherChannel.bind('pusher:subscription_succeeded', () => {
      setStatus('connected');
    });

    pusherChannel.bind('pusher:subscription_error', () => {
      setStatus('error');
    });

    return () => {
      pusher.unsubscribe(channelName);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelName]);

  return {
    status,
    channel,
  };
};

export default usePusherChannel;
