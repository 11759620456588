import {
  filter,
  find,
  includes,
  map,
  some,
} from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';

const GET_BEACON_CRM_USERS = gql`
  query GetAtBeaconcrmDotOrgUsers {
    atBeaconcrmDotOrgUsers {
      id
      first_name
      last_name
      email
    }
  }
`;

const AccessRequestButton = ({
  account,
  currentUser,
  activeRequests,
  isDialogOpen,
  onToggleDialog,
  onCreateRequest,
  createRequestError,
  classes,
}) => {

  const {
    data,
    loading,
    error: loadUsersError,
  } = useQuery(GET_BEACON_CRM_USERS);

  const accountTeamMembersSettingUri = `https://app.beaconcrm.org/account/${account.id}/settings/team`;

  // Nasty but it is the only way as things stand...
  const isCurrentUserManager = includes([
    'ben@beaconcrm.org',
    'charles@beaconcrm.org',
    'chris@beaconcrm.org',
    'david@beaconcrm.org',
    'sam@beaconcrm.org',
  ], currentUser.email);

  const [receiverUser, setReceiverUser] = useState(currentUser);

  // Reset local state and invoke the callback
  const toggleDialogState = () => {
    setReceiverUser(currentUser);
    onToggleDialog();
  };

  const getReceiverUserEmail = () => {
    if (receiverUser) {
      return receiverUser.email;
    }

    return '';
  };

  const handleCreateRequest = () => {
    onCreateRequest(receiverUser.id);
  };

  const handleSelectBeaconUser = ({ target }) => {
    if (data) {
      const { atBeaconcrmDotOrgUsers } = data;

      const user = find(
        atBeaconcrmDotOrgUsers,
        ['email', target.value],
      );

      setReceiverUser(user);
    }
  };

  // A beacon manager user can select another beacon user (including themselves)
  // to be the receiver of the temporary access session, so we render a dropdown
  // list of beacon users to chose from. In the other case, the user must chose
  // themselves to be the recipient...
  const renderUserMenuOptions = () => {
    if (loading) return null;

    if (isCurrentUserManager && data) {
      const { atBeaconcrmDotOrgUsers } = data;

      return atBeaconcrmDotOrgUsers
        ? map(atBeaconcrmDotOrgUsers, (user) => {
          // An access request cannot be granted for a user if there is already
          // an unexpired and unrevoked request open for that account user...
          const isDisabled = some(
            filter(activeRequests, ['revoked_by_user_id', null]),
            ['account_user.user.id', user.id],
          );

          return (
            <MenuItem
              key={`user-id-${user.id}`}
              value={user.email}
              disabled={isDisabled}
            >
              {user.email}
            </MenuItem>
          );
        })
        : null;
    }
  };

  return (
    <div className={classes.button}>
      <Button
        onClick={toggleDialogState}
        variant="contained"
      >
        {isCurrentUserManager ? 'Grant access' : 'Request access'}
      </Button>

      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={toggleDialogState}
      >
        <DialogTitle>
          {isCurrentUserManager
            ? 'Temporary account access for @beaconcrm team members'
            : `Temporary account access for ${currentUser.email}`}
        </DialogTitle>

        <DialogContent>
          {isCurrentUserManager ? (
            <DialogContentText>
              {'Grant any beacon team member immediate access to '}
              <strong>{account.name}</strong>
              .
            </DialogContentText>
          ) : (
            <DialogContentText>
              {'You are requesting temporary access to the account '}
              <strong>{account.name}</strong>
              {'. The request must be approved by an account administrator under Settings > Team members at '}
              <strong>{accountTeamMembersSettingUri}</strong>
            </DialogContentText>
          )}

          {isCurrentUserManager && loadUsersError && (
            <>
              <br />

              <Alert severity="error">
                {`Error loading beacon crm users: ${getHelpfulErrorMessage(loadUsersError)}. You can still request access on your own behalf.`}
              </Alert>
            </>
          )}

          {createRequestError && (
            <>
              <br />
              <Alert severity="error">
                {`Error creating the access request: ${getHelpfulErrorMessage(createRequestError)}`}
              </Alert>
            </>
          )}

          {isCurrentUserManager ? (
            <>
              <br />
              <TextField
                value={getReceiverUserEmail()}
                onChange={handleSelectBeaconUser}
                variant="outlined"
                fullWidth
                select
                disabled={Boolean(loadUsersError)}
              >
                {renderUserMenuOptions()}
              </TextField>
            </>
          ) : null}
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            onClick={toggleDialogState}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={handleCreateRequest}
            variant="contained"
          >
            {isCurrentUserManager ? 'Grant' : 'Request'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = (theme) => ({
  actions: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
  },
});

AccessRequestButton.propTypes = {
  account: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  activeRequests: PropTypes.arrayOf(PropTypes.object),
  isDialogOpen: PropTypes.bool.isRequired,
  onToggleDialog: PropTypes.func.isRequired,
  onCreateRequest: PropTypes.func.isRequired,
  createRequestError: PropTypes.object,
};

export default withStyles(styles)(AccessRequestButton);
