import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';

function TogglePartnerReferral({
  account, updateAccount, isBillingActive, classes,
}) {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [toggleError, setToggleError] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleToggleClick = async () => {
    try {
      setHasSaved(false);
      setToggleError(null);
      setIsSaving(true);

      await updateAccount(account.id, {
        is_partner_referral: !account.is_partner_referral,
      });

      setHasSaved(true);

      setTimeout(() => {
        setIsDialogOpen(false);
        setIsSaving(false);
        setHasSaved(false);
      }, 1500);

    } catch (e) {
      setToggleError('Failed to update account');
      setIsSaving(false);
    }
  };

  return (
    <div className={classes.wrapper}>

      <Button
        onClick={handleDialogOpen}
      >
        {account.is_partner_referral && 'Un-set as partner-referral'}
        {!account.is_partner_referral && 'Set as partner-referral'}
      </Button>

      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={handleDialogClose}
      >
        <DialogTitle>
          {account.is_partner_referral ? 'Un-set' : 'Set'}
          {' as partner referral'}
        </DialogTitle>
        <DialogContent>

          {toggleError && (
            <div>
              <Alert severity="error">
                {toggleError}
              </Alert>
              <br />
            </div>
          )}

          {hasSaved && (
            <div>
              <Alert severity="success">
                Success!
              </Alert>
              <br />
            </div>
          )}

          {isBillingActive && (
            <div>
              <Alert severity="warning">
                Billing has already been set up for this account, so the partner referral discount will need to be set up manually in Stripe.
              </Alert>
              <br />
            </div>
          )}

          {account.is_partner_referral ? (
            <Typography variant="subtitle1">
              {'Un-set '}
              {` ${account.name} `}
              {'as a partner referral? '}
              <strong>
                This will prevent a 15% discount
              </strong>
              {' from being applied when they set up billing.'}
            </Typography>
          ) : (
            <Typography variant="subtitle1">
              {'Would you like to set '}
              {` ${account.name} `}
              {'as a partner referral? '}
              <strong>
                This will apply a 15% discount
              </strong>
              {' on their 1st year Beacon subscription when they set up billing.'}
            </Typography>
          )}

        </DialogContent>

        {!hasSaved && (
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              onClick={handleToggleClick}
              disabled={isSaving || isBillingActive}
              variant="contained"
              color="primary"
            >
              {isSaving ? 'Saving...' : 'Make partner referral'}
            </Button>
          </DialogActions>
        )}
      </Dialog>

    </div>
  );

}

const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
});

TogglePartnerReferral.propTypes = {
  account: PropTypes.object.isRequired,
  isBillingActive: PropTypes.bool.isRequired,
  updateAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(TogglePartnerReferral);
