import {
  map,
  isArray,
  find,
} from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  ListItemText,
  TextField,
  MenuItem,
  Tooltip,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import { gql, useQuery } from '@apollo/client';

import arePropsEqual from 'utils/arePropsEqual';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Icon from '../../../../../icon';


const GET_TAX_RATES = gql`
  query GetTaxRates {
    billingTaxRates {
      id
      jurisdiction
      display_name
      percentage
    }
  }
`;

const BillingTax = ({
  draftBilling,
  isBillingActive,
  isSaving,
  dispatch,
  classes,
}) => {

  const {
    data = {},
  } = useQuery(GET_TAX_RATES);

  const { billingTaxRates } = data;


  // set the new tax exempt status, along with the relevant tax rate
  // ID. We only tax UK customers.
  const handleStripeCustomerTaxExemptChange = (e) => {
    const newData = {
      stripe_customer_tax_exempt: e.target.value,
    };

    const ukTaxRate = find(billingTaxRates, ['jurisdiction', 'GB']);

    if (newData.stripe_customer_tax_exempt === 'none' && ukTaxRate) {

      // Set the UK tax rate when changed to `none`, i.e.
      // there is tax to pay
      newData.stripe_tax_rate_id = ukTaxRate.id;

    } else if (newData.stripe_customer_tax_id !== 'none') {

      // Set the tax rate to null if they are exempt either
      // via reverse charging or true exemptions
      newData.stripe_tax_rate_id = null;

    }

    dispatch({
      type: 'set',
      data: newData,
    });
  };


  return (
    <Card>
      <CardContent>
        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-k8xXTceQVvPp" />
          </CardTitleIcon>
          Tax
        </CardTitle>

        <br />

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.fieldLabel}>
              <strong>Tax status</strong>
              <Tooltip
                title="Is the customer exempt from tax, or a business in the EU? Or are they a regular UK customer?"
                placement="top"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={draftBilling.stripe_customer_tax_exempt || 'none'}
              onChange={handleStripeCustomerTaxExemptChange}
              variant="outlined"
              disabled={isSaving}
              helperText="Should the customer pay VAT?"
              fullWidth
              select
              SelectProps={{
                renderValue: (value) => {
                  const labels = {
                    none: 'Taxable',
                    reverse: 'Reverse charge',
                    exempt: 'Exempt',
                  };
                  return labels[value];
                },
              }}
            >
              <MenuItem value="none">
                <ListItemText
                  primary="Taxable"
                  secondary="Customer pays tax (all UK customers)"
                />
              </MenuItem>
              <MenuItem value="reverse">
                <ListItemText
                  primary="Reverse charge"
                  secondary="EU organisations pay VAT directly to their government through reverse charging"
                />
              </MenuItem>
              <MenuItem value="exempt">
                <ListItemText
                  primary="Exempt"
                  secondary="Customer pays no tax. For organisations outside of the EU and tax havens."
                />
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>


        {isArray(billingTaxRates) && draftBilling.stripe_customer_tax_exempt === 'none' && (
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1" className={classes.fieldLabel}>
                <strong>Tax rate</strong>
                <Tooltip
                  title="What rate of tax should the customer pay?"
                  placement="top"
                >
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={draftBilling.stripe_tax_rate_id || ''}
                onChange={e => dispatch({
                  type: 'set',
                  key: 'stripe_tax_rate_id',
                  value: e.target.value,
                })}
                variant="outlined"
                disabled={isSaving}
                fullWidth
                select
              >
                {map(billingTaxRates, taxRate => (
                  <MenuItem
                    value={taxRate.id}
                    key={taxRate.id}
                  >
                    {taxRate.jurisdiction}
                    {' - '}
                    {taxRate.display_name}
                    {' ('}
                    {taxRate.percentage}
                    {'%)'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )}

        {isBillingActive && (
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="body1" className={classes.fieldLabel}>
                <strong>VAT number</strong>
                <Tooltip
                  title="VAT number to be shown on invoices"
                  placement="top"
                >
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={draftBilling.tax_id || ''}
                onChange={e => dispatch({
                  type: 'set',
                  key: 'tax_id',
                  value: e.target.value,
                })}
                variant="outlined"
                disabled={isSaving}
                helperText="Should start with the country code, e.g. GB"
                fullWidth
              />
            </Grid>
          </Grid>
        )}

      </CardContent>
    </Card>
  );

};

const styles = theme => ({
  fieldLabel: {
    marginTop: theme.spacing(2),
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
});

BillingTax.propTypes = {
  draftBilling: PropTypes.object.isRequired,
  isBillingActive: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};


// Only re-render when these props change
const watchedProps = [
  'isSaving',
  'draftBilling.stripe_customer_tax_exempt',
  'draftBilling.stripe_tax_rate_id',
  'draftBilling.tax_id',
];

export default withStyles(styles)(memo(BillingTax, arePropsEqual(watchedProps)));
