import { find } from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';


const ElementTrial = ({
  draftBilling,
  elementKey,
  isInUse,
  isNew,
  isSaving,
  onDisableElement: handleDisableElement,
  onToggleTrial: handleToggleTrial,
  onUpdateTrial: handleUpdateTrial,
  classes,
}) => {

  const elementTrialSetting = find(
    draftBilling.element_trials,
    ['element', elementKey],
  );

  const isTrialUnexpired = Boolean(elementTrialSetting)
    && moment(elementTrialSetting.expires).endOf('day') > moment();

  // Don't let anyone set expiry for today or tomorrow
  const isInvalidDate = date => date.isSame(moment(), 'day')
    || date.isSame(moment().add(1, 'day'), 'day');

  return (
    <div>
      <span>
        {isInUse && !isTrialUnexpired && !isNew && (
        // This is only seen where the corresponding trial is expired AND the
        // customer still has the enabled element in use in the dashboard
        <Tooltip
          title={
            'This unpurchased element is in use beyond expiration of the trial period. '
            + 'Extend the trial to remove the warning, or force element disabled for the customer '
            + '(note it will no longer be possible to reenable from customer settings).'
          }
          placement="top"
        >
          <Button
            onClick={handleDisableElement}
            className={classes.button}
          >
            <WarningIcon className={classes.buttonIcon} />
            Disable with force!
          </Button>
        </Tooltip>
        )}

        {Boolean(elementTrialSetting) && (
        // Allows to update the expiration date
        <DatePicker
          error={!isTrialUnexpired}
          label={isTrialUnexpired ? 'Trial expires at' : 'Trial expired at'}
          disabled={isSaving}
          value={(elementTrialSetting || {}).expires}
          format="Do MMMM, YYYY"
          onChange={handleUpdateTrial}
          helperText="" // Prevent showing expired date helper text
          shouldDisableDate={isInvalidDate} // Can't set today or tomorrow
          disablePast={!isTrialUnexpired || isNew} // If new or already expired
        />
        )}


        {isNew && (
        // This is only seen while the session is in draft state, once saved, a
        // trial is fixed and can only be updated...
        <FormControlLabel
          control={(
            <Switch
              checked={isTrialUnexpired}
              onChange={handleToggleTrial}
            />
          )}
          label={elementTrialSetting ? '' : 'Enable element trial'}
          labelPlacement="start"
        />
        )}
      </span>
    </div>
  );

};


const styles = theme => ({
  button: {
    marginRight: '1rem',
    color: theme.palette.error.dark,
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
});

ElementTrial.propTypes = {
  draftBilling: PropTypes.object.isRequired,
  elementKey: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  isInUse: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onDisableElement: PropTypes.func.isRequired,
  onToggleTrial: PropTypes.func.isRequired,
  onUpdateTrial: PropTypes.func.isRequired,
};

export default withStyles(styles)(ElementTrial);
