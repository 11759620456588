/*
* Use the react number format component library to export an input that:
* 1) only allows numbers in a valid currency format
* 2) adds thousand separators
* 3) has max 2.d.p
*
* Also, usefully, it can't have any non numeric things added, which is handy
*
* Used inside filters and editable entity field value for*
* number/currency/percent inputs
*
* TODO make this a shared component
*/
import { omit } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const NumberInput = (props) => {
  const {
    inputRef, onChange, decimalScale, thousandSeparator, fixedDecimalScale, allowCommas,
  } = props;

  const other = omit(props, ['inputRef', 'onChange', 'decimalScale', 'fixedDecimalScale', 'allowCommas']);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={allowCommas ? thousandSeparator : false}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
    />
  );
};

NumberInput.defaultProps = {
  decimalScale: 0,
  thousandSeparator: true,
  allowCommas: true,
  fixedDecimalScale: false,
};

NumberInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalScale: PropTypes.number,
  thousandSeparator: PropTypes.bool,
  allowCommas: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
};

export default NumberInput;
