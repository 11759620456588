import React from 'react';
import PropTypes from 'prop-types';

import {
  CardContent,
  Card,
  Collapse,
  Switch,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CardTitle from 'components/CardTitle';

const AdditionalFeatureToggleButtons = ({
  title,
  copyAll,
  setCopyAll,
  children,
  classes,
}) => (
  <Card>
    <CardContent className={classes.card}>
      <CardTitle className={classes.cardTitle}>
        <span className={classes.cardLabel}>
          {title}
        </span>

        <Switch
          checked={copyAll}
          onChange={() => setCopyAll(!copyAll)}
          color="primary"
          name="forms_all"
        />
      </CardTitle>

      <Collapse in={!copyAll}>
        <br />
        <div className={classes.formToggles}>
          {children}
        </div>
      </Collapse>
    </CardContent>
  </Card>
);

AdditionalFeatureToggleButtons.propTypes = {
  title: PropTypes.string.isRequired,
  copyAll: PropTypes.bool.isRequired,
  setCopyAll: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0',
  },
  cardLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: '400',
    gap: '16px',
  },
  formToggles: {
    display: 'grid',
    justifyItems: 'start',
    gap: theme.spacing(1),
  },
});

export default withStyles(styles)(AdditionalFeatureToggleButtons);
