import { isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateByKey } from 'reducers';
import {
  loadAccount,
  loadAccountBilling,
  loadAccountUsers,
} from 'actions/account';
import {
  loadEntityTypes,
} from 'actions/entity_type';
import Account from './component';

const selector = createSelector([
  (state, props) => Number(props.match.params.account_id),
  getStateByKey('account.byId'),
  getStateByKey('account.billingById'),
  getStateByKey('account.isLoading'),
  getStateByKey('account.isBillingLoading'),
  getStateByKey('account.isLoadingUsers'),
  (state, props) => {
    const accountId = Number(props.match.params.account_id);
    return state.account.accountUsersById[accountId];
  },
  (state, props) => {
    const accountId = Number(props.match.params.account_id);
    return state.entityType.byAccountId[accountId];
  },
], (
  accountId,
  accountsById,
  billingById,
  isLoading,
  isBillingLoading,
  isLoadingUsers,
  accountUsers,
  entityTypes,
) => {

  const account = accountsById[accountId];
  const billing = billingById[accountId];

  return {
    accountId,
    isLoading: (
      // isLoading
      // || isBillingLoading
      // || isLoadingUsers
      isUndefined(account)
      || isUndefined(billing)
      || isUndefined(accountUsers)
      || isUndefined(entityTypes)
    ),
    account,
    billing,
  };
});

const mapDispatchToProps = {
  loadAccount,
  loadAccountBilling,
  loadAccountUsers,
  loadEntityTypes,
};

export default connect(
  selector,
  mapDispatchToProps,
)(Account);
