import { isInteger } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  MenuItem,
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab';
import NumberInput from 'components/NumberInput';
import currencyFormat from 'mout/number/currencyFormat';
import { withStyles } from '@material-ui/core/styles';

import getHelpfulErrorMessage from 'utils/getHelpfulErrorMessage';
import api from 'api';


const ServicesInvoice = ({
  billing,
  classes,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [timeType, setTimeType] = useState('days');
  const [timeQuantity, setTimeQuantity] = useState(1);
  const [isSending, setIsSending] = useState(false);
  const [sendError, setSendError] = useState(null);
  const [hasSent, setHasSent] = useState(false);


  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleTimeTypeChange = (e) => {
    setTimeType(e.target.value);
  };

  const handleTimeQuantityChange = (e) => {
    const newQuantity = e.target.value === '' ? null : Number(e.target.value);
    setTimeQuantity(newQuantity);
  };

  const handleSendClick = async () => {
    try {
      setIsSending(true);
      setSendError(null);

      await api.post(`/account/${billing.account_id}/billing/implementation_invoice`, {
        quantity: timeQuantity,
        type: timeType,
      });

      setHasSent(true);
      setIsSending(false);

      setTimeout(() => {
        setIsDialogOpen(false);
      }, 1500);

      setTimeout(() => {
        setHasSent(false);
        setTimeType('days');
        setTimeQuantity(1);
      }, 3000);

    } catch (e) {
      setIsSending(false);
      setSendError(getHelpfulErrorMessage(e));
    }
  };

  const isQuantityValid = isInteger(timeQuantity) && timeQuantity >= 1;

  let invoiceSubtotal = 0;
  if (isQuantityValid) {
    const unitCost = timeType === 'days'
      ? billing.services_day_rate_amount
      : billing.services_hour_rate_amount;
    invoiceSubtotal = unitCost * timeQuantity;
  }


  return (
    <span>

      <Button
        onClick={handleDialogOpen}
        className={classes.buttonBilling}
      >
        Send implementation invoice
      </Button>


      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>
          Send implementation invoice
        </DialogTitle>

        {hasSent ? (
          <DialogContent>
            <Alert severity="success">
              {'Success! An invoice was sent to '}
              {billing.stripe_customer_email}
              {'.'}
            </Alert>
            <br />
            <br />
          </DialogContent>
        ) : (
          <div>
            <DialogContent>

              {sendError && (
                <div>
                  <Alert severity="error">
                    {sendError}
                  </Alert>
                  <br />
                </div>
              )}

              <DialogContentText>
                {'Send an invoice to the customer for implementation, training, or other services.'}
              </DialogContentText>

              <DialogContentText>
                {'Finance email: '}
                {billing.stripe_customer_email}
              </DialogContentText>

              <TextField
                label="Time"
                variant="outlined"
                select
                margin="normal"
                fullWidth
                value={timeType}
                onChange={handleTimeTypeChange}
                disabled={isSending}
              >
                <MenuItem value="days">
                  {'Days (£'}
                  {billing.services_day_rate_amount}
                  {'/day'}
                  {billing.stripe_customer_tax_exempt === 'none' && ' + VAT'}
                  {')'}
                </MenuItem>
                <MenuItem value="hours">
                  {'Hours (£'}
                  {billing.services_hour_rate_amount}
                  {'/hour'}
                  {billing.stripe_customer_tax_exempt === 'none' && ' + VAT'}
                  {')'}
                </MenuItem>
              </TextField>


              <TextField
                value={timeQuantity || ''}
                error={!isQuantityValid}
                variant="outlined"
                disabled={isSending}
                label={`Number of ${timeType}`}
                margin="normal"
                fullWidth
                InputProps={{
                  inputComponent: NumberInput,
                }}
                inputProps={{ // eslint-disable-line
                  decimalScale: 0,
                }}
                onChange={handleTimeQuantityChange}
              />

              <br />
              <br />

              <DialogContentText>
                <strong>
                  {'Invoice amount: £'}
                  {currencyFormat(invoiceSubtotal, 2)}
                  {billing.stripe_customer_tax_exempt === 'none' && ' + VAT'}
                </strong>
              </DialogContentText>

            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleDialogClose}
                disabled={isSending}

              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={isSending || !isQuantityValid}
                onClick={handleSendClick}
              >
                {isSending ? 'Sending...' : 'Send invoice'}
              </Button>
            </DialogActions>
          </div>
        )}

      </Dialog>

    </span>
  );


};

const styles = {
  buttonBilling: {
    margin: 10,
  },
};

ServicesInvoice.propTypes = {
  billing: PropTypes.object.isRequired,
};

export default withStyles(styles)(ServicesInvoice);
