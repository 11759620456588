import React, { useState } from 'react';
import PropTypes from 'prop-types';
import typecast from 'mout/string/typecast';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import Alert from 'components/Alert';

const ToggleLock = ({
  user,
  updateUser,
  classes,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [lockReason, setLockReason] = useState(null);
  const [lockError, setLockError] = useState(null);

  const onClose = () => {
    setIsDialogOpen(false);
    setIsSaving(false);
  };

  return (
    <div className={classes.wrapper}>

      <Button
        onClick={async () => {
          if (user.is_locked) {

            setIsSaving(true);

            updateUser(user.id, {
              is_locked: false,
              locked_reason: null,
            }, 'User unlocked');

            setIsSaving(false);

          } else {

            setIsDialogOpen(true);

          }
        }}
        disabled={isSaving}
      >
        {user.is_locked && isSaving && 'Unlocking...'}
        {user.is_locked && !isSaving && 'Unlock'}
        {!user.is_locked && 'Lock'}
      </Button>

      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          Lock user
        </DialogTitle>
        <DialogContent>

          {lockError && (
            <Alert
              type="danger"
              message={lockError}
            />
          )}

          <Typography variant="subtitle1">
            Why would you like to lock this user?
          </Typography>

          <TextField
            onChange={(e) => {
              setLockReason(typecast(e.target.value));
            }}
            select
            disabled={isSaving}
            variant="outlined"
            fullWidth
            margin="normal"
            value={String(lockReason)}
            // helperText={this.getLockReasonHelpText()}
          >
            <MenuItem value="competitor">
              Competitor
            </MenuItem>
            <MenuItem value="security">
              Security
            </MenuItem>
            <MenuItem value="null">
              Other
            </MenuItem>
          </TextField>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {

              try {
                setIsSaving(true);

                await updateUser(user.id, {
                  is_locked: true,
                  locked_reason: lockReason,
                }, 'User locked');

                onClose();
              } catch (e) {
                setIsSaving(false);
                setLockError(e.message);
              }
            }}
            disabled={isSaving}
          >
            {isSaving ? 'Locking...' : 'Lock'}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );

};

const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
});

ToggleLock.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default withStyles(styles)(ToggleLock);
