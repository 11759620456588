import { trim } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  ListItemText,
} from '@material-ui/core';


const ProrationDialog = ({
  open,
  onClose,
  onSave,
  disabled,
}) => {

  const [prorationBehaviour, setProrationBehaviour] = useState(null);
  const [itemDescription, setItemDescription] = useState('');

  const handleSaveClick = () => {
    onSave(prorationBehaviour, itemDescription);
  };

  let canSave = true;
  if (!prorationBehaviour) {
    canSave = false;
  }
  if (prorationBehaviour === 'always_invoice' && !trim(itemDescription)) {
    canSave = false;
  }

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        Prorate price changes
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          You've made changes to this customer's price! How would you like to handle the changes?
        </DialogContentText>

        <TextField
          label="Proration behaviour"
          variant="outlined"
          disabled={disabled}
          margin="normal"
          onChange={e => setProrationBehaviour(e.target.value)}
          fullWidth
          select
          value={prorationBehaviour || ''}
          required
          SelectProps={{
            renderValue: (value) => {
              if (value === 'none') {
                return 'None';
              }
              if (value === 'always_invoice') {
                return 'Invoice immediately';
              }
            },
          }}
        >
          <MenuItem value="none">
            <ListItemText
              primary="None"
              secondary="Price changes will not take affect until their plan renews"
            />
          </MenuItem>
          <MenuItem value="always_invoice">
            <ListItemText
              primary="Invoice immediately"
              secondary="Customer will immediately receive an invoice/credit based on this price change"
            />
          </MenuItem>
        </TextField>

        {prorationBehaviour === 'always_invoice' && (
          <TextField
            label="Invoice item label"
            helperText="What would you like to appear on the invoice generated?"
            variant="outlined"
            value={itemDescription}
            onChange={e => setItemDescription(e.target.value)}
            placeholder="e.g. Upgrade to 50 users"
            margin="normal"
            required
            disabled={disabled}
            fullWidth
          />
        )}


      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={disabled}>
          Cancel
        </Button>
        <Button
          onClick={handleSaveClick}
          disabled={disabled || !canSave}
          variant="contained"
        >
          {disabled ? 'Saving...' : 'Save changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );

};

ProrationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ProrationDialog;
