// Convert hexes to filters: https://codepen.io/sosuke/pen/Pjoqqp
export default {
  '000000': '',
  '000F4F': 'invert(9%) sepia(54%) saturate(4170%) hue-rotate(223deg) brightness(86%) contrast(112%)',
  FF0069: 'invert(14%) sepia(62%) saturate(7493%) hue-rotate(328deg) brightness(99%) contrast(106%)',
  FFBA00: 'invert(64%) sepia(90%) saturate(688%) hue-rotate(358deg) brightness(101%) contrast(108%)',
  '9E15BF': 'invert(15%) sepia(52%) saturate(6965%) hue-rotate(284deg) brightness(89%) contrast(99%)',
  '458CFF': 'invert(46%) sepia(93%) saturate(1538%) hue-rotate(198deg) brightness(99%) contrast(105%)',
  '29E8BA': 'invert(76%) sepia(48%) saturate(683%) hue-rotate(106deg) brightness(97%) contrast(87%)',
  BB93FF: 'invert(59%) sepia(94%) saturate(1032%) hue-rotate(211deg) brightness(100%) contrast(102%)',
  '1CC9CC': 'invert(62%) sepia(81%) saturate(466%) hue-rotate(130deg) brightness(93%) contrast(85%)',
  FFFFFF: 'invert(94%) sepia(10%) saturate(0%) hue-rotate(148deg) brightness(104%) contrast(106%)',
};
