import {
  isInteger,
  isNull,
} from 'lodash';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Tooltip,
  Grid,
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import NumberInput from 'components/NumberInput';

import arePropsEqual from 'utils/arePropsEqual';
import CardTitle from 'components/CardTitle';
import CardTitleIcon from 'components/CardTitleIcon';
import Expandomatic from 'components/Expandomatic';
import ExpandIcon from './ExpandIcon';
import Icon from '../../../../../icon';

const BillingLimits = ({
  draftBilling,
  isSaving,
  dispatch,
  classes,
}) => {

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const isUltimate = draftBilling.plan === 'ultimate';

  return (
    <Card>
      <CardContent>

        <ExpandIcon
          onChange={handleExpandClick}
          isExpanded={isExpanded}
          label="limits"
        />

        <CardTitle>
          <CardTitleIcon>
            <Icon value="plumpy plumpy-AOtO5hoOz3pU" />
          </CardTitleIcon>
          Limits
        </CardTitle>

        {isExpanded ? (
          <div>
            <br />

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1" className={classes.fieldLabel}>
                  <strong>Contacts</strong>
                  <Tooltip
                    title={isUltimate
                      ? 'Number of contacts the customer is allowed on the Ultimate plan. Can only be changed here.'
                      : 'Number of contacts the customer has currently purchased. This can only be changed by the customer in their billing settings, but you can temporarily override this limit using the field below.'}
                    placement="top"
                  >
                    <HelpIcon className={classes.helpIcon} />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  value={draftBilling.contacts || ''}
                  disabled={!isUltimate || isSaving}
                  InputProps={{
                    inputComponent: NumberInput,
                  }}
                  onChange={(e) => dispatch({
                    type: 'set_number',
                    key: 'contacts',
                    value: e.target.value,
                  })}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            {!isUltimate && (
              <div>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      <strong>Contacts override (temporary)</strong>
                      <Tooltip
                        title="If you'd like to temporarily override the number of contacts a customer has purchased, enter the desired limit here. This should only be used SHORT TERM - if customers need more contacts, they have to pay for them!"
                        placement="top"
                      >
                        <HelpIcon className={classes.helpIcon} />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      value={draftBilling.contacts_override || ''}
                      disabled={isSaving}
                      onChange={(e) => dispatch({
                        type: 'set_number',
                        key: 'contacts_override',
                        value: e.target.value,
                      })}
                      InputProps={{
                        inputComponent: NumberInput,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      <strong>Purchasable contacts (range)</strong>
                      <Tooltip
                        title="The range of contacts that can be purchased. This is NOT their current contact limit - it's how many/few contacts they're ALLOWED to purchase. Usually, the default plan limits are fine."
                        placement="top"
                      >
                        <HelpIcon className={classes.helpIcon} />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      value={draftBilling.min_contacts || ''}
                      disabled={isSaving}
                      onChange={(e) => dispatch({
                        type: 'set_number',
                        key: 'min_contacts',
                        value: e.target.value,
                      })}
                      error={!isInteger(draftBilling.min_contacts)}
                      InputProps={{
                        inputComponent: NumberInput,
                      }}
                      variant="outlined"
                    />

                    <Typography variant="body2" className={classes.rangeDashIcon}>
                      to
                    </Typography>

                    <TextField
                      value={draftBilling.max_contacts || ''}
                      disabled={isSaving}
                      onChange={(e) => dispatch({
                        type: 'set_number',
                        key: 'max_contacts',
                        value: e.target.value,
                      })}
                      error={!isInteger(draftBilling.max_contacts)}
                      InputProps={{
                        inputComponent: NumberInput,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1" className={classes.fieldLabel}>
                  <strong>
                    {isUltimate ? (
                      'Number of users'
                    ) : (
                      'Number of free users'
                    )}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  value={draftBilling.max_users || ''}
                  disabled={isSaving}
                  onChange={(e) => dispatch({
                    type: 'set_number',
                    key: 'max_users',
                    value: e.target.value,
                  })}
                  InputProps={{
                    inputComponent: NumberInput,
                  }}
                  error={!isInteger(draftBilling.max_users)}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1" className={classes.fieldLabel}>
                  <strong>
                    {isUltimate ? (
                      'Number of custom fields'
                    ) : (
                      'Number of free custom fields'
                    )}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  value={draftBilling.max_custom_entity_fields || ''}
                  disabled={isSaving}
                  onChange={(e) => dispatch({
                    type: 'set_number',
                    key: 'max_custom_entity_fields',
                    value: e.target.value,
                  })}
                  InputProps={{
                    inputComponent: NumberInput,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1" className={classes.fieldLabel}>
                  <strong>
                    {isUltimate ? (
                      'Number of roles'
                    ) : (
                      'Number of free roles'
                    )}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  value={draftBilling.max_roles || ''}
                  disabled={isSaving}
                  onChange={(e) => dispatch({
                    type: 'set_number',
                    key: 'max_roles',
                    value: e.target.value,
                  })}
                  InputProps={{
                    inputComponent: NumberInput,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1" className={classes.fieldLabel}>
                  <strong>Max read-only users</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  value={draftBilling.max_read_only_users || ''}
                  disabled={isSaving}
                  onChange={(e) => dispatch({
                    type: 'set_number',
                    key: 'max_read_only_users',
                    value: e.target.value,
                  })}
                  InputProps={{
                    inputComponent: NumberInput,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant="body1" className={classes.fieldLabel}>
                  <strong>Max workflows</strong>
                  <Tooltip
                    title="Number of workflows this customer is allowed to have running at once. Designed to allow customers on Standard to have 5. Meant to be unlmiited on Premium."
                    placement="top"
                  >
                    <HelpIcon className={classes.helpIcon} />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  value={isInteger(draftBilling.max_workflows)
                    ? draftBilling.max_workflows
                    : ''}
                  error={
                    // if not an integer and not null then error
                    (!isInteger(draftBilling.max_workflows) && !isNull(draftBilling.max_workflows))
                    // if an integer but less than 0 then error
                    || (isInteger(draftBilling.max_workflows) && draftBilling.max_workflows < 0)
                  }
                  disabled={isSaving}
                  InputProps={{
                    inputComponent: NumberInput,
                  }}
                  onChange={(e) => dispatch({
                    type: 'set_number',
                    key: 'max_workflows',
                    value: e.target.value,
                  })}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <br />
            <br />

            {draftBilling.plan !== 'ultimate' && (
              <Alert severity="info">
                {'Looking for custom fields or roles? You can change these in Prices > Limit increases above.'}
              </Alert>
            )}

          </div>
        ) : (
          <Expandomatic onClick={() => setIsExpanded(true)}>
            Click to expand 👉👉
          </Expandomatic>
        )}

      </CardContent>
    </Card>
  );
};

const styles = (theme) => ({
  fieldLabel: {
    marginTop: theme.spacing(2),
  },
  helpIcon: {
    fontSize: 20,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    marginTop: -2, // weird i have to do this
    color: theme.palette.grey[600],
  },
  rangeDashIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

BillingLimits.propTypes = {
  draftBilling: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Only re-render when these props change
const watchedProps = [
  'isSaving',
  'draftBilling.contacts',
  'draftBilling.contacts_override',
  'draftBilling.max_contacts',
  'draftBilling.min_contacts',
  'draftBilling.max_users',
  'draftBilling.max_read_only_users',
  'draftBilling.max_workflows',
  'draftBilling.max_custom_entity_fields',
  'draftBilling.max_roles',
];

export default withStyles(styles)(memo(BillingLimits, arePropsEqual(watchedProps)));
