import { get, map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const AccessRequestsList = ({
  accessRequests,
}) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Id</TableCell>
        <TableCell>Account user</TableCell>
        <TableCell>Account user id</TableCell>
        <TableCell>Expires after</TableCell>
        <TableCell>Approved by</TableCell>
        <TableCell>Is revoked?</TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {map(accessRequests, (request) => {
        const {
          account_user,
          approved_by_user,
          revoked_by_user_id,
        } = request;

        // Lifetime of the access request is calculated on the basis of two
        // days from the time of approval or creation if not yet approved...
        const baseDateTime = request.approved_at || request.created_at;

        return (
          <TableRow key={request.id}>
            <TableCell>{request.id}</TableCell>
            <TableCell>{get(account_user, 'user.email')}</TableCell>
            <TableCell>{account_user.id}</TableCell>
            <TableCell>{moment(baseDateTime).add(2, 'days').format('ll')}</TableCell>
            <TableCell>{get(approved_by_user, 'email', '-')}</TableCell>
            <TableCell>{revoked_by_user_id ? 'true' : '-'}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);

AccessRequestsList.propTypes = {
  accessRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AccessRequestsList;
