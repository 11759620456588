import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getStateByKey } from 'reducers';
import { loadCurrentSession, loadCurrentUser } from 'actions/user';
import CreateAccount from './component';

const selector = createSelector([
  getStateByKey('user.currentSession'),
  getStateByKey('user.currentUser'),
  (state, props) => props.location,
], (currentSession, currentUser, location) => ({
  isLoading: !currentSession || !currentUser,
  currentSession,
  location,
}));

const mapDispatchToProps = {
  loadCurrentSession,
  loadCurrentUser,
};

export default connect(
  selector,
  mapDispatchToProps,
)(CreateAccount);
