import { isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  loadUserAccounts,
} from 'actions/user';
import UserAccount from './component';

const selector = createSelector([
  (state, props) => state.user.usersAccountsById[props.userId],
], (userAccounts) => ({
  isLoading: isUndefined(userAccounts),
  userAccounts,
}));

const mapDispatchToProps = {
  loadUserAccounts,
};

export default connect(
  selector,
  mapDispatchToProps,
)(UserAccount);
