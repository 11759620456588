// Exports two instances of an Apollo client, which can be used
// by consumers to run graphql queries.
import {
  get,
} from 'lodash';
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  concat,
} from '@apollo/client';
import { host as apiHost } from 'config/api';
import cache from './apiCache';

import store from './store';

// Pull the client ID from the redux state so the client knows how to
// handle incoming websockets (updates made by this user should not
// be handled from a websocket request)
const clientId = get(store.getState(), 'user.clientId');

const apiHttpLink = new HttpLink({
  uri: `${apiHost}/api/graphql`,
  headers: {
    'Beacon-Application': 'admin_dashboard',
  },
  credentials: 'include',
});

// Add the account ID as a header if sending the query from a particular
// account dashboard.
const accountMiddleware = new ApolloLink((operation, forward) => {
  const { accountId } = store.getState().account;

  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'Beacon-Account-ID': accountId || null,
      'Beacon-Client-Id': clientId,
    },
  }));

  return forward(operation);
});

const apiClient = new ApolloClient({
  cache,
  link: concat(accountMiddleware, apiHttpLink),
  // https://www.apollographql.com/docs/react/api/core/ApolloClient/#example-defaultoptions-object
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },

});

/*
* Querying entities uses an entirely separate graphql schema,
* check out the dashboard if it's needed.
* It's commented out for now but we'll need
*/

export default apiClient;
