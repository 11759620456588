export const ACCOUNTS_LOADING = 'ACCOUNTS_LOADING';
export const ACCOUNTS_LOADED = 'ACCOUNTS_LOADED';

export const ACCOUNT_UPDATED = 'ACCOUNT_UPDATED';

export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const ACCOUNT_LOADED = 'ACCOUNT_LOADED';

export const ACCOUNT_USERS_LOADING = 'ACCOUNT_USERS_LOADING';
export const ACCOUNT_USERS_LOADED = 'ACCOUNT_USERS_LOADED';
export const ACCOUNT_USER_UPDATED = 'ACCOUNT_USER_UPDATED';
export const ACCOUNT_BILLING_USAGE_LOADED = 'ACCOUNT_BILLING_USAGE_LOADED';

export const ACCOUNT_BILLING_LOADING = 'ACCOUNT_BILLING_LOADING';
export const ACCOUNT_BILLING_LOADED = 'ACCOUNT_BILLING_LOADED';
