import { noop } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { gql, useMutation } from '@apollo/client';

import { APP_FRAGMENT } from 'app/fragments';

const TOGGLE_APP = gql`
  mutation toggleApp($id: Int!, $is_enabled: Boolean!) {
    toggleApp(id: $id, is_enabled: $is_enabled) {
      ...App
    }
  }
  ${APP_FRAGMENT}
`;
const ToggleIsAllAppsEnabled = ({
  app,
  buttonStyles = {},
  setDisableHoverEffect = noop,
  classes,
}) => {

  const [toggleApp, { loading: isSaving, error }] = useMutation(TOGGLE_APP, {
    variables: {
      id: app.id,
    },
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleToggleClick = (e) => {
    e.stopPropagation();
    setIsDialogOpen(true);
    setDisableHoverEffect(false);
  };

  const handleDialogClose = (e) => {
    e.stopPropagation();
    setIsDialogOpen(false);
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation();
    toggleApp({
      variables: {
        is_enabled: !app.is_enabled,
      },
      onCompleted: () => setIsDialogOpen(false),
    });
  };

  return (
    <div className={classes.wrapper}>

      <Button
        color={buttonStyles.color}
        variant={buttonStyles.variant}
        onClick={handleToggleClick}
        disabled={isSaving || true}
      >
        {app.is_enabled ? 'Disable' : 'Enable'}
      </Button>

      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={handleDialogClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          {app.is_enabled
            ? `Disable ${app.title}`
            : `Enable ${app.title}`}
        </DialogTitle>
        <DialogContent>

          {error && (
          <Alert
            severity="error"
            className={classes.alert}
          >
            {error}
          </Alert>
          )}

          <Typography variant="subtitle1">
            {`This will ${app.is_enabled ? 'disable' : 'enable'} `}
            {`${app.title} on ALL accounts.`}
            {' '}
            🤖
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveClick}
            disabled={isSaving}
          >
            {app.is_enabled ? 'Disable' : 'Enable'}
          </Button>
        </DialogActions>
      </Dialog>

    </div>

  );
};
const styles = (theme) => ({
  wrapper: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    verticalAlign: 'middle',
  },
  editTextBox: {
    paddingTop: theme.spacing(1.5),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
});

ToggleIsAllAppsEnabled.propTypes = {
  app: PropTypes.object.isRequired,
  buttonStyles: PropTypes.object.isRequired,
  setDisableHoverEffect: PropTypes.func.isRequired,
};

export default withStyles(styles)(ToggleIsAllAppsEnabled);
