import {
  capitalize, includes, replace, split,
} from 'lodash';
import React from 'react';
import {
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';

const GET_PORTAL_FORMS_TOTAL = gql`
  query GetPortalFormsTotal($query: String, $type: String, $account_id: Int) {
    portalFormsTotal(query: $query, type: $type, account_id: $account_id)
  }
`;

function ToggleCopyForm({
  account,
  additionalFeatures,
  formKey,
  onChange,
}) {
  const formType = replace(formKey, 'forms_', '');
  const formTitle = capitalize(split(formType, '_').join(' '));

  const { data } = useQuery(GET_PORTAL_FORMS_TOTAL, {
    variables: {
      account_id: account.id,
      type: formType,
    },
  });

  return (
    <div>
      <FormControlLabel
        control={(
          <Switch
            checked={includes(additionalFeatures, formKey)}
            onChange={onChange}
            color="primary"
          />
        )}
        label={`${formTitle} forms (${data?.portalFormsTotal || 0})`}
      />
    </div>
  );
}

ToggleCopyForm.propTypes = {
  account: PropTypes.object.isRequired,
  additionalFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  formKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleCopyForm;
