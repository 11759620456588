import {
  gql,
} from '@apollo/client';

export const ACCOUNT_USERS_FRAGMENT = gql`
  fragment AccountUser on AccountUser {
    id
    created_at
    is_deactivated
    user {
      id
      first_name
      last_name
      email
      is_locked
      locked_reason
    }
    account_id
    account {
      name
      is_locked
      locked_reason
    }
    updated_at
  }
`;

export const ACCOUNT_FRAGMENT = gql` 
  fragment Account on Account {
      created_at
      id
      name
      slug
      country_code
      timezone
      billing {
        mrr
      }
      apiKey {
        id
        last4
        account_id
        key
        is_revoked
      }
  }
`;

export const API_KEYS_FRAGMENT = gql`
  fragment ApiKey on ApiKey {
    id
    key
    is_revoked
    last4
    created_at
    updated_at
    account_id
    account {
      name
      id
    }
    created_by_user {
      email
    }
  }
`;

export const APP_FRAGMENT = gql`
  fragment App on App {
    id
    key
    title
    is_coming_soon
    is_visible
    is_enabled
    help_link
    icon
    short_description
    created_at
    updated_at
    total_installs
    total_installs_enabled
  }
`;

export const APP_CONFIGURATION_FRAGMENT = gql`
  fragment AppConfiguration on AppConfiguration {
    id
    health_status
    health_description
    is_enabled
    routing_id
    disabled_at
    account_id
    created_at
    updated_at
    account {
      id
      name
    }
    run_next_schedule_at
  }
`;
